import api from 'services/api';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { selectWorkspaces } from 'store/Workspace/selectors';
import { selectJobsList } from 'store/Pipelines/selectors';
import { redirect } from 'store/Router/actions';
import { parseRules } from 'store/Jobs/actions';
import {
  selectPipelineSelected, selectInputField,
  selectIsCreating, selectId, selectJobsSelected
} from './selectors';
import {
  PIPELINES_FETCHING,
  PIPELINES_FAIL,
  PIPELINES_TABLE_GET_DATA,
  FILTER_PIPELINES,
  PIPELINE_SELECTED,
  PIPELINE_ENABLE_DISABLE,
  PIPELINE_UPDATE_FAIL,
  RUN_PIPELINE_FAIL,
  SET_PIPELINE_INPUT_FIELD,
  PIPELINE_CREATE_UPDATE_START,
  PIPELINE_CREATE_UPDATE_SUCCESS,
  RESET_STATE_PIPELINE_CREATE_UPDATE,
  SET_JOB,
  DELETE_JOB_FROM_PIPELINE,
  SET_JOBS_NEW_ORDER,
  SET_TARGET,
  SET_PIPELINE_REDIRECT_TO,
  GET_JOBS,
  OPEN_NEW_PIPELINE,
  PIPELINES_RESET_ERROR,
  RUN_PIPELINE
} from './constants';

export function resetState (isCreating) {
  return (dispatch) => {
    dispatch({ type: RESET_STATE_PIPELINE_CREATE_UPDATE, isCreating });
  };
}

export function setRedirectTo (redirectTo) {
  return (dispatch) => {
    dispatch({ type: SET_PIPELINE_REDIRECT_TO, redirectTo });
  };
}

export function fetching (isFetching) {
  return async (dispatch) => {
    dispatch({ type: PIPELINES_FETCHING, isFetching });
  };
}

export function somethingWentWrong (error) {
  return async (dispatch) => {
    dispatch({ type: PIPELINES_FAIL, errorMessage: error || 'There was an error, please try again.' });
  };
}

export function getPipelines () {
  return async (dispatch) => {
    try {
      const response = await api.pipelines.fetchPipelines();
      dispatch({ type: PIPELINES_TABLE_GET_DATA, data: response });
    } catch (e) {
      dispatch(somethingWentWrong());
    }
  };
}

export function filterPipelines (query) {
  return async (dispatch) => {
    dispatch({ type: FILTER_PIPELINES, query });
  };
}

export function newPipeline (value) {
  return (dispatch) => {
    dispatch({ type: OPEN_NEW_PIPELINE, value, isCreating: true });
    dispatch(redirect('/automation/pipelines/new'));
  };
}

export function viewDetail (pipeline, isEditing, isCreating) {
  return (dispatch, getState) => {
    const state = getState();
    const pipelineSelected = selectPipelineSelected(state);
    if (isEditing) {
      const workspaceList = selectWorkspaces(state);
      const workspace = pipeline && workspaceList && workspaceList.find((ws) => ws._id === pipeline.workspace_id || ws.name === pipeline.workspace_id);
      const workspaceName = get(workspace, 'name', '');
      const jobsSorted = pipeline && pipeline.jobs_order.split('-').map((o) => pipeline.jobs.find((j) => j.id === parseInt(o, 10))).filter((i) => i !== undefined);
      const newPipeline = { ...pipeline, workspace_id: pipeline && workspaceName, jobs: jobsSorted || [] };

      dispatch({
        type: PIPELINE_SELECTED, pipeline: newPipeline, isCreating: false, isEditing
      });
    } else if (isCreating) {
      dispatch({
        type: PIPELINE_SELECTED, pipeline: isEqual(pipelineSelected, pipeline) ? null : pipeline, isCreating: true, isEditing: false
      });
    } else {
      dispatch({
        type: PIPELINE_SELECTED, pipeline: isEqual(pipelineSelected, pipeline) ? null : pipeline, isCreating: false, isEditing: false
      });
    }
  };
}

export function enableDisablePipeline (id, status) {
  return async (dispatch) => {
    try {
      dispatch({ type: PIPELINE_ENABLE_DISABLE });

      if (status) await api.pipelines.disable(id);
      else await api.pipelines.enable(id);

      dispatch(getPipelines());
    } catch (e) {
      dispatch({ type: PIPELINE_UPDATE_FAIL, error: 'An error has occurred.' });
    }
  };
}

export function deletePipeline (pipeline) {
  return async (dispatch) => {
    try {
      await api.pipelines.removePipeline(pipeline.id);
      dispatch(viewDetail());
      dispatch(resetState(false));
      return dispatch(getPipelines());
    } catch (e) {
      return dispatch(somethingWentWrong());
    }
  };
}

export function copyPipeline (pipeline) {
  return async (dispatch) => {
    try {
      await api.pipelines.clonePipeline(pipeline.id);
      return dispatch(getPipelines());
    } catch (e) {
      return dispatch(somethingWentWrong());
    }
  };
}

export function createPipeline () {
  return async (dispatch, getState) => {
    const state = getState();
    const isCreating = selectIsCreating(state);
    const name = selectInputField(state, 'name');
    const description = selectInputField(state, 'description');
    const jobsList = selectJobsSelected(state, 'jobs_ids');
    const jobsIds = jobsList.length ? jobsList.map((job) => job.id) : [];
    const jobsOrder = jobsIds.length ? jobsIds.join('-') : '';
    const workspaceList = selectWorkspaces(state);
    const workspaceName = get(state, 'pipelines.pipelineSelected.workspace_id', '');
    const workspace = workspaceList && workspaceList.filter((ws) => ws.name === workspaceName);
    const workspaceId = workspace.length ? workspace[0].id : null;
    const id = selectId(state);

    dispatch({ type: PIPELINE_CREATE_UPDATE_START });

    try {
      let pipeline = {
        name,
        description,
        jobs_ids: jobsIds,
        jobs_order: jobsOrder,
        workspace_id: workspaceId
      };

      if (isCreating) {
        await api.pipelines.createPipeline(pipeline);
      } else {
        pipeline = { ...pipeline, id };
        await api.pipelines.editPipeline(id, pipeline);
        if (!workspaceId) await api.pipelines.disable(id);
      }

      dispatch({ type: PIPELINE_CREATE_UPDATE_SUCCESS });
      dispatch(viewDetail(null));
      return dispatch(getPipelines());
    } catch (e) {
      if (e?.message === 'Existing value') return dispatch(somethingWentWrong('Already existing pipeline with same name'));
      return dispatch(somethingWentWrong());
    }
  };
}

export function setJob (value) {
  return (dispatch, getState) => {
    const state = getState();
    const pipelineSelectedJobs = selectJobsSelected(state);
    const jobRepeated = pipelineSelectedJobs && pipelineSelectedJobs.filter((j) => j.name === value);
    const jobsList = selectJobsList(state);

    if ((jobRepeated.length === 0 && pipelineSelectedJobs.length === 0) || jobRepeated.length === 0) {
      const job = jobsList && jobsList.filter((job) => job.name === value);
      dispatch({ type: SET_JOB, value: job[0] });
    }
  };
}

export function setTarget (wsName) {
  return (dispatch, getState) => {
    const state = getState();
    const workspaceList = selectWorkspaces(state);
    const workspaceId = workspaceList && workspaceList.filter((ws) => ws.name === wsName);
    dispatch({ type: SET_TARGET, value: workspaceId[0] ? workspaceId[0].name : '' });
  };
}

export function setInputField (label, value) {
  return (dispatch) => {
    dispatch({ type: SET_PIPELINE_INPUT_FIELD, label, value });
  };
}

export function deleteJob (id) {
  return async (dispatch, getState) => {
    const jobsList = selectJobsSelected(getState());
    const filteredJobs = jobsList && jobsList.filter((el) => el.id !== id);
    dispatch({ type: DELETE_JOB_FROM_PIPELINE, value: filteredJobs });
  };
}

export function setJobsNewOrder (jobs) {
  return async (dispatch) => {
    dispatch({ type: SET_JOBS_NEW_ORDER, jobs });
  };
}

export function runPipeline (id) {
  return async (dispatch) => {
    const checkStatus = async (id) => {
      const pipeline = await api.pipelines.getPipeline(id);
      const isRunning = pipeline.running;
      setTimeout(() => {
        if (isRunning) checkStatus(id);
        else dispatch(getPipelines());
      }, 5000);
    };

    try {
      await api.pipelines.runPipeline(id);
      dispatch({ type: RUN_PIPELINE, isRunning: true, id });
      await checkStatus(id);
    } catch (e) {
      dispatch({ type: RUN_PIPELINE_FAIL, error: 'An error has occurred.' });
    }
  };
}

export function getJobs () {
  return async (dispatch) => {
    try {
      const jobs = await api.jobs.fetchJobs();
      const parsedJobs = jobs.map((job) => parseRules(job));
      return dispatch({ type: GET_JOBS, jobs: parsedJobs });
    } catch (e) {
      return dispatch(somethingWentWrong());
    }
  };
}

export function resetError () {
  return async (dispatch) => {
    dispatch({ type: PIPELINES_RESET_ERROR });
  };
}
