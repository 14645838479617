import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as Loupe } from 'Images/icon_loupe.svg';
import { ReactComponent as Clear } from 'Images/icon_clearsearch.svg';
import BlueButton from 'Common/Components/BlueButton';

const zIndex = 2;

export const SearchInput = styled.div`
  max-width: 550px;
  flex: 1 1 0;
  cursor: pointer;
  display: flex;
  justify-content: left;
  flex-direction: row;
  padding: 0 8px 0 10px;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-align: end;
  z-index: ${zIndex + 2};
  height: 40px;
  .react-datepicker-wrapper {
    padding-top: 7px;
  }
`;

export const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
`;

export const Input = styled.input`
  height: 40px;
  min-width: 40px;
  flex: 1 1 0;
  padding-left: 5.5px;
  border: solid 0px rgba(255, 255, 255, 0);
  background-color: rgba(255, 255, 255, 0);

  font-size: 13px;
  letter-spacing: 0.1px;
  color: ${colors.darkBlueGrey};
  
  :-ms-input-placeholder {
    color: rgb(222,229,236);
  }
  ::-webkit-input-placeholder {
    color: rgb(222,229,236);
  }
`;

export const DropdownContainer = styled.div`
  left: ${(props) => (`${props.x + 48}px`)};
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 45px;
  z-index: ${zIndex + 2};
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${zIndex + 1};
`;
Backdrop.displayName = 'PopupBackdrop';

export const SearchIcon = styled(Loupe)`
  width: 18px;
`;
SearchIcon.displayName = 'SearchIcon';

export const ClearIcon = styled(Clear)`
  margin-left: auto;
  margin-left: 13px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 13px;
  color: #a1b4b6;
  cursor: pointer;
  background-color: white;
  `;
ClearIcon.displayName = 'ClearIcon';

export const SearchButton = styled(BlueButton)`
  width: 38px;
  height: 38px;
  padding: 0px;
`;
SearchButton.displayName = 'SearchButton';
