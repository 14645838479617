import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectReadOnly } from 'store/Faraday/selectors';
import ManualCreationModal from 'Screens/Contextualization/ManageEditCreate/components/ManualCreationModal';
import { Wrapper, ButtonWrapper } from './styled';
import AddButton from 'Common/Components/Contextualization/AddButton';

const CreateActions = () => {
  const [showModalCreation, setShowModalCreation] = useState(false);
  const readOnly = useSelector(selectReadOnly);
  const canUpdate = !readOnly;

  const handleShow = () => {
    if (canUpdate) setShowModalCreation(true);
  };

  const handleClose = () => setShowModalCreation(false);

  return (
    <Wrapper>
      <ButtonWrapper data-tour="Add Vulnerability in Asset">
        <AddButton onClick={ handleShow } title="Add Vulnerability" text="Add Vulnerability" disabled={ readOnly } />
      </ButtonWrapper>
      { showModalCreation && <ManualCreationModal handleClose={ handleClose } entity="vulnsAssets" /> }
    </Wrapper>
  );
};

export default CreateActions;
