const getFilterVal = (key) => {
  if (key === 'services') return ({ name: 'status', op: '==', val: 'open' });
  if (key === 'owned') return 'true';
  return '0';
};
const getFilterOp = (key) => {
  if (key === 'services') return 'any';
  if (key === 'owned') return '==';
  if (key === 'vulnerability_critical_generic_count') return '!=';
  return '0';
};

const defaultFilters = {
  vulns: [{
    title: 'Severity',
    name: 'severity',
    options: ['critical', 'high', 'medium', 'low', 'informational', 'unclassified'],
    displayOptions: ['C', 'H', 'M', 'L', 'I', 'U'],
    type: 'severity',
    showClear: true,
    filter: (filter) => ({ name: 'severity', op: '==', val: filter })
  }, {
    title: 'Status',
    name: 'status',
    options: ['open', 'closed', 're-opened', 'risk-accepted'],
    displayOptions: ['Open', 'Closed', 'Re-Opened', 'Risk Accepted'],
    type: 'checkbox',
    showClear: true,
    filter: (filter) => ({ name: 'status', op: '==', val: filter })
  }, {
    title: 'Ease of Resolution',
    name: 'ease_of_resolution',
    options: ['trivial', 'simple', 'moderate', 'difficult', 'infeasible'],
    displayOptions: ['Trivial', 'Simple', 'Moderate', 'Difficult', 'Infeasible'],
    type: 'checkbox',
    showClear: true,
    filter: (filter) => ({ name: 'ease_of_resolution', op: '==', val: filter })
  }, {
    title: 'Confirmation',
    name: 'confirmed',
    options: ['true', 'false'],
    displayOptions: ['Confirmed', 'Not Confirmed'],
    type: 'checkbox',
    showClear: true,
    filter: (filter) => ({ name: 'confirmed', op: '==', val: filter })
  }
  ],
  assets: [
    {
      title: '',
      name: '',
      options: ['services', 'vulnerability_critical_generic_count', 'owned'],
      displayOptions: ['Has Open Services', 'Has Critical Vulns', 'Has Been Owned'],
      type: 'checkbox',
      showClear: false,
      filter: (filter) => ({ name: filter, op: getFilterOp(filter), val: getFilterVal(filter) })
    },
    {
      title: 'OS',
      name: 'os',
      options: ['windows', 'unix', 'macos', 'linux'],
      displayOptions: ['Windows', 'Unix', 'MacOS', 'Linux'],
      type: 'checkbox',
      showClear: true,
      filter: (filter) => ({ name: 'os', op: 'ilike', val: `%${filter}%` })
    }
  ],
  vulnsAssets: [{
    title: 'Severity',
    name: 'severity',
    options: ['critical', 'high', 'medium', 'low', 'informational', 'unclassified'],
    displayOptions: ['C', 'H', 'M', 'L', 'I', 'U'],
    type: 'severity',
    showClear: true,
    filter: (filter) => ({ name: 'severity', op: '==', val: filter })
  }, {
    title: 'Status',
    name: 'status',
    options: ['open', 'closed', 're-opened', 'risk-accepted'],
    displayOptions: ['Open', 'Closed', 'Re-Opened', 'Risk Accepted'],
    type: 'checkbox',
    showClear: true,
    filter: (filter) => ({ name: 'status', op: '==', val: filter })
  }, {
    title: 'Ease of Resolution',
    name: 'ease_of_resolution',
    options: ['trivial', 'simple', 'moderate', 'difficult', 'infeasible'],
    displayOptions: ['Trivial', 'Simple', 'Moderate', 'Difficult', 'Infeasible'],
    type: 'checkbox',
    showClear: true,
    filter: (filter) => ({ name: 'ease_of_resolution', op: '==', val: filter })
  }, {
    title: 'Confirmation',
    name: 'confirmed',
    options: ['true', 'false'],
    displayOptions: ['Confirmed', 'Not Confirmed'],
    type: 'checkbox',
    showClear: true,
    filter: (filter) => ({ name: 'confirmed', op: '==', val: filter })
  }
  ],
  assetsGeneral: [
    {
      title: '',
      name: '',
      options: ['services', 'vulnerability_critical_generic_count', 'owned'],
      displayOptions: ['Has Open Services', 'Has Critical Vulns', 'Has Been Owned'],
      type: 'checkbox',
      showClear: false,
      filter: (filter) => ({ name: filter, op: getFilterOp(filter), val: getFilterVal(filter) })
    },
    {
      title: 'OS',
      name: 'os',
      options: ['windows', 'unix', 'macos', 'linux'],
      displayOptions: ['Windows', 'Unix', 'MacOS', 'Linux'],
      type: 'checkbox',
      showClear: true,
      filter: (filter) => ({ name: 'os', op: 'ilike', val: `%${filter}%` })
    }
  ],
  vulnsGeneral: [
    {
      title: 'Severity',
      name: 'severity',
      options: ['critical', 'high', 'medium', 'low', 'informational', 'unclassified'],
      displayOptions: ['C', 'H', 'M', 'L', 'I', 'U'],
      type: 'severity',
      showClear: true,
      filter: (filter) => ({ name: 'severity', op: '==', val: filter })
    }, {
      title: 'Status',
      name: 'status',
      options: ['open', 'closed', 're-opened', 'risk-accepted'],
      displayOptions: ['Open', 'Closed', 'Re-Opened', 'Risk Accepted'],
      type: 'checkbox',
      showClear: true,
      filter: (filter) => ({ name: 'status', op: '==', val: filter })
    }, {
      title: 'Ease of Resolution',
      name: 'ease_of_resolution',
      options: ['trivial', 'simple', 'moderate', 'difficult', 'infeasible'],
      displayOptions: ['Trivial', 'Simple', 'Moderate', 'Difficult', 'Infeasible'],
      type: 'checkbox',
      showClear: true,
      filter: (filter) => ({ name: 'ease_of_resolution', op: '==', val: filter })
    }, {
      title: 'Confirmation',
      name: 'confirmed',
      options: ['true', 'false'],
      displayOptions: ['Confirmed', 'Not Confirmed'],
      type: 'checkbox',
      showClear: true,
      filter: (filter) => ({ name: 'confirmed', op: '==', val: filter })
    }
  ]
};

export default defaultFilters;
