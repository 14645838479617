import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MODAL_APPLY_TEMPLATE, MODAL_EVIDENCE_WARNING_UPLOAD } from 'store/modals/modals';
import selectModal from 'store/modals/selectors';
import ApplyTemplateModal from 'Screens/Contextualization/KnowledgeBase/components/EditVuln';
import { TableContainer } from './styled';
import VulnsContextMenu from './components/ContextMenu';
import BulkUpdateConfirmationDialog from 'Screens/Contextualization/Manage/components/ManageTable/components/BulkUpdateConfirmationDialog';
import Table from './components/Table';
import BulkUpdateModal from './components/BulkUpdateModal';
import SelectAll from './components/SelectAll';
import ModalWarning from 'Common/Components/EvidenceTab/components/ModalWarning';
import ReduxModal from 'Common/Components/ReduxModal';
import {
  selectIsFetching, selectModalBulkUpdateValue, selectSelectAll,
  selectVulnsCount, selectVulnsSelected
} from 'store/ManageGeneral/selectors';
import { bulkUpdateVulns } from 'store/ManageGeneral/actions';

const ManageTable = () => {
  const dispatch = useDispatch();
  const showTemplateModal = useSelector((state) => selectModal(state, MODAL_APPLY_TEMPLATE));
  const vulnsSelected = useSelector(selectVulnsSelected);
  const vulnsCount = useSelector(selectVulnsCount);
  const isSelectAll = useSelector(selectSelectAll);
  const isFetching = useSelector(selectIsFetching);
  const bulkUpdateValue = useSelector(selectModalBulkUpdateValue);
  useEffect(() => () => {

  }, [dispatch]);

  return (
    <TableContainer>
      <SelectAll />
      {showTemplateModal && <ApplyTemplateModal entity="vulnsGeneral" vulnsSelected={ vulnsSelected } />}
      <VulnsContextMenu />
      <BulkUpdateModal />
      <BulkUpdateConfirmationDialog
        selected={ vulnsSelected }
        count={ vulnsCount }
        isSelectAll={ isSelectAll }
        isFetching={ isFetching }
        bulkUpdateValue={ bulkUpdateValue }
        bulkUpdateAction={ bulkUpdateVulns }
        title="Vulnerabilities"
      />
      <Table />
      <ReduxModal id={ MODAL_EVIDENCE_WARNING_UPLOAD }>
        <ModalWarning />
      </ReduxModal>
    </TableContainer>
  );
};

export default ManageTable;
