import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectCount, selectIsFetching, selectPage, selectSelected, selectServiceRowsPerPage,
  selectShowDeleteConfirmation,
  selectShowDetail
} from 'store/Contextualization/AssetDetail/selectors';
import { hideModalDelete, setServicesPageNumber } from 'store/Contextualization/AssetDetail/actions';
import { selectAssetServicesFields } from 'store/Preferences/assetServices/selectors';
import { resetDefault, setFieldVisibility, setNewOrderColumns } from 'store/Preferences/service/actions';
import CustomActionBar from 'Screens/Contextualization/Services/components/CustomActionBar';

const ActionBar = ({ setFF }) => {
  const dispatch = useDispatch();
  const servicesSelected = useSelector((state) => selectSelected('services', state));
  const servicesCount = useSelector((state) => selectCount('services', state));
  const page = useSelector((state) => selectPage('services', state));
  const rowsPerPage = useSelector((state) => selectServiceRowsPerPage(state));
  const fields = useSelector(selectAssetServicesFields);
  const showDeleteConfirmation = useSelector((state) => selectShowDeleteConfirmation('services', state));
  const isFetching = useSelector((state) => selectIsFetching('services', state));
  const showServiceDetail = useSelector((state) => selectShowDetail('services', state));

  return (
    <CustomActionBar
      setFF={ setFF }
      servicesSelected={ servicesSelected }
      servicesCount={ servicesCount }
      page={ page }
      rowsPerPage={ rowsPerPage }
      setPage={ (page) => dispatch(setServicesPageNumber(page)) }
      entity="servicesAsset"
      setFieldVisibility={ (fieldObj, value) => dispatch(setFieldVisibility(fieldObj, value)) }
      setNewOrderColumns={ (columns) => dispatch(setNewOrderColumns(columns)) }
      resetDefault={ () => dispatch(resetDefault()) }
      isFetching={ isFetching }
      showDeleteConfirmation={ showDeleteConfirmation }
      fields={ fields }
      hideServiceModalDelete={ () => dispatch(hideModalDelete('services')) }
      showServiceDetail={ showServiceDetail }
    />
  );
};

export default ActionBar;
