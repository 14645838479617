import React from 'react';
import { useDispatch } from 'react-redux';
import { redirectToVulnDetailGeneral, selectVulnRow, unSelectAllVulns } from 'store/ManageGeneral/actions';
import { NameWrapper, Name } from './styled';
import Skeleton from 'Common/Components/Skeleton';

const NameCellVulnsGeneral = ({ vuln, showSkeleton, groupBy, isPivot }) => {
  const dispatch = useDispatch();

  const handleClick = (e) => {
    dispatch(unSelectAllVulns());
    dispatch(selectVulnRow(e, vuln));
    dispatch(redirectToVulnDetailGeneral(vuln._id));
  };

  if (showSkeleton) return <Skeleton />;
  if (groupBy && isPivot) return null;
  return (
    <NameWrapper>
      <Name confirmed={ vuln.confirmed } onClick={ handleClick }>{ vuln.name }</Name>
    </NameWrapper>
  );
};

export default NameCellVulnsGeneral;
