import defaultFields from './fields';

const initialState = {
  isFetching: false,
  error: false,
  errorMessage: '',
  list: [],
  showVulnDetail: false,
  vulnDetail: undefined,
  vulnsSelected: [],
  lastSelected: -1,
  showDeleteConfirmation: false,
  showContextMenu: false,
  count: 0,
  fields: defaultFields,
  bulkUpdateField: '',
  bulkUpdateValue: '',
  vulnDetailSelectedTab: 'general',
  contextMenuXPos: 0,
  contextMenuYPos: 0,
  selectAll: false
};

export default initialState;
