import Importance from 'Common/Components/Importance';
import React from 'react';
import { useDispatch } from 'react-redux';
import { updateHost } from 'store/Host/actions';
import { updateHostGeneral } from 'store/HostsGeneral/actions';
import { Wrapper } from './styled';

const ImportanceColumn = ({ host, assetsGeneral }) => {
  const dispatch = useDispatch();
  const callback = (importance) => {
    assetsGeneral ? dispatch(updateHostGeneral(host._id, { importance }, host.workspace_name)) : dispatch(updateHost(host._id, { importance }));
  };
  return (
    <Wrapper>
      <Importance importance={ host.importance } callback={ callback } />
    </Wrapper>
  );
};

export default ImportanceColumn;
