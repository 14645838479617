import React from 'react';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import Skeleton from 'Common/Components/Skeleton';
import { enableDisable } from 'Screens/Contextualization/Workspaces/actions/Actions';
import { Wrapper, ActiveIcon, ArchivedIcon, Text } from './styled';

const EnableDisableWs = ({ isFetching, ws, setShowConfirmation }) => {
  const isWsActive = get(ws, 'active', false);
  const name = get(ws, 'name', '');
  const dispatch = useDispatch();

  const changeWsStatus = (e) => {
    e.stopPropagation();
    if (isWsActive) {
      setShowConfirmation({ show: true, ws: name, isMassive: false });
    } else {
      dispatch(enableDisable(name, false));
    }
  };

  if (isFetching) return <Skeleton />;
  return (
    <Wrapper onClick={ changeWsStatus }>
      {isWsActive
        ? <>
          <ActiveIcon />
          <Text>Active</Text>
          </>
        : <>
          <ArchivedIcon />
          <Text>Archived</Text>
          </>
      }

    </Wrapper>
  );
};

export default EnableDisableWs;
