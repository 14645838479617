/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import { FormattedMessage } from 'react-intl';
import DateFormatter from 'Common/Components/DateFormatter';
import Skeleton from 'Common/Components/Skeleton';
import {
  ColKbName, ColKbDescription, ColKbReferences,
  ColKbResolution, ColKbPolicyViolations, ColKbCreator,
  ColKbCreatedDate, Col
} from './styled';

TimeAgo.addLocale(en);

export default function getColumns (isFetching) {
  // Severity column is copy/paste from manage. It should be refactored
  const columns = [{
    Header: () => (
      <div />
    ),
    accessor: 'exploitation',
    maxWidth: 45,
    minWidth: 45,
    id: 'severity',
    resizable: false,
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : (
          <Col>
            <div className={ `background-${rowInfo.original.exploitation === 'info' ? 'informational' : (rowInfo.original.exploitation === 'med' ? 'medium' : rowInfo.original.exploitation)} ml-0` }>
              <div className="severity-letter">{rowInfo.original.exploitation ? rowInfo.original.exploitation.charAt(0).toUpperCase() : ''}</div>
            </div>
          </Col>
          )
    ),
    getProps: () => ({ 'column-type': 'exploitation' })
  }, {
    Header: () => <FormattedMessage id="Name" />,
    id: 'name',
    resizable: false,
    maxWidth: 400,
    minWidth: 200,
    Cell: (rowInfo) => (isFetching ? <Skeleton /> : <ColKbName>{rowInfo.original.name}</ColKbName>),
    getProps: () => ({ 'column-type': 'name' })
  }, {
    Header: () => <FormattedMessage id="description" />,
    id: 'description',
    resizable: false,
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : (
          <ColKbDescription>
            { rowInfo.original.description }
          </ColKbDescription>
          )
    ),
    getProps: () => ({ 'column-type': 'description' })
  }, {
    Header: () => <FormattedMessage id="references" />,
    id: 'references',
    resizable: false,
    sortable: false,
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : (
          <ColKbReferences>
            { rowInfo.original.references }
          </ColKbReferences>
          )
    ),
    getProps: () => ({ 'column-type': 'references' })
  }, {
    Header: () => <FormattedMessage id="resolution" />,
    id: 'resolution',
    resizable: false,
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : (
          <ColKbResolution>
            { rowInfo.original.resolution }
          </ColKbResolution>
          )
    ),
    getProps: () => ({ 'column-type': 'resolution' })
  }, {
    Header: () => <FormattedMessage id="policyviolations" />,
    id: 'policyviolations',
    resizable: false,
    sortable: false,
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : (
          <ColKbPolicyViolations>
            { rowInfo.original.policyviolations.map((policy, index) => {
              const { policyviolations } = rowInfo.original;
              if (policyviolations.length > 1 && index + 1 < policyviolations.length) return `${policy}, `;
              return `${policy}`;
            }) }
          </ColKbPolicyViolations>
          )
    ),
    getProps: () => ({ 'column-type': 'creator' })
  }, {
    Header: () => <FormattedMessage id="creator" />,
    id: 'creator_id',
    resizable: false,
    minWidth: 100,
    maxWidth: 100,
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : (
          <ColKbCreator>
            { rowInfo.original.creator }
          </ColKbCreator>
          )
    ),
    getProps: () => ({ 'column-type': 'creator' })
  }, {
    Header: () => <FormattedMessage id="created_date" />,
    id: 'create_date',
    resizable: false,
    minWidth: 120,
    maxWidth: 120,
    Cell: (rowInfo) => (
      isFetching
        ? <Skeleton />
        : (
          <ColKbCreatedDate>
            { rowInfo.original.create_at ? <DateFormatter date={ rowInfo.original.create_at } /> : '-'}
          </ColKbCreatedDate>
          )
    ),
    getProps: () => ({ 'column-type': 'created_date' })
  }];
  return columns;
}
