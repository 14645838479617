import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setPageNumberServices, hideServiceModalDelete } from 'store/Services/actions';
import { selectPage, selectRowsPerPage } from 'store/Filters/selectors';
import { selectIsFetching, selectSelectAllServices, selectServicesCount, selectServicesSelected, selectShowDeleteConfirmation, selectShowDetail } from 'store/Services/selectors';
import { selectFields } from 'store/Preferences/service/selectors';
import { resetDefault, setFieldVisibility, setNewOrderColumns } from 'store/Preferences/service/actions';
import CustomActionBar from '../CustomActionBar';

const ActionBar = ({ setFF }) => {
  const dispatch = useDispatch();
  const servicesSelected = useSelector(selectServicesSelected);
  const servicesCount = useSelector(selectServicesCount);
  const page = useSelector((state) => selectPage('services', state));
  const rowsPerPage = useSelector((state) => selectRowsPerPage('services', state));
  const fields = useSelector(selectFields);
  const showDeleteConfirmation = useSelector(selectShowDeleteConfirmation);
  const isFetching = useSelector(selectIsFetching);
  const showServiceDetail = useSelector(selectShowDetail);
  const selectAll = useSelector(selectSelectAllServices);

  return (
    <CustomActionBar
      setFF={ setFF }
      servicesSelected={ servicesSelected }
      servicesCount={ servicesCount }
      page={ page }
      rowsPerPage={ rowsPerPage }
      setPage={ (page) => dispatch(setPageNumberServices(page)) }
      entity="services"
      setFieldVisibility={ (fieldObj, value) => dispatch(setFieldVisibility(fieldObj, value)) }
      setNewOrderColumns={ (columns) => dispatch(setNewOrderColumns(columns)) }
      resetDefault={ () => dispatch(resetDefault()) }
      isFetching={ isFetching }
      showDeleteConfirmation={ showDeleteConfirmation }
      fields={ fields }
      hideServiceModalDelete={ () => dispatch(hideServiceModalDelete()) }
      showServiceDetail={ showServiceDetail }
      selectAll={ selectAll }
    />
  );
};

export default ActionBar;
