export const MODAL_MANAGE_BULK_UPDATE = 'MODAL_MANAGE_BULK_UPDATE';
export const MODAL_MANAGE_BULK_UPDATE_CONFIRMATION = 'MODAL_MANAGE_BULK_UPDATE_CONFIRMATION';
export const MODAL_RUN_USER_AGENT = 'modalRunAgent';
export const MODAL_AGENT_TOKEN = 'modalAgentToken';
export const MODAL_AGENT_CREATE = 'modalAgentCreate';
export const MODAL_WORKSPACE_CREATE_EDIT = 'modalWorkspaceCreateEdit';
export const MODAL_WORKSPACE_CREATE = 'modalWorkspaceCreate';
export const MODAL_EVIDENCE = 'MODAL_EVIDENCE';
export const MODAL_CREATE_SERVICE = 'MODAL_CREATE_SERVICE';
export const MODAL_REDIRECT_CONFIRMATION = 'MODAL_REDIRECT_CONFIRMATION';
export const MODAL_APPLY_TEMPLATE = 'MODAL_APPLY_TEMPLATE';
export const MODAL_CUSTOM_ATTRIBUTES = 'MODAL_CUSTOM_ATTRIBUTES';
export const MODAL_UPGRADE_LICENSE_SIDEBAR = 'modalUpgradeLicenseSidebar';
export const MODAL_UPGRADE_LICENSE_ACTION_BAR = 'modalUpgradeLicenseActionBar';
export const MODAL_UPGRADE_LICENSE_VULN_DETAIL = 'modalUpgradeLicenseVulnDetail';
export const MODAL_UPGRADE_LICENSE_CLOUD_AGENTS = 'modalUpgradeLicenseCloudAgent';
export const MODAL_TOUR_FINISHED = 'MODAL_TOUR_FINISHED';
export const MODAL_EVIDENCE_WARNING_UPLOAD = 'MODAL_EVIDENCE_WARNING_UPLOAD';
export const MODAL_MOVE_VULN_HOST = 'MODAL_MOVE_VULN_HOST';
export const MODAL_CANT_CREATE = 'MODAL_CANT_CREATE';
export const MODAL_RUN_PIPELINE = 'modalRunPipeline';
export const MODAL_SAVE_JOB = 'modalSaveJob';
export const MODAL_JOBS_DELETE = 'MODAL_JOBS_DELETE';
export const MODAL_UPGRADE_LICENSE = 'modalUpgradeLicense';
export const MODAL_SCHEDULE_CREATE_EDIT = 'modalScheduleCreateEdit';
export const MODAL_SCHEDULER_NO_WS_WARNING = 'MODAL_SCHEDULER_NO_WS_WARNING';
export const MODAL_SCHEDULER_OFFLINE_AGENT_WARNING = 'MODAL_SCHEDULER_OFFLINE_AGENT_WARNING';
