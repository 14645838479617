import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { selectReadOnly } from 'store/Faraday/selectors';
import { saveTemplateFromVulnContext } from 'store/Manage/actions';
import IconButton from 'Common/Components/IconButton';
import SaveTemplateIcon from './styled';
import ModalWarning from 'Common/Components/ModalWarning';

const SaveAsTemplate = ({ hasVulns, vulnsSelected, entity }) => {
  const [showWarning, setShowWarning] = useState(false);
  const intl = useIntl();
  const dispatch = useDispatch();
  const title = intl.formatMessage({ id: 'manage.cm.saveAsTemplate' });
  const readOnly = useSelector(selectReadOnly);
  const canUpdate = !readOnly && hasVulns;
  const message = intl.formatMessage({ id: 'modalWarning.selectOneOrMore' }, { entity: 'vulnerabilities' });

  const saveTemplate = () => {
    dispatch(saveTemplateFromVulnContext(vulnsSelected, entity));
  };

  const handleClick = () => {
    if (canUpdate) {
      if (vulnsSelected.length > 0) saveTemplate();
      else setShowWarning(true);
    }
  };

  const onClose = () => setShowWarning(false);

  return (
    <>
      <IconButton icon={ <SaveTemplateIcon /> } title={ title } onClick={ handleClick } disabled={ !canUpdate } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title={ title } description={ message } /> }
    </>
  );
};

export default SaveAsTemplate;
