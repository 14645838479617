import React, { useEffect, useState } from 'react';
import useSideBar from 'Hooks/useSideBar';
import {
  MODAL_SCHEDULE_CREATE_EDIT, MODAL_SCHEDULER_NO_WS_WARNING,
  MODAL_SCHEDULER_OFFLINE_AGENT_WARNING
} from 'store/modals/modals';
import { useDispatch, useSelector } from 'react-redux';
import { getUserAgents } from 'store/Agents/actions';
import { selectIsFetching, selectData } from 'store/Schedule/selectors';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { closeModal } from 'store/modals/actions';
import selectModal from 'store/modals/selectors';
import CreateEditSchedule from './components/CreateEditSchedule';
import TableContainer from './components/TableContainer';
import ActionBar from './components/ActionBar';
import NoWsWarning from './components/NoWsWarning';
import Wrapper from './styled';
import OfflineAgent from './components/OfflineAgent';
import { getWorkspaces } from 'Screens/Workspaces/actions/Actions';

const Schedule = () => {
  const [ModalCreateEdit, showCreateEdit, hideCreateEdit] = useSideBar(CreateEditSchedule, MODAL_SCHEDULE_CREATE_EDIT);
  const [isCreateSchedule, setIsCreateSchedule] = useState(true);
  const dispatch = useDispatch();
  const workspaceSelected = useSelector(selectCurrentWorkspace);
  const data = useSelector((state) => (selectData(state)));
  const isFetching = useSelector((state) => (selectIsFetching(state)));
  const noSchedule = data.rows && (data.rows.length === 0 && !isFetching);
  const showNoWsWarning = useSelector((state) => selectModal(state, MODAL_SCHEDULER_NO_WS_WARNING));
  const showOfflineAgentWarning = useSelector((state) => selectModal(state, MODAL_SCHEDULER_OFFLINE_AGENT_WARNING));

  useEffect(() => {
    dispatch(getUserAgents());
  }, [dispatch, workspaceSelected]);

  useEffect(() => {
    dispatch(getWorkspaces(false, false));
  }, [dispatch]);

  useEffect(() => () => {
    dispatch(closeModal(MODAL_SCHEDULER_NO_WS_WARNING));
    dispatch(closeModal(MODAL_SCHEDULER_OFFLINE_AGENT_WARNING));
  }, [dispatch]);

  const showCreate = () => {
    setIsCreateSchedule(true);
    showCreateEdit();
  };

  const showEdit = () => {
    setIsCreateSchedule(false);
    showCreateEdit(true);
  };

  return (
    <>
      <ModalCreateEdit isCreateSchedule={ isCreateSchedule } onAccept={ () => hideCreateEdit(false) } onClose={ () => hideCreateEdit(false) } />
      <Wrapper emptySchedule={ noSchedule }>
        <ActionBar toggleModalCreateSchedule={ showCreate } toggleModalEditSchedule={ showEdit } />
        <TableContainer toggleModalEditSchedule={ showEdit } />
        { showNoWsWarning && <NoWsWarning /> }
        { showOfflineAgentWarning && <OfflineAgent /> }
      </Wrapper>
    </>
  );
};
export default Schedule;
