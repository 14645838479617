import React, { useState, useCallback } from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import BlueButton from 'Common/Components/BlueButton';
import { redirect } from 'store/Router/actions';
import { deleteJob, setRedirectTo } from 'store/Pipelines/actions';
import { selectIsCreating, selectJobsList } from 'store/Pipelines/selectors';
import ActionBar from './components/ActionBar';
import {
  Wrapper, Box, BoxWrapper, TextWrapper,
  Name, Description, Id, EditActionBar, CancelButton,
  DragIcon
} from './styled';
import JobsDetails from './components/JobDetails';

const JobItem = ({ job, pipelineId }) => {
  const {
    id, tasks
  } = job;
  const [showActionBar, setShowActionBar] = useState(false);
  const [showJobDetail, setShowJobDetail] = useState(false);
  const dispatch = useDispatch();
  const jobs = useSelector((state) => selectJobsList(state));
  const jobSelected = jobs.filter((job) => job.id === id);
  const name = jobSelected ? jobSelected[0]?.name : '';
  const description = jobSelected ? jobSelected[0]?.description : '';
  const handleOnMouseEnter = () => setShowActionBar(true);
  const handleOnMouseLeave = () => setShowActionBar(false);
  const isCreating = useSelector(selectIsCreating);

  const handleDelete = useCallback((id) => {
    dispatch(deleteJob(id));
  }, [dispatch]);

  const handleEditJob = () => {
    dispatch(redirect(`/automation/jobs/${id}`));
    if (isCreating) dispatch(setRedirectTo('/automation/pipelines/new'));
    else dispatch(setRedirectTo(`/automation/pipelines/${pipelineId}`));
  };

  return (
    <Wrapper>

      <Box onMouseEnter={ showJobDetail ? undefined : handleOnMouseEnter } onMouseLeave={ handleOnMouseLeave }>
        <BoxWrapper>
          <DragIcon />
          <TextWrapper>
            <Name>{ name }</Name>
            <Description>{ description }</Description>
            <Id>
              ID:
              { id }
            </Id>
          </TextWrapper>
          {showJobDetail
            ? (
            <EditActionBar>
              <CancelButton onClick={ () => { setShowJobDetail(false); } }>Close</CancelButton>
              <BlueButton onClick={ handleEditJob } label="Edit" className="create-button" />
            </EditActionBar>
              )
            : null }
        </BoxWrapper>

        <ActionBar showActionBar={ showActionBar } setShowJobDetail={ setShowJobDetail } setShowActionBar={ setShowActionBar } handleDelete={ () => handleDelete(id) } />
        {showJobDetail ? (<JobsDetails tasks={ tasks } id={ id } />) : null}
      </Box>
    </Wrapper>
  );
};

JobItem.defaultProps = {
  job: {}
};

JobItem.propTypes = {
  job: PropTypes.shape({
    id: PropTypes.number.isRequired,
    tasks: PropTypes.array.isRequired
  }),
  pipelineId: PropTypes.number.isRequired
};

export default JobItem;
