import { updatePreferences } from 'store/Preferences/actions';
import { selectActiveWorkspaces } from 'store/Workspace/selectors';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { redirect } from 'store/Router/actions';
import { selectWorkspaceActivity } from 'store/Preferences/workspace/selectors';
import {
  WORKSPACE_SELECTED_FOR_WORKING, CURRENT_WORKSPACE_EDITED_UPDATE,
  SET_CURRENT_WORKSPACE_AS_INVALID, SET_SHOW_WORKSPACE_VALIDATOR
} from 'Screens/Contextualization/Workspaces/actions/Actions';
import api from 'services/api';

export const SET_URL_RETURN = 'SET_URL_RETURN';
export const SET_VULNS_COUNT_SUCCESS = 'SET_VULNS_COUNT_SUCCESS';
export const SET_VULNS_COUNT_FAILURE = 'SET_VULNS_COUNT_FAILURE';
export const SET_HOSTS_COUNT_SUCCESS = 'SET_HOSTS_COUNT_SUCCESS';
export const SET_HOSTS_COUNT_FAILURE = 'SET_HOSTS_COUNT_FAILURE';
export const SET_TOTAL_COUNT_HOSTS_SUCCESS = 'SET_TOTAL_COUNT_HOSTS_SUCCESS';
export const SET_TOTAL_COUNT_HOSTS_FAILURE = 'SET_TOTAL_COUNT_HOSTS_FAILURE';

export function setUrlReturn (urlReturn) {
  return (dispatch) => {
    dispatch({ type: SET_URL_RETURN, urlReturn });
  };
}

export function updateCurrentWorkspaceOnEdit (newWsName, oldWsName) {
  return (dispatch) => {
    dispatch({
      type: CURRENT_WORKSPACE_EDITED_UPDATE, newWsName, oldWsName, redirect: false
    });
  };
}

// Summary: select a workspace for user to work
export function workspaceSelectedForWorking (workspace) {
  return async (dispatch, getState) => {
    const workspaces = selectActiveWorkspaces(getState());
    if (find(workspaces, { name: workspace })) await dispatch({ type: WORKSPACE_SELECTED_FOR_WORKING, workspace, redirect: false });
  };
}

export function saveWorkspaceHistory (ws) {
  return async (dispatch, getState) => {
    const workspaceActivity = selectWorkspaceActivity(getState());
    let newWsActivity = [];

    if (isEmpty(workspaceActivity)) newWsActivity = [ws];

    else {
      const filteredWs = workspaceActivity.filter((workspace) => workspace !== ws);
      newWsActivity = [ws, ...filteredWs];
      if (newWsActivity.length > 3) newWsActivity = [...newWsActivity.slice(0, 3)];
    }

    dispatch(updatePreferences({ workspaceActivity: newWsActivity }));
    dispatch({ type: WORKSPACE_SELECTED_FOR_WORKING, workspace: ws, redirect: true });
  };
}

export function setCurrentWorkspaceAsInvalid () {
  return (dispatch) => {
    dispatch({ type: SET_CURRENT_WORKSPACE_AS_INVALID });
  };
}

export function setShowWorkspaceValidator (show) {
  return (dispatch) => {
    dispatch({ type: SET_SHOW_WORKSPACE_VALIDATOR, show });
  };
}

export function checkWorkspace (ws) {
  return async (dispatch, getState) => {
    const workspaces = selectActiveWorkspaces(getState());
    const currentWorkspace = selectCurrentWorkspace(getState());

    if (ws && ws !== currentWorkspace) {
      if (workspaces.find((w) => w.name === ws)) {
        dispatch(workspaceSelectedForWorking(ws));
      } else {
        dispatch(redirect('/'));
      }
    }
  };
}

export const getVulnsCountInWs = () => async (dispatch, getState) => {
  const currentWorkspace = selectCurrentWorkspace(getState());
  try {
    const data = await api.workspace.fetchVulnsCountInWs(currentWorkspace);
    let vulnsCount = {};
    data.groups.forEach((group) => {
      if (group.confirmed === 'True') {
        vulnsCount = { ...vulnsCount, confirmed: group.count };
      } else {
        vulnsCount = { ...vulnsCount, unconfirmed: group.count };
      }
    });

    dispatch({ type: SET_VULNS_COUNT_SUCCESS, confirmed: vulnsCount.confirmed, unconfirmed: vulnsCount.unconfirmed, total: data.total_count });
  } catch (e) {
    dispatch({ type: SET_VULNS_COUNT_FAILURE, data: e.message || 'An error has occurred' });
  }
};

export const getVulnsCountInAllWorkspaces = () => async (dispatch) => {
  try {
    const data = await api.workspace.fetchVulnsCountAllWorkspaces();
    let vulnsCount = {};
    data.groups.forEach((group) => {
      if (group.confirmed === 'True') {
        vulnsCount = { ...vulnsCount, confirmed: group.count };
      } else {
        vulnsCount = { ...vulnsCount, unconfirmed: group.count };
      }
    });

    dispatch({ type: SET_VULNS_COUNT_SUCCESS, confirmed: vulnsCount.confirmed, unconfirmed: vulnsCount.unconfirmed, total: data.total_count });
  } catch (e) {
    dispatch({ type: SET_VULNS_COUNT_FAILURE, data: e.message || 'An error has occurred' });
  }
};

export const getHostsCountInWs = () => async (dispatch, getState) => {
  const currentWorkspace = selectCurrentWorkspace(getState());
  try {
    const data = await api.workspace.fetchHostsCountInWs(currentWorkspace);
    dispatch({ type: SET_HOSTS_COUNT_SUCCESS, total: data.total_count });
  } catch (e) {
    dispatch({ type: SET_HOSTS_COUNT_FAILURE, data: e.message || 'An error has occurred' });
  }
};

export const getHostsCountInAllWorkspaces = () => async (dispatch) => {
  try {
    const data = await api.workspace.fetchHostsCountInAllWorkspaces();

    dispatch({ type: SET_TOTAL_COUNT_HOSTS_SUCCESS, totalHosts: data.total_count });
  } catch (e) {
    dispatch({ type: SET_TOTAL_COUNT_HOSTS_FAILURE, data: e.message || 'An error has occurred' });
  }
};
