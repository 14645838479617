export const GET_HOSTS_DATA_START = 'GET_HOSTS_DATA_START';
export const GET_HOSTS_DATA_SUCCESS = 'GET_HOSTS_DATA_SUCCESS';
export const GET_HOSTS_DATA_FAILURE = 'GET_HOSTS_DATA_FAILURE';
export const GENERAL_HOSTS_SELECTED = 'GENERAL_HOSTS_SELECTED';
export const GENERAL_HOSTS_UNSELECTED = 'GENERAL_HOSTS_UNSELECTED';
export const GENERAL_HOSTS_NEW_PIVOT = 'GENERAL_HOSTS_NEW_PIVOT';
export const GENERAL_HOSTS_SELECT_ALL_HOST = 'GENERAL_HOSTS_SELECT_ALL_HOST';
export const GENERAL_HOSTS_UNSELECT_ALL_HOST = 'GENERAL_HOSTS_UNSELECT_ALL_HOST';
export const GENERAL_HOSTS_CREATE_SUCCESS = 'GENERAL_HOSTS_CREATE_SUCCESS';
export const GENERAL_HOSTS_CREATE_FAIL = 'GENERAL_HOSTS_CREATE_FAIL';
export const GENERAL_HOSTS_SHOW_DETAIL = 'GENERAL_HOSTS_SHOW_DETAIL';
export const GENERAL_HOSTS_HIDE_DETAIL = 'GENERAL_HOSTS_HIDE_DETAIL';
export const GENERAL_HOSTS_DELETE_HOSTS_START = 'GENERAL_HOSTS_DELETE_HOSTS_START';
export const GENERAL_HOSTS_DELETE_HOSTS_SUCCESS = 'GENERAL_HOSTS_DELETE_HOSTS_SUCCESS';
export const GENERAL_HOSTS_DELETE_HOSTS_FAIL = 'GENERAL_HOSTS_DELETE_HOSTS_FAIL';
export const GET_HOSTS_DATA_GROUP_BY_RESET = 'GET_HOSTS_DATA_GROUP_BY_RESET';
export const GET_HOSTS_DATA_GROUP_BY_EXPAND = 'GET_HOSTS_DATA_GROUP_BY_EXPAND';
export const GENERAL_HOSTS_SHOW_FILTERS = 'GENERAL_HOSTS_SHOW_FILTERS';
export const GENERAL_HOSTS_CLEAR_ERROR = 'GENERAL_HOSTS_CLEAR_ERROR';
export const GENERAL_HOSTS_SET_BULK_UPDATE_FIELD = 'GENERAL_HOSTS_SET_BULK_UPDATE_FIELD';
export const GENERAL_HOSTS_SET_BULK_UPDATE_VALUE = 'GENERAL_HOSTS_SET_BULK_UPDATE_VALUE';
export const GENERAL_HOSTS_ADD_BULK_UPDATE_VALUE = 'GENERAL_HOSTS_ADD_BULK_UPDATE_VALUE';
export const GENERAL_HOSTS_REMOVE_BULK_UPDATE_VALUE = 'GENERAL_HOSTS_REMOVE_BULK_UPDATE_VALUE';
export const GENERAL_HOSTS_SHOW_CONTEXT_MENU = 'GENERAL_HOSTS_SHOW_CONTEXT_MENU';
export const GENERAL_HOSTS_REFRESH_LIST = 'GENERAL_HOSTS_REFRESH_LIST';
export const GENERAL_HOSTS_CONFIRMATION_CHANGE_START = 'GENERAL_HOSTS_CONFIRMATION_CHANGE_START';
export const GENERAL_HOSTS_SET_BULK_UPDATE_SUCCESS = 'GENERAL_HOSTS_SET_BULK_UPDATE_SUCCESS';
export const GENERAL_HOSTS_BULK_UPDATE_FINISHED = 'GENERAL_HOSTS_BULK_UPDATE_FINISHED';
export const GENERAL_HOSTS_ERROR = 'GENERAL_HOSTS_ERROR';
export const GENERAL_HOSTS_UPDATE_HOSTS_LIST = 'GENERAL_HOSTS_UPDATE_HOSTS_LIST';
export const GENERAL_HOSTS_SELECT_TOTAL_ASSETS = 'GENERAL_HOSTS_SELECT_TOTAL_ASSETS';
export const GENERAL_HOSTS_UNSELECT_TOTAL_ASSETS = 'GENERAL_HOSTS_UNSELECT_TOTAL_ASSETS';
