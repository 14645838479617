import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import {
  getServicesGeneral, hideServiceDetail, loadServiceDetail,
  clearError, resetServiceDetail, updateService,
  redirectToServices
} from 'store/ServicesGeneral/actions';
import ServicesTable from './components/ServicesTable';
import Wrapper from './styled';
import ActionBar from './components/ActionBar';
import { closeModal } from 'store/modals/actions';
import ReduxModal from 'Common/Components/ReduxModal';
import { MODAL_CANT_CREATE, MODAL_CREATE_SERVICE, MODAL_UPGRADE_LICENSE_ACTION_BAR } from 'store/modals/modals';
import UpgradeLicense from 'Common/Components/UpgradeLicense';
import ErrorDialog from 'Common/Components/ErrorDialog';
import { selectError, selectErrorMessage, selectServiceDetail, selectServiceDetailDescription, selectServiceDetailId, selectServiceDetailOwned, selectShowDetail } from 'store/ServicesGeneral/selectors';
import CreateService from 'Screens/Contextualization/Services/components/CreateService';
import ServiceDetail from 'Screens/Contextualization/Services/components/ServiceDetail';
import WarningModal from 'Screens/Contextualization/ManageEditCreate/components/WarningModal';
import selectModal from 'store/modals/selectors';
import { selectTotalHostsCount } from 'store/Faraday/selectors';
import { selectEmptyActiveWorkspaces } from 'store/Workspace/selectors';
import { getHostsCountInAllWorkspaces, getVulnsCountInAllWorkspaces } from '../Faraday/actions/Actions';
import SelectAll from './components/ServicesTable/components/SelectAll';

const ServicesGeneral = ({ params }) => {
  const dispatch = useDispatch();
  const [FFImage, setFFImage] = useState(null);
  const [FFDescription, setFFDescription] = useState('');
  const error = useSelector(selectError);
  const errorMessage = useSelector(selectErrorMessage);
  const showDetail = useSelector(selectShowDetail);
  const data = useSelector(selectServiceDetail);
  const serviceId = useSelector(selectServiceDetailId);
  const owned = useSelector(selectServiceDetailOwned);
  const description = useSelector(selectServiceDetailDescription);
  const showModalCantCreate = useSelector((state) => selectModal(state, MODAL_CANT_CREATE));
  const assetsCountAllWorkspaces = useSelector(selectTotalHostsCount);
  const noAssetsFromAllWorkspaces = assetsCountAllWorkspaces === 0;
  const noWorkspaces = useSelector(selectEmptyActiveWorkspaces);

  const handleParamId = useCallback(() => {
    const id = get(params, 'id', 0);
    if (parseInt(id, 10) > 0) dispatch(loadServiceDetail(id));
    else dispatch(hideServiceDetail());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    dispatch(getServicesGeneral());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleParamId();
  }, [handleParamId]);

  useEffect(() => {
    dispatch(getVulnsCountInAllWorkspaces());
    dispatch(getHostsCountInAllWorkspaces());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFF = (image, description) => {
    setFFImage(image);
    setFFDescription(description);
  };

  return (
    <Wrapper>
      <ActionBar setFF={ setFF } />
      <SelectAll />
      <ServicesTable />
      { showDetail &&
        <ServiceDetail
          data={ data }
          resetServiceDetail={ resetServiceDetail }
          serviceId={ serviceId }
          owned={ owned }
          updateService={ (id, obj) => dispatch(updateService(id, obj)) }
          redirectToService={ redirectToServices }
          loadServiceDetail={ loadServiceDetail }
          description={ description }
        />
      }
      <ReduxModal id={ MODAL_CREATE_SERVICE }>
        <CreateService onClose={ () => dispatch(closeModal(MODAL_CREATE_SERVICE)) } entity="servicesGeneral" />
      </ReduxModal>
      <ReduxModal id={ MODAL_UPGRADE_LICENSE_ACTION_BAR }>
        <UpgradeLicense id={ MODAL_UPGRADE_LICENSE_ACTION_BAR } image={ FFImage } descriptionIntlId={ FFDescription } />
      </ReduxModal>
      { error ? <ErrorDialog message={ errorMessage } resetError={ () => dispatch(clearError()) } /> : null }
      { showModalCantCreate && <WarningModal entity="Service" noAssetsInAllWorkspaces={ noAssetsFromAllWorkspaces } noWorkspaces={ noWorkspaces } /> }
    </Wrapper>
  );
};

export default ServicesGeneral;
