import React from 'react';
import get from 'lodash/get';

import { ReactComponent as Dashboard } from 'Images/manage.svg';
import { ReactComponent as Automation } from 'Images/automation.svg';
import { ReactComponent as Planner } from 'Images/tasks_icon.svg';
import { ReactComponent as Reports } from 'Images/reports.svg';
import { ReactComponent as Analytics } from 'Images/analytics.svg';
import { ReactComponent as Users } from 'Images/icon_users.svg';
import { ReactComponent as KnowledgeBase } from 'Images/vuln-kb.svg';

import FFAnalytics from 'Images/ff_analytics.png';
import FFPlanner from 'Images/ff_planner.png';
import FFReports from 'Images/ff_reporting.png';
import FFUsers from 'Images/ff_users.png';

export const selectIsOpen = (state) => get(state, 'sideBar.isOpen', false);
export const selectPagesData = (state) => {
  return [
    {
      name: 'Management',
      icon: <Dashboard />,
      subMenu: [
        {
          name: 'Management',
          url: '/workspaces',
          enabledByRole: true,
          enabledByFeature: true
        }
      ]
    },
    {
      name: 'Knowledge Base',
      icon: <KnowledgeBase />,
      subMenu: [
        {
          name: 'Knowledge Base',
          url: '/knowledge_base',
          enabledByRole: true,
          enabledByFeature: true
        }
      ]
    },
    {
      name: 'Analytics',
      icon: <Analytics />,
      subMenu: [
        {
          name: 'Analytics',
          url: null,
          enabledByRole: true,
          enabledByFeature: false,
          FF: {
            image: FFAnalytics,
            description: 'upgradeLicense.sidebar.analytics.description'
          }
        }
      ]
    },
    {
      name: 'Planner',
      icon: <Planner />,
      subMenu: [
        {
          name: 'Planner',
          url: null,
          enabledByRole: true,
          enabledByFeature: false,
          FF: {
            image: FFPlanner,
            description: 'upgradeLicense.sidebar.planner.description'
          }
        }
      ]
    },
    {
      name: 'Reports',
      icon: <Reports />,
      subMenu: [
        {
          name: 'Reports',
          url: null,
          enabledByRole: true,
          enabledByFeature: false,
          FF: {
            image: FFReports,
            description: 'upgradeLicense.sidebar.reporting.description'
          }
        }
      ]
    },
    {
      name: 'Automation',
      icon: <Automation />,
      subMenu: [
        {
          name: 'Agents',
          url: '/automation/agents',
          enabledByRole: true,
          enabledByFeature: true
        },
        {
          name: 'Scheduler',
          url: '/automation/schedule',
          enabledByRole: true,
          enabledByFeature: true
        },
        {
          name: 'Pipelines',
          url: '/automation/pipelines',
          enabledByRole: true,
          enabledByFeature: true
        },
        {
          name: 'Jobs',
          url: '/automation/jobs',
          enabledByRole: true,
          enabledByFeature: true
        }
      ]
    },
    {
      name: 'Users',
      icon: <Users />,
      subMenu: [
        {
          name: 'Users',
          url: null,
          enabledByRole: true,
          enabledByFeature: false,
          FF: {
            image: FFUsers,
            description: 'upgradeLicense.sidebar.users.description'
          }
        }
      ]
    }
  ];
};
