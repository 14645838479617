import get from 'lodash/get';
// import { selectMaxSchedulesLimit } from 'store/Config/selector';

export const selectFields = (state) => get(state, 'schedule.table.fields', []); // obtiene el listado de campos para la tabla

export const selectIsFetching = (state) => get(state, 'schedule.isFetching', false);

export const selectData = (state) => get(state, 'schedule.table.list.data', []);

export const selectTotal = (state) => get(state, 'schedule.table.list.total', 0);

export const selectRowsSelected = (state) => get(state, 'schedule.table.selected.rowsSelected', []);

export const selectLastRowSelected = (state) => get(state, 'schedule.table.selected.lastRowSelected', {});

export const selectLastIndexForRange = (state) => get(state, 'schedule.table.selected.lastIndexForRange', {});

export const selectLastSelected = (state) => get(state, 'schedule.table.selected.lastSelected', {});

export const selectWorkspaceSelected = (state) => get(state, 'faraday.workspaceSelected', {});

// selecciono el primer item del array, corresponde al ultimo row seleccionado en la tabla
export const selectAgentExecutors = (state) => get(state, 'schedule.table.selected.rowsSelected[0].value.agent.executors', {});

// devuelve el total de schedules activos
export const selectTotalSchedules = (state) => {
  const schedules = get(state, 'schedule.table.list.data.rows', []);
  return schedules.length;
};
