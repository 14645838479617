import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { selectReadOnly } from 'store/Faraday/selectors';
import IconButton from 'Common/Components/IconButton';
import { Trash } from '../../styled';
import ModalWarning from 'Common/Components/ModalWarning';

const DeleteButton = ({ hasVulns, vulnsSelected, showVulnDetail, showModalDelete }) => {
  const [showWarning, setShowWarning] = useState(false);
  const readOnly = useSelector(selectReadOnly);
  const canUpdate = !readOnly && !showVulnDetail && hasVulns;
  const intl = useIntl();
  const message = intl.formatMessage({ id: 'modalWarning.selectOneOrMore' }, { entity: 'vulnerabilities' });

  const handleClick = () => {
    if (canUpdate) {
      if (vulnsSelected.length > 0) showModalDelete();
      else setShowWarning(true);
    }
  };

  const onClose = () => setShowWarning(false);

  return (
    <>
      <IconButton icon={ <Trash /> } title="Delete" onClick={ handleClick } disabled={ !canUpdate } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title="Delete" description={ message } /> }
    </>
  );
};

export default DeleteButton;
