import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Checked } from 'Images/Checkbox_ok.svg';
import { ReactComponent as Unchecked } from 'Images/Checkbox_empty.svg';
import {
  Wrapper, CheckboxContainer, Label, Span
} from './styled';

const StyledCheckbox = ({ checked, onChange, label }) => (
  <Wrapper>
    <Label>
      <CheckboxContainer>
        {checked && <Checked onClick={ onChange } />}
        {!checked && <Unchecked onClick={ onChange } />}
      </CheckboxContainer>
      {label && <Span>{label}</Span>}
    </Label>
  </Wrapper>
);

StyledCheckbox.defaultProps = {
  label: ''
};

StyledCheckbox.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

export default StyledCheckbox;
