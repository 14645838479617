import uniq from 'lodash/uniq';
import { copy } from 'Common/Functions/Copy';
import { HOST_CREATE_UPDATE_SUCCESS } from 'store/HostEditCreate/types';
import * as types from './types';
import initialState from './initialState';

function host (state = initialState, action) {
  switch (action.type) {
    case types.SHOW_HOST_LEFT_FILTERS: {
      return {
        ...state,
        showLeftFilters: action.visible
      };
    }
    case types.SET_HOSTS_FILTER_ERROR: {
      return {
        ...state,
        isFetching: false
      };
    }
    case types.GET_DATA_HOST_START: {
      return {
        ...state,
        isFetching: true
      };
    }
    case types.GET_DATA_HOST_FAIL: {
      return {
        ...state,
        isFetching: false,
        error: true,
        errorMessage: action.data
      };
    }
    case types.GET_DATA_HOST_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        hostList: action.data,
        hostCount: action.hostCount,
        hostDetail: action.host ? copy(action.host) : false
      };
    }
    case types.UPDATE_DATA_HOST_LIST: {
      return {
        ...state,
        hostList: action.hostList
      };
    }
    case types.APPEND_DATA_HOST_LIST: {
      const { hostList } = state;
      return {
        ...state,
        hostList: [action.hostRow, ...hostList]
      };
    }
    case types.GET_DATA_HOST_SEARCH: {
      return {
        ...state,
        filters: action.value
      };
    }
    case types.GET_DATA_HOST_GROUP_BY_RESET: {
      return {
        ...state,
        groupBy: '',
        mapGroupByIndex: []
      };
    }
    case types.GET_DATA_HOST_GROUP_BY_EXPAND: {
      const { mapGroupByIndex } = state;
      const newMapGroupByIndex = { ...mapGroupByIndex, [action.index]: action.data };
      const hostList = state.hostList;
      hostList[action.index].groupData = action.data;

      return {
        ...state,
        mapGroupByIndex: newMapGroupByIndex,
        hostList,
        isFetching: false
      };
    }
    case HOST_CREATE_UPDATE_SUCCESS: {
      return {
        ...state,
        showModalEditCreate: false
      };
    }
    case types.TOGGLE_MODAL_EDIT_CREATE_HOST: {
      return {
        ...state,
        showModalEditCreate: action.value
      };
    }
    case types.HOST_SELECTED: {
      return {
        ...state,
        hostsSelected: uniq([...state.hostsSelected, ...action.payload])
      };
    }
    case types.HOST_UNSELECTED: {
      const filterByID = (array, id) => array.filter((el) => el._id !== id);
      const newHostsSelected = action.payload.reduce((hostsSelected, hostToDelete) => filterByID(hostsSelected, hostToDelete._id), state.hostsSelected);

      return {
        ...state,
        hostsSelected: newHostsSelected
      };
    }
    case types.NEW_PIVOT: {
      return {
        ...state,
        lastSelected: action.payload
      };
    }
    case types.SELECT_ALL_HOST: {
      return {
        ...state,
        hostsSelected: [...action.hostsList]
      };
    }
    case types.UNSELECT_ALL_HOST: {
      return {
        ...state,
        hostsSelected: []
      };
    }
    case types.RESET_STATE_HOST: {
      return {
        ...copy(initialState),
        fields: state.fields,
        page: 1,
        hostSelectedForWorking: state.hostSelectedForWorking,
        hostList: state.hostList
      };
    }
    case types.SHOW_MODAL_DELETE_HOSTS: {
      return {
        ...state,
        showDeleteConfirmation: true
      };
    }
    case types.HIDE_MODAL_DELETE_HOSTS: {
      return {
        ...state,
        showDeleteConfirmation: false
      };
    }
    case types.DELETE_HOSTS_START: {
      return {
        ...state,
        isFetching: true
      };
    }
    case types.DELETE_HOSTS_SUCCESS: {
      return {
        ...state,
        hostsSelected: [],
        isFetching: false
      };
    }
    case types.DELETE_HOSTS_FAIL: {
      return {
        ...state,
        isFetching: false
      };
    }
    case types.UPDATE_HOST_SELECTED: {
      return {
        ...state,
        hostsSelected: action.host
      };
    }

    // context
    case types.REMOVE_CURRENT_HOST: {
      return {
        ...state,
        hostSelectedForWorking: []
      };
    }
    case types.SET_HOST_FOR_WORKING: {
      return {
        ...state,
        hostSelectedForWorking: [action.host]
      };
    }

    case types.ASSETS_APPEND_DATA_HOST_LIST: {
      const { hostList } = state;
      return {
        ...state,
        hostList: [action.hostRow, ...hostList],
        hostCount: state.hostCount + 1
      };
    }

    case types.ASSET_SELECTED: {
      return {
        ...state,
        hostsSelected: uniq([...state.hostsSelected, ...action.payload]),
        hostSelectedForWorking: action.payload
      };
    }

    case types.ASSET_UNSELECTED: {
      const filterByID = (array, id) => array.filter((el) => el._id !== id);
      const newHostsSelected = action.payload.reduce((hostsSelected, hostToDelete) => filterByID(hostsSelected, hostToDelete._id), state.hostsSelected);

      return {
        ...state,
        hostsSelected: newHostsSelected
      };
    }

    case types.ASSETS_SET_BULK_UPDATE_FIELD: {
      return {
        ...state,
        bulkUpdateField: action.field,
        bulkUpdateValue: ''
      };
    }

    case types.ASSETS_SET_BULK_UPDATE_VALUE: {
      return {
        ...state,
        bulkUpdateValue: action.value
      };
    }

    case types.ASSETS_ADD_BULK_UPDATE_VALUE: {
      const bulkUpdateValue = state.bulkUpdateValue;
      bulkUpdateValue.push(action.value);

      return {
        ...state,
        bulkUpdateValue
      };
    }

    case types.ASSETS_REMOVE_BULK_UPDATE_VALUE: {
      let bulkUpdateValue = state.bulkUpdateValue;
      bulkUpdateValue = bulkUpdateValue.filter((element) => element !== action.value);

      return {
        ...state,
        bulkUpdateValue
      };
    }

    case types.ASSETS_SHOW_CONTEXT_MENU: {
      return {
        ...state,
        showContextMenu: action.show,
        contextMenuXPos: action.contextMenuXPos,
        contextMenuYPos: action.contextMenuYPos
      };
    }

    case types.ASSETS_REFRESH_LIST: {
      return {
        ...state,
        hostList: action.assetsList,
        hostCount: action.assetsCount,
        hostsSelected: action.assetsSelected,
        hostDetail: action.assetDetail
      };
    }

    case types.ASSETS_CONFIRMATION_CHANGE_START: {
      return {
        ...state,
        isFetching: true,
        isFetchingExpandedData: true,
        error: false,
        errorMessage: ''
      };
    }

    case types.ASSETS_SET_BULK_UPDATE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        isFetchingExpandedData: false,
        error: false,
        errorMessage: ''
      };
    }

    case types.ASSETS_BULK_UPDATE_FINISHED: {
      return {
        ...state,
        isFetching: false,
        isFetchingExpandedData: false,
        bulkUpdateField: '',
        bulkUpdateValue: ''
      };
    }

    case types.ASSETS_ERROR: {
      return {
        ...state,
        error: true,
        errorMessage: action.errorMessage
      };
    }

    case types.SELECT_TOTAL_ASSETS: {
      return {
        ...state,
        selectAll: true
      };
    }

    case types.UNSELECT_TOTAL_ASSETS: {
      return {
        ...state,
        selectAll: false
      };
    }

    default: {
      return state;
    }
  }
}

export default host;
