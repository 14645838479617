import React from 'react';
import SearchBar from 'Common/Components/Contextualization/SearchBar/index';
import 'Common/styles/commonStyles.scss';
import { useSelector } from 'react-redux';
import { selectTotalVulns } from 'store/Workspace/selectors';
import { selectPathname } from 'store/Router/selectors';
import { selectVulnsCount } from 'store/Manage/selectors';

const Searcher = () => {
  const pathname = useSelector(selectPathname);
  const totalVulns = useSelector(selectTotalVulns);
  const vulnsCountManage = useSelector(selectVulnsCount);

  const canShowNewSearcher =
    (pathname.includes('/manage') && (totalVulns > 0 || vulnsCountManage > 0)) ||
    (pathname.includes('/host') && !pathname.includes('/services')) ||
    (pathname.includes('/knowledge_base')) ||
    pathname.includes('/vulnerabilities') ||
    pathname.includes('/workspaces');

  if (canShowNewSearcher) return <SearchBar />;

  return null;
};

export default Searcher;
