import React from 'react';
import get from 'lodash/get';
import ContextMenu from 'Common/Components/ContextMenu';
import { useDispatch, useSelector } from 'react-redux';
import { selectReadOnly } from 'store/Faraday/selectors';
import {
  selectContextMenuXPos, selectContextMenuYPos, selectHostsSelected,
  selectShowContextMenu
} from 'store/HostsGeneral/selectors';
import { selectPermissions } from 'store/Host/selectors';
import { showContextMenu } from 'store/HostsGeneral/actions';
import { Delete, Edit } from './components';

const AssetsContextMenu = () => {
  const dispatch = useDispatch();
  const displayContextMenu = useSelector(selectShowContextMenu);
  const xPos = useSelector(selectContextMenuXPos);
  const yPos = useSelector(selectContextMenuYPos);
  const assetsSelected = useSelector(selectHostsSelected);
  const areAssetsSelected = assetsSelected.length > 0;
  const readOnly = useSelector(selectReadOnly);
  const permissions = useSelector(selectPermissions);
  const cantUpdate = !get(permissions, 'update.allowed', false);

  if (readOnly || cantUpdate || !displayContextMenu || !areAssetsSelected) return null;
  return (
    <ContextMenu
      show={ displayContextMenu }
      xPos={ xPos }
      yPos={ yPos }
      displayFunction={ (show, x, y) => dispatch(showContextMenu(show, x, y)) }
    >
      <Edit />
      <Delete />
    </ContextMenu>
  );
};

export default AssetsContextMenu;
