/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InputFilter from 'Common/Components/InputFilter';
import {
  getTemplates, setField, addPolicy, removePolicy,
  selectCheckbox, addReference, removeReference,
  addCVE, removeCVE, getHosts, addAsset, removeAsset,
  addWorkspace, removeWorkspace, removeGroupAsset,
  setCreateVulnError
} from 'Screens/Contextualization/ManageEditCreate/actions/Actions';
import {
  selectTemplates,
  selectManageEditCreate,
  selectNameField,
  selectAllHosts,
  selectTargetsHosts,
  selectWorkspacesField,
  selectTargetsHostsOutsideWs
} from 'store/ManageEditCreate/selectors';
import SeverityDropdown from 'Common/Components/SeverityDropdown';
import EORDropdown from 'Common/Components/EORDropdown';
import CustomList from 'Common/Components/CustomList';
import Impacts from 'Common/Components/Impacts';
import MarkdownInput from '../MarkdownInput';
import {
  Wrapper, Row, ExternalId, NewColumn, Gap, Title, Box
} from './styled';
import debounce from 'lodash/debounce';
import CVE from './InputCVE';
import InputSearch from './InputSearch';
import { selectCurrentHost } from 'store/Host/selectors';
import { selectLocation } from 'store/Router/selectors';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { selectActiveWorkspaces } from 'store/Workspace/selectors';
import InputFilterGrouped from 'Common/Components/Contextualization/InputFilterGrouped';
import isEmpty from 'lodash/isEmpty';
import { getAssetsByWorkspace } from 'store/ServicesGeneral/actions';
import { selectAssetsList } from 'store/ServicesGeneral/selectors';
import colors from 'Styles/colors';
import { useIntl } from 'react-intl';

const General = ({ assets, setAssets }) => {
  const templates = useSelector(selectTemplates);
  const manageEditCreate = useSelector(selectManageEditCreate);
  const name = useSelector(selectNameField);
  const hosts = useSelector(selectAllHosts);
  const addedHosts = useSelector(selectTargetsHosts);
  const assetForWorking = useSelector(selectCurrentHost);
  const createFromAsset = Object.keys(assetForWorking).length > 0;
  const dispatch = useDispatch();
  const location = useSelector(selectLocation);
  const currentWs = useSelector(selectCurrentWorkspace);
  const isOutsideWorkspace = location.pathname.startsWith('/vulnerabilities') && !location.pathname.includes(`/manage/${currentWs}`);
  const workspacesOptions = useSelector(selectActiveWorkspaces);
  const addedWorkspaces = useSelector(selectWorkspacesField);
  const assetsByWorkspace = useSelector(selectAssetsList);
  const addedHostsOutsideWs = useSelector((state) => selectTargetsHostsOutsideWs(state, isOutsideWorkspace));
  const cantSelectAsset = isOutsideWorkspace && isEmpty(addedWorkspaces);

  const intl = useIntl();
  const assetsErrorTitle = intl.formatMessage({ id: 'modal.createVuln.missingWs.title' });
  const assetsErrorMessage = intl.formatMessage({ id: 'modal.createVuln.missingWs.message' });

  const handleChange = (field, value) => {
    dispatch(setField(field, value));
  };

  const debouncedSetField = debounce(handleChange, 250);

  const applyTemplate = (template) => {
    dispatch(setField('name', template.name));
    dispatch(setField('description', template.description));
    dispatch(setField('resolution', template.resolution));
    dispatch(setField('severity', template.exploitation));
    dispatch(setField('policies', template.policyviolations));
    dispatch(setField('references', template.refs));
    dispatch(setField('externalId', template.external_id));
    dispatch(setField('accountability', template.impact.accountability));
    dispatch(setField('availability', template.impact.availability));
    dispatch(setField('integrity', template.impact.integrity));
    dispatch(setField('confidentiality', template.impact.confidentiality));
    dispatch(setField('easeOfResolution', template.easeofresolution));
    dispatch(setField('data', template.data));
    dispatch(setField('customFields', template.customfields));
    dispatch(setField('CVE', template.cve));
  };

  const setTarget = (value) => {
    const temp = addedHosts;
    const found = temp.find((item) => item.id === value.id);
    if (!found) {
      dispatch(addAsset(value));
      setAssets([...assets, value]);
    }
  };

  const onRemoveTarget = (value) => {
    const temp = addedHosts;
    const index = temp.findIndex((item) => item.id === value.id);
    if (index > -1) {
      temp.splice(index, 1);
      setAssets(temp);
      dispatch(removeAsset(value));
    }
  };
  const impacts = {
    accountability: manageEditCreate.accountability,
    availability: manageEditCreate.availability,
    confidentiality: manageEditCreate.confidentiality,
    integrity: manageEditCreate.integrity
  };

  const getHostsOptions = () => {
    if (!isOutsideWorkspace) dispatch(getHosts());
  };

  const setWorkspace = (value) => {
    const temp = addedWorkspaces;
    const found = temp.find((item) => item.id === value.id);
    if (!found) {
      dispatch(addWorkspace(value));
      getHostsOptions();
    }
  };

  const onRemoveWorkspace = (value) => {
    const temp = addedWorkspaces;
    const index = temp.findIndex((item) => item.id === value.id);
    if (index > -1) {
      if (!isEmpty(assets)) {
        const filteredAssets = assets.filter((asset) => asset.value.workspace_name !== value.name);
        setAssets(filteredAssets);
      }
      temp.splice(index, 1);
      dispatch(removeWorkspace(value));
      getHostsOptions();
    }
  };

  const setTargetOutsideWs = (element) => {
    if (element) {
      const temp = addedHostsOutsideWs;
      const found = temp.find((item) => item.id === element[element.length - 1].value.id);
      if (!found) {
        dispatch(addAsset(element[element.length - 1]));
        setAssets([...assets, element[element.length - 1]]);
      }
    }
  };

  const removeTargetOutsideWs = (value) => {
    setAssets(value);
    dispatch(removeGroupAsset(value));
  };

  useEffect(() => {
    if (!isEmpty(addedWorkspaces) && isOutsideWorkspace) dispatch(getAssetsByWorkspace(addedWorkspaces));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedWorkspaces]);

  return (
    <Wrapper>
      <NewColumn>
      { isOutsideWorkspace &&
          <>
            <InputSearch
              onSelect={ setWorkspace }
              data={ workspacesOptions }
              placeholder="Add a Workspace"
              id="vuln-add-workspaces"
              title="Workspace(s)"
              required
              addedItems={ addedWorkspaces }
              removeItem={ onRemoveWorkspace }
            />
            <Gap />
          </>
        }
        { !createFromAsset && !isOutsideWorkspace &&
          <>
            <InputSearch
              getObjects={ () => dispatch(getHosts()) }
              onSelect={ setTarget }
              data={ hosts }
              placeholder="Add a Asset"
              id="vuln-add-assets"
              title="Asset(s)"
              required
              addedItems={ addedHosts }
              removeItem={ onRemoveTarget }
            />
            <Gap />
          </>
        }
        <Title required>Vulnerability Name</Title>
        <InputFilter
          getObjects={ () => dispatch(getTemplates()) }
          data={ templates }
          width="405px"
          height="38px"
          placeholder="Search or give a name to your Vulnerability"
          onSelect={ applyTemplate }
          onChange={ debouncedSetField }
          value={ name }
        />
        <Gap />
        <Title>CVE</Title>
        <CVE
          listData={ manageEditCreate.CVE }
          onAdd= { (val) => dispatch(addCVE(val)) }
          onRemove={ (val) => dispatch(removeCVE(val)) }
        />
        <Gap />
        <MarkdownInput
          title="Description"
          value={ manageEditCreate.description }
          onBlur={ (value) => debouncedSetField('description', value) }
          isRequired
          minHeight={ 130 }
        />
        <ExternalId
          width="405px"
          height="38px"
          title="External ID"
          name="external_id"
          defaultValue={ manageEditCreate.externalId || '' }
          onChange={ (value) => debouncedSetField('externalId', value) }
        />
        <Gap />
        <CustomList
          key="CL_POLICY_VIOLATIONS"
          title="Policy Violations"
          placeholder="Add a Policy Violation"
          addItem={ (val) => dispatch(addPolicy(val)) }
          removeItem={ (index) => dispatch(removePolicy(index)) }
          listData={ manageEditCreate.policies || [] }
          responsive
        />
      </NewColumn>
      <NewColumn>
      { isOutsideWorkspace &&
          <Box>
            <InputFilterGrouped
              options={ isEmpty(addedWorkspaces) ? [] : assetsByWorkspace }
              required
              title="Asset(s)"
              id="vulns-general-add-assets"
              placeholder="Add a Asset"
              cantSelect={ cantSelectAsset }
              groupNameField="workspace_name"
              addItems={ setTargetOutsideWs }
              addedItems={ assets }
              setItems={ (elements) => removeTargetOutsideWs(elements) }
              width="386px"
              bgColor={ colors.iceBlue }
              menuHeight="290px"
              setError={ () => dispatch(setCreateVulnError(true, assetsErrorMessage, assetsErrorTitle)) }
            />
          </Box>
        }
        { !createFromAsset && <><Gap /><Gap /></> }
        <Row>
          <SeverityDropdown updateValue={ (fieldName, value) => dispatch(setField(fieldName, value)) } defaultValue={ manageEditCreate.severity || '' } context />
          <EORDropdown updateValue={ (fieldName, value) => dispatch(setField(fieldName, value)) } defaultValue={ manageEditCreate.easeOfResolution || '' } />
        </Row>
        <Gap />
        <Box>
          <MarkdownInput
            title="Resolution"
            value={ manageEditCreate.resolution }
            onBlur={ (value) => debouncedSetField('resolution', value) }
            minHeight={ 317 }
          />
        </Box>
        <Impacts selectCheckbox={ (field) => dispatch(selectCheckbox(field)) } impacts={ impacts } />
        <Gap />
        <Box>
          <CustomList
            key="REFERENCES"
            title="References"
            placeholder="Add Reference"
            addItem={ (val) => dispatch(addReference(val)) }
            removeItem={ (index) => dispatch(removeReference(index)) }
            listData={ manageEditCreate.references || [] }
            responsive
          />
        </Box>
      </NewColumn>
    </Wrapper>
  );
};

export default withRouter(General);
