/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ActionBar from './ActionBar';
import { resetFilters } from 'store/Filters/actions';
import get from 'lodash/get';
import { TableWrapper, Wrapper } from './styled';
import Table from './Table';
import { clearError, hideVulnTemplateDetail, loadVulnTemplateDetail } from '../actions/Actions';
import { selectError, selectErrorMessage, selectShowDetail } from 'store/KnowledgeBase/selectors';
import VulnTemplateDetail from './VulnTemplateDetail';
import ErrorDialog from 'Common/Components/ErrorDialog';

const KnowledgeBaseContext = ({ params }) => {
  const showDetail = useSelector(selectShowDetail);
  const dispatch = useDispatch();
  const error = useSelector(selectError);
  const errorMessage = useSelector(selectErrorMessage);

  const handleParamId = useCallback(() => {
    const id = get(params, 'id', 0);
    if (parseInt(id, 10) > 0) dispatch(loadVulnTemplateDetail(id));
    else dispatch(hideVulnTemplateDetail());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    handleParamId();
  }, [handleParamId]);

  useEffect(() => () => {
    dispatch(resetFilters('knowledgeBase'));
  }, [dispatch]);

  return (
    <Wrapper>
      <ActionBar />
      <TableWrapper>
        <Table />
        { showDetail && <VulnTemplateDetail /> }
      </TableWrapper>
      { error ? <ErrorDialog message={ errorMessage } resetError={ () => dispatch(clearError()) } /> : null }
    </Wrapper>
  );
};

export default KnowledgeBaseContext;
