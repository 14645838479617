import fetchApi from 'services/api/connector';

export const getVulnsCount = () => fetchApi('vulns/count?group_by=confirmed');

export const fetchVulns = (queryParam) => fetchApi(`vulns/filter?q=${escape(JSON.stringify(queryParam))}`);

export const fetchVulnById = (id) => fetchApi(`vulns/${id}`);

export const deleteVulns = (ids) => fetchApi('vulns', { method: 'DELETE', data: { ids } });

export const deleteAllVulns = (queryParam) => fetchApi(`vulns?q=${encodeURI(JSON.stringify(queryParam))}`, { method: 'DELETE', version: 'v3' });

export const updateVulns = (data) => fetchApi('vulns', { method: 'PATCH', data, version: 'v3' });

export const updateAllVulns = (queryParam, data) => fetchApi(`vulns?q=${encodeURI(JSON.stringify(queryParam))}`, { method: 'PATCH', data, version: 'v3' });
