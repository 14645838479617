import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectReadOnly } from 'store/Faraday/selectors';
import { useIntl } from 'react-intl';
import IconButton from 'Common/Components/IconButton';
import { Trash } from '../../styled';
import { showServiceModalDelete } from 'store/Services/actions';
import ModalWarning from 'Common/Components/ModalWarning';
import { showModalDelete } from 'store/Contextualization/AssetDetail/actions';
import { showServiceModalDelete as showGeneralModal } from 'store/ServicesGeneral/actions';

const DeleteServiceButton = ({ hasServices, servicesSelected, entity, showServiceDetail }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [showWarning, setShowWarning] = useState(false);
  const readOnly = useSelector(selectReadOnly);
  const canUpdate = !readOnly && hasServices;
  const message = intl.formatMessage({ id: 'modalWarning.selectOneOrMore' }, { entity: 'services' });

  const showModal = () => {
    if (entity === 'servicesAsset') dispatch(showModalDelete('services'));
    else if (entity === 'servicesGeneral') dispatch(showGeneralModal());
    else dispatch(showServiceModalDelete());
  };

  const handleClick = () => {
    if (canUpdate) {
      if (servicesSelected.length > 0) showModal();
      else setShowWarning(true);
    }
  };

  const onClose = () => setShowWarning(false);
  return (
    <>
      <IconButton icon={ <Trash /> } title="Delete" onClick={ handleClick } disabled={ readOnly || !hasServices || showServiceDetail } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title="Delete" description={ message } /> }
    </>
  );
};

export default DeleteServiceButton;
