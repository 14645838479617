export const GENERAL_MANAGE_GET_REQUEST = 'GENERAL_MANAGE_GET_REQUEST';
export const GENERAL_MANAGE_GET_SUCCESS = 'GENERAL_MANAGE_GET_SUCCESS';
export const GENERAL_MANAGE_FAIL = 'GENERAL_MANAGE_FAIL';
export const GENERAL_MANAGE_CLEAR_ERROR = 'GENERAL_MANAGE_CLEAR_ERROR';
export const GENERAL_MANAGE_SELECTED = 'GENERAL_MANAGE_SELECTED';
export const GENERAL_MANAGE_NEW_PIVOT = 'GENERAL_MANAGE_NEW_PIVOT';
export const GENERAL_MANAGE_UNSELECTED = 'GENERAL_MANAGE_UNSELECTED';
export const GENERAL_MANAGE_SELECT_ALL = 'GENERAL_MANAGE_SELECT_ALL';
export const GENERAL_MANAGE_UNSELECT_ALL = 'GENERAL_MANAGE_UNSELECT_ALL';
export const GENERAL_MANAGE_SHOW_CONTEXT_MENU = 'GENERAL_MANAGE_SHOW_CONTEXT_MENU';
export const GENERAL_MANAGE_GET_GROUPED_DATA_START = 'GENERAL_MANAGE_GET_GROUPED_DATA_START';
export const GENERAL_MANAGE_GET_GROUPED_DATA_SUCCESS = 'GENERAL_MANAGE_GET_GROUPED_DATA_SUCCESS';
export const GENERAL_MANAGE_SEARCH_VULNS_FAIL = 'GENERAL_MANAGE_SEARCH_VULNS_FAIL';
export const GENERAL_MANAGE_SET_BULK_UPDATE_FIELD = 'GENERAL_MANAGE_SET_BULK_UPDATE_FIELD';
export const GENERAL_MANAGE_SET_BULK_UPDATE_VALUE = 'GENERAL_MANAGE_SET_BULK_UPDATE_VALUE';
export const GENERAL_MANAGE_ADD_BULK_UPDATE_VALUE = 'GENERAL_MANAGE_ADD_BULK_UPDATE_VALUE';
export const GENERAL_MANAGE_REMOVE_BULK_UPDATE_VALUE = 'GENERAL_MANAGE_REMOVE_BULK_UPDATE_VALUE';
export const GENERAL_MANAGE_BULK_UPDATE_FINISHED = 'GENERAL_MANAGE_BULK_UPDATE_FINISHED';
export const GENERAL_MANAGE_SHOW_MODAL_DELETE_CONFIRMATION = 'GENERAL_MANAGE_SHOW_MODAL_DELETE_CONFIRMATION';
export const GENERAL_MANAGE_HIDE_MODAL_DELETE_CONFIRMATION = 'GENERAL_MANAGE_HIDE_MODAL_DELETE_CONFIRMATION';
export const GENERAL_MANAGE_DELETE = 'GENERAL_MANAGE_DELETE';
export const GENERAL_MANAGE_HIDE_MODAL_DELETE_CONFIRMATION_SUCCEED = 'GENERAL_MANAGE_HIDE_MODAL_DELETE_CONFIRMATION_SUCCEED';
export const GENERAL_MANAGE_RESET_STATE = 'GENERAL_MANAGE_RESET_STATE';
export const GENERAL_MANAGE_CONFIRMATION_CHANGE_START = 'GENERAL_MANAGE_CONFIRMATION_CHANGE_START';
export const GENERAL_MANAGE_SET_BULK_UPDATE_SUCCESS = 'GENERAL_MANAGE_SET_BULK_UPDATE_SUCCESS';
export const GENERAL_MANAGE_CONFIRMATION_CHANGE_SUCCESS = 'GENERAL_MANAGE_CONFIRMATION_CHANGE_SUCCESS';
export const GENERAL_MANAGE_CONFIRMATION_CHANGE_FAIL = 'GENERAL_MANAGE_CONFIRMATION_CHANGE_FAIL';
export const GENERAL_MANAGE_REFRESH_VULNS = 'GENERAL_MANAGE_REFRESH_VULNS';
export const GENERAL_MANAGE_SELECT_TOTAL_VULNS = 'GENERAL_MANAGE_SELECT_TOTAL_VULNS';
export const GENERAL_MANAGE_UNSELECT_TOTAL_VULNS = 'GENERAL_MANAGE_UNSELECT_TOTAL_VULNS';
export const GENERAL_MANAGE_UPDATE_PREVIEW_SUCCESS = 'GENERAL_MANAGE_UPDATE_PREVIEW_SUCCESS';
export const GENERAL_MANAGE_UPDATE_PREVIEW_FAILURE = 'GENERAL_MANAGE_UPDATE_PREVIEW_FAILURE';
export const GENERAL_MANAGE_SET_VULN_DETAIL_TAB = 'GENERAL_MANAGE_SET_VULN_DETAIL_TAB';
export const GENERAL_MANAGE_UPDATE_VULNS_LIST = 'GENERAL_MANAGE_UPDATE_VULNS_LIST';
export const GENERAL_MANAGE_SHOW_VULN_DETAIL = 'GENERAL_MANAGE_SHOW_VULN_DETAIL';
export const GENERAL_MANAGE_HIDE_VULN_DETAIL = 'GENERAL_MANAGE_HIDE_VULN_DETAIL';
export const GENERAL_MANAGE_SHOW_FILTERS = 'GENERAL_MANAGE_SHOW_FILTERS';
export const GENERAL_MANAGE_CREATE_UPDATE_SUCCESS = 'GENERAL_MANAGE_CREATE_UPDATE_SUCCESS';
export const ADD_EVIDENCE_FAILURE = 'ADD_EVIDENCE_FAILURE';
export const GENERAL_MANAGE_SET_VULNS_FILTER_ERROR = 'GENERAL_MANAGE_SET_VULNS_FILTER_ERROR';
