import React, { useEffect, useState } from 'react';
import head from 'lodash/head';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { selectVulnDetail } from 'store/Manage/selectors';
import DateFormatter from 'Common/Components/DateFormatter';
import { FormattedMessage } from 'react-intl';
import {
  Wrapper, FieldContainer, FieldName, FieldValue, MoveIcon, ModalContent, Subtitle, Title, Flex, Footer
} from './styled';
import { ReactComponent as AssetIcon } from 'Images/assets.svg';
import { closeModal, openModal } from 'store/modals/actions';
import { MODAL_MOVE_VULN_HOST } from 'store/modals/modals';
import ModalWrapper from 'Common/Components/ModalWrapper';
import { Dropdown } from 'Common/Components/Inputs';
import { dropdownBlueStyle } from 'Common/Components/Inputs/styled';
import { Accept, Cancel } from 'Common/Components/Button/styled';
import { updateHostParent } from 'store/Manage/actions';
import { selectServicesAndHostsContextMove } from 'store/ManageEditCreate/selectors';
import { getHosts, getServices } from 'Screens/Contextualization/ManageEditCreate/actions/Actions';
import { unSelectAll, hideManageDetail } from 'store/Contextualization/AssetDetail/actions';
import { selectCurrentHost } from 'store/Host/selectors';

// eslint-disable-next-line import/prefer-default-export
export const HostData = () => {
  const dispatch = useDispatch();
  const handleMoveIconClick = () => {
    dispatch(openModal(MODAL_MOVE_VULN_HOST));
  };

  return (
    <Wrapper>
      <Target />
      <Hostname />
      <Date />
      <MoveIcon title="Move to a different Asset" onClick={ handleMoveIconClick } />
    </Wrapper>
  );
};

export const MoveVulnModal = () => {
  const dispatch = useDispatch();
  const vulnDetail = useSelector(selectVulnDetail);
  const [assets, setAssets] = useState([]);
  const servicesAndHost = useSelector(selectServicesAndHostsContextMove);
  const isWeb = get(vulnDetail, 'type', '') === 'VulnerabilityWeb';
  const currentHost = useSelector(selectCurrentHost);
  const assetId = get(currentHost, 'id', 0);
  const currentVuln = {
    value: {
      hostId: assetId,
      name: vulnDetail.service?.name || vulnDetail.target,
      type: vulnDetail.type,
      parentId: vulnDetail.parent
    },
    label: vulnDetail.service ? `(${vulnDetail.service.name}) - ${vulnDetail.target}` : vulnDetail.target
  };
  const [selectedAsset, setSelectedAsset] = useState(currentVuln);

  const parseLabel = (item) => {
    if (item.type && item.type === 'Host') {
      return item.name;
    }
    if (item.type && item.type === 'Service') {
      return `(${item.name}) - ${item.hostname}`;
    }

    return item.name;
  };

  const parseHostAndServices = (data) => {
    const filteredData = data.filter(item => {
      if (isWeb) {
        return item.type === 'Service';
      } else if (!isWeb && (vulnDetail?.parent_type === 'Service')) {
        return item;
      } else {
        return item.type === 'Host';
      }
    });
    const result = filteredData.map(item => {
      const parentId = item.id;
      const hostId = isWeb ? item.host_id : item.id;
      return { value: { parentId, type: item.type, name: item.name, hostId }, label: parseLabel(item) };
    });
    return result;
  };

  useEffect(() => {
    setAssets(parseHostAndServices(servicesAndHost));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [servicesAndHost]);

  useEffect(() => {
    dispatch(getServices(vulnDetail.workspace_name));
    dispatch(getHosts(false, vulnDetail.workspace_name));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAssetSelect = (asset) => {
    setSelectedAsset(asset);
  };

  const handleAccept = () => {
    const newParent = selectedAsset.value.parentId;
    const newParentType = selectedAsset.value.type;
    dispatch(updateHostParent(vulnDetail._id, newParent, newParentType, vulnDetail.workspace_name));
    if (selectedAsset.value.hostId !== assetId) {
      dispatch(unSelectAll('vulns'));
      dispatch(hideManageDetail());
    }
    dispatch(closeModal(MODAL_MOVE_VULN_HOST));
  };

  const handleClose = () => {
    dispatch(closeModal(MODAL_MOVE_VULN_HOST));
  };

  return (
    <ModalWrapper>
      <ModalContent>
        <Flex>
          <AssetIcon />
          <Title>Move Vulnerability</Title>
        </Flex>
        <Subtitle>Select the Asset where you want to move this vulnerability to</Subtitle>
        <Dropdown
          style={ dropdownBlueStyle }
          options={ assets }
          defaultValue={ currentVuln }
          onChange={ handleAssetSelect }
        />
        <Footer>
            <Accept
              onClick={ handleAccept }
              disabled={ selectedAsset.value.parentId === currentVuln.value.parentId }
              children="Move"
            />
            <Cancel onClick={ handleClose } children="Close" />
        </Footer>
      </ModalContent>
    </ModalWrapper>
  );
};

const Target = () => {
  const vuln = useSelector(selectVulnDetail);
  const target = get(vuln, 'target', '');

  return (
    <FieldContainer>
      <FieldName>
        <FormattedMessage id="manage.detail.tab.general.hostData.host" />
      </FieldName>
      <FieldValue>{target}</FieldValue>
    </FieldContainer>
  );
};

const Hostname = () => {
  const vuln = useSelector(selectVulnDetail);
  const hostnames = get(vuln, 'hostnames', []).filter((h) => !isEmpty(h));
  const hostname = head(hostnames);

  if (!hostname) return null;
  return (
    <FieldContainer>
      <FieldName>
        <FormattedMessage id="manage.detail.tab.general.hostData.hostname" />
      </FieldName>
      <FieldValue>{hostname}</FieldValue>
    </FieldContainer>
  );
};

const Date = () => {
  const vuln = useSelector(selectVulnDetail);
  const createTime = get(vuln, 'metadata.create_time', '');

  return (
    <FieldContainer>
      <FieldName>
        <FormattedMessage id="manage.detail.tab.general.hostData.date" />
      </FieldName>
      <FieldValue><DateFormatter date={ createTime } /></FieldValue>
    </FieldContainer>
  );
};
