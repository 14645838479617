import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import head from 'lodash/head';
import { useIntl } from 'react-intl';
import IconButton from 'Common/Components/IconButton';
import EvidenceIcon from './styled';
import ModalWarning from 'Common/Components/ModalWarning';
import { selectReadOnly } from 'store/Faraday/selectors';

const AddNewEvidence = ({ hasVulns, vulnsSelected, openEvidenceTab }) => {
  const [showWarning, setShowWarning] = useState(false);
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'manage.cm.addNewEvidence' });
  const selectedVulnID = get(head(vulnsSelected), '_id', null);
  const readOnly = useSelector(selectReadOnly);
  const message = intl.formatMessage({ id: 'modalWarning.selectOnlyOne' }, { entity: 'vulnerability' });
  const canUpdate = !readOnly && hasVulns;

  const openEvidence = () => {
    if (canUpdate) {
      if (vulnsSelected.length === 1) openEvidenceTab(selectedVulnID);
      else setShowWarning(true);
    }
  };

  const onClose = () => setShowWarning(false);

  return (
    <>
      <IconButton icon={ <EvidenceIcon /> } title={ title } onClick={ openEvidence } disabled={ !canUpdate } />
      { showWarning && <ModalWarning onCloseCallback={ onClose } title={ title } description={ message } /> }
    </>
  );
};

export default AddNewEvidence;
