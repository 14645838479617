import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  clearGroupByAssets,
  setPageNumberAssets,
  setGroupByAssets,
  toggleModalEditCreate,
  showHostModalDelete
} from 'store/Host/actions';
import { selectShowHostDetail } from 'store/Contextualization/AssetDetail/selectors';
import {
  selectHostCount,
  selectHostsSelected,
  selectIsFetching,
  selectSelectAllHosts,
  selectShowDeleteConfirmation
} from 'store/Host/selectors';
import { selectFields } from 'store/Preferences/host/contextSelectors';
import { resetDefault, setFieldVisibility, setNewOrderColumns } from 'store/Preferences/host/contextActions';
import CustomActionBar from '../CustomActionBar';

const ActionBar = ({ setFF }) => {
  const dispatch = useDispatch();
  const hostsSelected = useSelector(selectHostsSelected);
  const hostsCount = useSelector(selectHostCount);
  const isHostDetailVisible = useSelector(selectShowHostDetail);
  const showDeleteConfirmation = useSelector(selectShowDeleteConfirmation);
  const isFetching = useSelector(selectIsFetching);
  const fields = useSelector(selectFields);
  const selectAll = useSelector(selectSelectAllHosts);

  return (
    <CustomActionBar
      setFF={ setFF }
      hostsSelected={ hostsSelected }
      hostsCount={ hostsCount }
      showHostDetail={ isHostDetailVisible }
      entity="assets"
      setPage={ (page) => dispatch(setPageNumberAssets(page)) }
      toggleCreateModal={ (value) => dispatch(toggleModalEditCreate(value)) }
      showHostModalDelete={ () => dispatch(showHostModalDelete()) }
      setGroupBy={ (groupBy) => dispatch(setGroupByAssets(groupBy)) }
      clearGroupBy={ () => dispatch(clearGroupByAssets()) }
      resetDefault={ () => dispatch(resetDefault()) }
      setFieldVisibility={ (fieldObj, value) => dispatch(setFieldVisibility(fieldObj, value)) }
      setNewOrderColumns={ (columns) => dispatch(setNewOrderColumns(columns)) }
      isFetching={ isFetching }
      showDeleteConfirmation={ showDeleteConfirmation }
      fields={ fields }
      selectAll={ selectAll }
    />
  );
};

export default ActionBar;
