import React from 'react';
import { selectIsLoggedIn } from 'store/Sesion/selectors';
import { selectLocation } from 'store/Router/selectors';
import { useSelector } from 'react-redux';
import Searcher from 'Common/Components/Contextualization/Searcher';
import WorkspaceSelector from './WorkspaceMenuSelector';
import User from './User/userContainer';
import ProcessingQueueButton from './ProcessingQueueButton';
import { Header, Separator, Wrapper } from './styled';
import VulnsCounter from './VulnsCounter';
import AssetSelector from './AssetSelector';
import Title from './Title';
import HomeButton from './HomeButton';
import HostModalCreate from '../HostModalCreate';

const NavMenuContext = () => {
  const loggedIn = useSelector((state) => selectIsLoggedIn(state));
  const location = useSelector((state) => selectLocation(state));
  const isInsideWs = location.pathname.startsWith('/feed') ||
   location.pathname.startsWith('/host/') ||
   location.pathname.startsWith('/manage') ||
   location.pathname.startsWith('/service/');
  const agents = location.pathname === '/automation/agents';
  const isOutsideWs = location.pathname.startsWith('/workspaces') ||
  location.pathname.startsWith('/vulnerabilities') ||
  location.pathname.startsWith('/hosts');

  return loggedIn && (
    <Header agents={ agents }>
      <Title />
      { isInsideWs &&
        <>
          <HomeButton />
          <Separator />
          <WorkspaceSelector />
        </>
      }
      <AssetSelector />
      <VulnsCounter />
      <Searcher />
      <HostModalCreate />
      <Wrapper isOutsideWs={ isOutsideWs }>
        <ProcessingQueueButton />
        <User />
      </Wrapper>
    </Header>
  );
};

export default NavMenuContext;
