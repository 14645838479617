import StandardTextAreaField from 'Common/Components/StandardTextAreaField';
import StandardTextField from 'Common/Components/StandardTextField';
import styled from 'styled-components';
import colors from 'Styles/colors';
import { ReactComponent as Icon } from 'Images/services.svg';

export const GearIcon = styled(Icon)`
  margin-right: -19px;
  cursor: default;
`;
GearIcon.displayName = 'GearIcon';

export const Wrapper = styled.div`
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 18px 43px 50px 0 rgb(167 167 167 / 50%);
  border-top: 10px solid #53a9ff;
  border-radius: 2px 2px 0 0;
  padding: 36px 40px;
  overflow: hidden !important;
  max-height: 85%;
`;
Wrapper.displayName = 'Wrapper';

export const Title = styled.div`
  font-size: 21.5px;
  font-weight: 600;
  color: ${colors.blueCerulean};
`;
Title.displayName = 'Title';

export const WrapperRow = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 13px;
  align-items: center;
  & > :not(:last-child) {
    margin-right: 21px;
  }
  input, textarea {
    box-shadow: inset 0 -1px 0 0 ${colors.grey30};
    background-color: ${colors.white};
  }
  span {
    font-weight: 400;
  }
`;
WrapperRow.displayName = 'WrapperRow';

export const Name = styled(StandardTextField)`
  width: 50%;
`;
Name.displayName = 'Name';

export const Port = styled(StandardTextField)`
  width: 125px;
  input {
    padding-bottom: 1px;
    padding-right: 0px;
  }
`;
Port.displayName = 'Port';

export const Protocol = styled(StandardTextField)`
  width: 150px;
`;
Protocol.displayName = 'Protocol';

export const Version = styled(StandardTextField)`
`;
Version.displayName = 'Version';

export const Description = styled(StandardTextAreaField)`
`;
Description.displayName = 'Description';

export const WrapperButtons = styled.div`
  display: flex;
  margin-left: auto;
  & > :first-child {
    margin-right: auto;
  }
  & > :last-child {
    margin-left: 8px;
  }
`;
WrapperButtons.displayName = 'WrapperButtons';

export const Error = styled.div`
  font-size: 12.5px;
  font-weight: normal;
  color: ${colors.redPink};
  position: absolute;
  bottom: 21px;
`;
Error.displayName = 'Error';

export const WrapperField = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  & > :first-child {
    margin-right: 10px;
  }
  & > :last-child {
    margin: 2px 0 0 0;
    line-height: 1.2;
  }
`;
WrapperField.displayName = 'WrapperField';

export const Label = styled.div`
  text-align: left;
  font-size: 12.5px;
  font-stretch: normal;
  line-height: 2.08;
  margin-bottom: 1px;
  color: ${colors.grey17};
  `;
Label.displayName = 'Label';

export const Column = styled.div`
  width: 47%;
`;
Column.displayName = 'Column';

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0 20px 0;
`;
Field.displayName = 'Field';

export const BottomRow = styled(WrapperRow)`
  align-items: flex-start;
  & > :first-child {
    margin-right: 27px;
  }
`;
BottomRow.displayName = 'BottomRow';

export const AssetsField = styled.div`
  margin: 10px 0 20px 0;
  width: 250px;
`;
AssetsField.displayName = 'AssetsField';
