import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { selectReadOnly } from 'store/Faraday/selectors';
import AddButton from 'Common/Components/Contextualization/AddButton';
import { selectEmptyActiveWorkspaces } from 'store/Workspace/selectors';
import { openModal } from 'store/modals/actions';
import { MODAL_CANT_CREATE } from 'store/modals/modals';

const CreateActions = ({ setVisibilityCreateModal, entity }) => {
  const intl = useIntl();
  const addText = intl.formatMessage({ id: 'host.create' });
  const readOnly = useSelector(selectReadOnly);
  const canUpdate = !readOnly;
  const noWorkspaces = useSelector(selectEmptyActiveWorkspaces);
  const dispatch = useDispatch();

  const handleShow = () => {
    if (entity === 'assetsGeneral' && noWorkspaces) {
      dispatch(openModal(MODAL_CANT_CREATE));
    } else if (canUpdate) {
      setVisibilityCreateModal(true);
    }
  };

  return (
    <AddButton
      onClick={ handleShow }
      title={ addText }
      text={ addText }
      disabled={ readOnly }
    />
  );
};

export default CreateActions;
