import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectReadOnly, selectTotalHostsCount } from 'store/Faraday/selectors';
import ManualCreationModal from 'Screens/Contextualization/ManageEditCreate/components/ManualCreationModal';
import { Wrapper, ButtonWrapper } from './styled';
import AddButton from 'Common/Components/Contextualization/AddButton';
import { selectEmptyActiveWorkspaces } from 'store/Workspace/selectors';
import { openModal } from 'store/modals/actions';
import { MODAL_CANT_CREATE } from 'store/modals/modals';

const CreateActions = () => {
  const [showModalCreation, setShowModalCreation] = useState(false);
  const readOnly = useSelector(selectReadOnly);
  const assetsCountAllWorkspaces = useSelector(selectTotalHostsCount);
  const noAssetsFromAllWorkspaces = assetsCountAllWorkspaces === 0;
  const noWorkspaces = useSelector(selectEmptyActiveWorkspaces);
  const canUpdate = !readOnly;
  const dispatch = useDispatch();

  const handleShow = () => {
    if (canUpdate) {
      if (noAssetsFromAllWorkspaces || noWorkspaces) dispatch(openModal(MODAL_CANT_CREATE));
      else setShowModalCreation(true);
    }
  };

  const handleClose = () => setShowModalCreation(false);

  return (
    <Wrapper>
      <ButtonWrapper data-tour="Add Vulnerability in Asset">
        <AddButton onClick={ handleShow } title="Add Vulnerability" text="Add Vulnerability" disabled={ !canUpdate } />
      </ButtonWrapper>
      { showModalCreation && <ManualCreationModal handleClose={ handleClose } entity="vulnsGeneral" /> }
    </Wrapper>
  );
};

export default CreateActions;
