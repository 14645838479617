import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Title from './Title';
import { Wrapper, CloseIconWrapper, CloseIcon, Owned } from './styled';
import CopyLink from 'Common/Components/CopyLink';
import CopyApiLink from 'Common/Components/CopyApiLink';
import { selectCurrentWorkspace, selectReadOnly } from 'store/Faraday/selectors';

const Header = ({ data, owned, serviceId, redirectToService, updateService }) => {
  const dispatch = useDispatch();
  const workspaceSelected = useSelector(selectCurrentWorkspace);
  const intl = useIntl();
  const ownedText = owned ? intl.formatMessage({ id: 'host.hostDetail.owned' }) : intl.formatMessage({ id: 'host.hostDetail.notOwned' });
  const servicePath = `ws/${workspaceSelected}/services/${serviceId}`;
  const readOnly = useSelector(selectReadOnly);
  const canUpdate = !readOnly;

  const updateOwned = () => {
    if (canUpdate) updateService(serviceId, { owned: !owned });
  };

  return (
    <Wrapper>
      <Title serviceDetail={ data } serviceId={ serviceId } updateService={ updateService } />
      <Owned owned={ owned } onClick={ updateOwned } disabled={ readOnly }>{ ownedText }</Owned>
      <CopyLink />
      <CopyApiLink path={ servicePath } />
      <CloseIconWrapper onClick={ () => dispatch(redirectToService()) }>
        <CloseIcon />
      </CloseIconWrapper>
    </Wrapper>
  );
};

export default Header;
