/* eslint-disable react/destructuring-assignment */
import React from 'react';
import NameCell from './NameCell';
import GenericCell from './GenericCell';
import { CheckColum } from './CheckColumn/CheckColum';
import { CheckHeader } from './CheckHeader/CheckHeader';

export default function getColumns (fields, isFetching) {
  const columns = [
    {
      Header: () => <CheckHeader />,
      accessor: 'selected',
      id: 'Selected',
      maxWidth: 38,
      minWidth: 38,
      resizable: false,
      sortable: false,
      show: true,
      Cell: (rowInfo) => <CheckColum service={ rowInfo.original } showSkeleton={ isFetching } />,
      custom_order: -1
    },
    {
      accessor: 'name',
      id: 'name',
      Header: () => <div>SERVICES</div>,
      Cell: (rowInfo) => <NameCell rowInfo={ rowInfo.original } showSkeleton={ isFetching } />,
      resizable: true,
      sortable: false,
      show: fields.name?.visible,
      maxWidth: fields.name?.maxWidth,
      minWidth: fields.name?.minWidth,
      custom_order: fields.name?.order,
      getProps: () => ({ className: `name min-wd-${fields.name?.defaultWidth}` })
    },
    {
      accessor: 'version',
      id: 'version',
      Header: () => <div>VERSION</div>,
      Cell: (rowInfo) => <GenericCell rowInfo={ rowInfo.original } attrib="version" showSkeleton={ isFetching } />,
      resizable: true,
      sortable: false,
      show: fields.version?.visible,
      maxWidth: fields.version?.maxWidth,
      minWidth: fields.version?.minWidth,
      custom_order: fields.version?.order,
      getProps: () => ({ className: `version min-wd-${fields.version?.defaultWidth}` })
    },
    {
      accessor: 'port',
      id: 'port',
      Header: () => <div>PORT</div>,
      Cell: (rowInfo) => <GenericCell rowInfo={ rowInfo.original } attrib="port" showSkeleton={ isFetching } />,
      resizable: true,
      sortable: false,
      show: fields.port?.visible,
      maxWidth: fields.port?.maxWidth,
      minWidth: fields.port?.minWidth,
      custom_order: fields.port?.order,
      getProps: () => ({ className: `port min-wd-${fields.port?.defaultWidth}` })
    },
    {
      accessor: 'protocol',
      id: 'protocol',
      Header: () => <div>PROTOCOL</div>,
      Cell: (rowInfo) => <GenericCell rowInfo={ rowInfo.original } capitalize="uppercase" attrib="protocol" showSkeleton={ isFetching } />,
      resizable: true,
      sortable: false,
      show: fields.protocol?.visible,
      maxWidth: fields.protocol?.maxWidth,
      minWidth: fields.protocol?.minWidth,
      custom_order: fields.protocol?.order,
      getProps: () => ({ className: `protocol min-wd-${fields.protocol?.defaultWidth}` })
    },
    {
      accessor: 'status',
      id: 'status',
      Header: () => <div>STATUS</div>,
      Cell: (rowInfo) => <GenericCell rowInfo={ rowInfo.original } attrib="status" showSkeleton={ isFetching } />,
      resizable: true,
      sortable: false,
      show: fields.status?.visible,
      maxWidth: fields.status?.maxWidth,
      minWidth: fields.status?.minWidth,
      custom_order: fields.status?.order,
      getProps: () => ({ className: `status min-wd-${fields.status?.defaultWidth}` })
    },
    {
      accessor: 'vulns',
      id: 'vulns',
      Header: () => <div>VULNS</div>,
      Cell: (rowInfo) => <GenericCell rowInfo={ rowInfo.original } attrib="vulns" showSkeleton={ isFetching } />,
      resizable: true,
      sortable: false,
      show: fields.vulns?.visible,
      maxWidth: fields.vulns?.maxWidth,
      minWidth: fields.vulns?.minWidth,
      custom_order: fields.vulns?.order,
      getProps: () => ({ className: `vulns min-wd-${fields.vulns?.defaultWidth}` })
    },
    {
      accessor: 'credentials',
      id: 'credentials',
      Header: () => <div>CRED</div>,
      Cell: (rowInfo) => <GenericCell rowInfo={ rowInfo.original } attrib="credentials" showSkeleton={ isFetching } />,
      resizable: true,
      sortable: false,
      show: fields.credentials?.visible,
      maxWidth: fields.credentials?.maxWidth,
      minWidth: fields.credentials?.minWidth,
      custom_order: fields.credentials?.order,
      getProps: () => ({ className: `credentials min-wd-${fields.credentials?.defaultWidth}` })
    }, {
      accessor: 'parent_name',
      id: 'parent_name',
      Header: () => <div>ASSET</div>,
      Cell: (rowInfo) => <GenericCell rowInfo={ rowInfo.original } attrib="parent_name" showSkeleton={ isFetching } />,
      resizable: true,
      sortable: false,
      show: fields.asset?.visible,
      maxWidth: fields.asset?.maxWidth,
      minWidth: fields.asset?.minWidth,
      custom_order: fields.asset?.order,
      getProps: () => ({ className: `parent_name min-wd-${fields.asset?.defaultWidth}` })
    }
  ];
  return columns.filter((row, index) => row.show === true || index === 0).sort((a, b) => (a.custom_order < b.custom_order ? -1 : 1));
}
