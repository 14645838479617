import React from 'react';
import { useSelector } from 'react-redux';
import { selectIsAdvancedMode } from 'store/Filters/selectors';
import FilterError from './components/FilterError';
import { SearchBarWrapper, Wrapper } from './styled';
import BasicFilter from './components/BasicFilter';
import AdvancedFilter from './components/AdvancedFilter';
import AdvancedModeSelector from './components/AdvancedModeSelector';
import SaveFilter from './components/SaveFilter';
import { selectPathname } from 'store/Router/selectors';

const FilterBar = () => {
  const isAdvancedMode = useSelector(selectIsAdvancedMode);
  return isAdvancedMode ? <AdvancedFilter /> : <BasicFilter />;
};

const SearchBar = () => {
  const pathname = useSelector((state) => selectPathname(state));
  const searchBarStyle = pathname.includes('/hosts') || pathname.includes('/vulnerabilities') || pathname.includes('/workspaces');

  return (
    <Wrapper searchBarStyle={ searchBarStyle }>
      <SearchBarWrapper>
        <FilterBar />
        <FilterError />
        <AdvancedModeSelector />
      </SearchBarWrapper>
      <SaveFilter />
    </Wrapper>
  );
};

export default SearchBar;
