import get from 'lodash/get';
import find from 'lodash/find';
import api from 'services/api';
import axios, { CancelToken } from 'axios';
import { CURRENT_API_VERSION } from 'services/api/connector';
import { ADD_COMMAND, REMOVE_COMMAND, SET_ERROR_IN_COMMAND } from 'Common/Components/FileUploadContext/Context/actions';
import toBase64 from 'Common/Functions/ToBase64';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';
import { selectFileIndex, selectTargetsField, selectWorkspacesField } from 'store/ManageEditCreate/selectors';
import { getVulnsCountInWs } from 'Screens/Contextualization/Faraday/actions/Actions';
import { refreshDashboard } from 'Screens/Contextualization/Dashboard/actions/Actions';

export const ADD_REFERENCE_MANAGE_CREATE_UPDATE = 'ADD_REFERENCE_MANAGE_CREATE_UPDATE';
export const REMOVE_REFERENCE_MANAGE_CREATE_UPDATE = 'REMOVE_REFERENCE_MANAGE_CREATE_UPDATE';
export const ADD_CVE_MANAGE_CREATE_UPDATE = 'ADD_CVE_MANAGE_CREATE_UPDATE';
export const REMOVE_CVE_MANAGE_CREATE_UPDATE = 'REMOVE_CVE_MANAGE_CREATE_UPDATE';
export const ADD_POLICY_MANAGE_CREATE_UPDATE = 'ADD_POLICY_MANAGE_CREATE_UPDATE';
export const REMOVE_POLICY_MANAGE_CREATE_UPDATE = 'REMOVE_POLICY_MANAGE_CREATE_UPDATE';
export const SELECT_CHECK_BOX_MANAGE_CREATE_UPDATE = 'SELECT_CHECK_BOX_MANAGE_CREATE_UPDATE';
export const SET_FIELD_NAME_MANAGE_CREATE_UPDATE = 'SET_FIELD_NAME_MANAGE_CREATE_UPDATE';
export const REMOVE_FILE_MANAGE_CREATE_UPDATE = 'REMOVE_FILE_MANAGE_CREATE_UPDATE';
export const SET_CUSTOM_FIELD_MANAGE_CREATE_UPDATE = 'SET_CUSTOM_FIELD_MANAGE_CREATE_UPDATE';
export const MANAGE_CREATE_UPDATE_START = 'MANAGE_CREATE_UPDATE_START';
export const CONTEXT_MANAGE_CREATE_UPDATE_SUCCESS = 'CONTEXT_MANAGE_CREATE_UPDATE_SUCCESS';
export const MANAGE_CREATE_UPDATE_FAIL = 'MANAGE_CREATE_UPDATE_FAIL';
export const RESET_STATE_MANAGE_CREATE_UPDATE = 'RESET_STATE_MANAGE_CREATE_UPDATE';
export const SET_MODE_MANAGE_CREATE_UPDATE = 'SET_MODE_MANAGE_CREATE_UPDATE';
export const SET_REDIRECT_MANAGE_CREATE_UPDATE = 'SET_REDIRECT_MANAGE_CREATE_UPDATE';
export const GET_TEMPLATES_MANAGE_EDIT_CREATE = 'GET_TEMPLATES_MANAGE_EDIT_CREATE';
export const GET_TEMPLATES_MANAGE_EDIT_SUCCESS = 'GET_TEMPLATES_MANAGE_EDIT_SUCCESS';
export const GET_TEMPLATES_MANAGE_EDIT_FAIL = 'GET_TEMPLATES_MANAGE_EDIT_FAIL';
export const SET_VALUE_FIELD_PREVIEW_MANAGE_EDIT_CREATE = 'SET_VALUE_FIELD_PREVIEW_MANAGE_EDIT_CREATE';
export const GET_HOSTS_REQUEST = 'GET_HOSTS_REQUEST';
export const GET_HOSTS_SUCCESS = 'GET_HOSTS_SUCCESS';
export const GET_HOSTS_FAIL = 'GET_HOSTS_FAIL';
export const GET_SERVICES_REQUEST = 'GET_SERVICES_REQUEST';
export const GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS';
export const GET_SERVICES_FAIL = 'GET_SERVICES_FAIL';
export const CREATE_SERVICES_REQUEST = 'CREATE_SERVICES_REQUEST';
export const CREATE_SERVICES_SUCCESS = 'CREATE_SERVICES_SUCCESS';
export const CREATE_SERVICES_FAIL = 'CREATE_SERVICES_FAIL';
export const HOST_CREATE_SUCCESS = 'HOST_CREATE_SUCCESS';
export const HOST_CREATE_REQUEST = 'HOST_CREATE_REQUEST';
export const HOST_CREATE_FAIL = 'HOST_CREATE_FAIL';
export const ADD_TARGET = 'ADD_TARGET';
export const REMOVE_ASSET = 'REMOVE_ASSET';

export const UPLOAD_REPORT_MANAGE_UPDATE_PERCENT = 'UPLOAD_REPORT_MANAGE_UPDATE_PERCENT';
export const UPLOAD_REPORT_ERROR_MANAGE = 'UPLOAD_REPORT_ERROR_MANAGE';
export const CANCEL_REQUEST_MANAGE = 'CANCEL_REQUEST_MANAGE';
export const SET_FILES_TO_UPLOAD_MANAGE = 'SET_FILES_TO_UPLOAD_MANAGE';
export const SET_UPLOAD_STATE_MANAGE = 'SET_UPLOAD_STATE_MANAGE';
export const UPDATE_FILES_TO_UPLOAD = 'UPDATE_FILES_TO_UPLOAD';
export const RESET_FILE_STATE_MANAGE = 'RESET_FILE_STATE_MANAGE';
export const SET_FILE_INDEX_MANAGE = 'SET_FILE_INDEX_MANAGE';
export const SET_NAME_INTO_FILE_ERROR_LIST = 'SET_NAME_INTO_FILE_ERROR_LIST';
export const SET_ATTACHMENT_MANAGE_CREATE_UPDATE = 'SET_ATTACHMENT_MANAGE_CREATE_UPDATE';
export const DELETE_ATTACHMENT_MANAGE_CREATE_UPDATE = 'DELETE_ATTACHMENT_MANAGE_CREATE_UPDATE';
export const RESET_ERROR_VALUE = 'RESET_ERROR_VALUE';
export const SET_IGNORE_INFO = 'SET_IGNORE_INFO';
export const SET_RESOLVE_HOST = 'SET_RESOLVE_HOST';
export const CLEAN_WEB_VULNS_FIELDS = 'CLEAN_WEB_VULNS_FIELDS';
export const SET_ERROR_CREATE_VULN = 'SET_ERROR_CREATE_VULN';
export const GENERAL_MANAGE_ADD_WORKSPACE = 'GENERAL_MANAGE_ADD_WORKSPACE';
export const GENERAL_MANAGE_REMOVE_WORKSPACE = 'GENERAL_MANAGE_REMOVE_WORKSPACE';
export const CONTEXT_GET_HOSTS_SUCCESS = 'CONTEXT_GET_HOSTS_SUCCESS';
export const REMOVE_GROUP_ASSETS = 'REMOVE_GROUP_ASSETS';
export const REMOVE_GROUP_SERVICES = 'REMOVE_GROUP_SERVICES';

export function addReference (reference) {
  return (dispatch) => {
    dispatch({
      type: ADD_REFERENCE_MANAGE_CREATE_UPDATE, reference
    });
  };
}

export function removeReference (index) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_REFERENCE_MANAGE_CREATE_UPDATE, index
    });
  };
}

export function addCVE (CVE) {
  return (dispatch) => {
    dispatch({
      type: ADD_CVE_MANAGE_CREATE_UPDATE, CVE
    });
  };
}

export function removeCVE (CVE) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_CVE_MANAGE_CREATE_UPDATE, CVE
    });
  };
}

export function addPolicy (policy) {
  return (dispatch) => {
    dispatch({
      type: ADD_POLICY_MANAGE_CREATE_UPDATE, policy
    });
  };
}

export function removePolicy (index) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_POLICY_MANAGE_CREATE_UPDATE, index
    });
  };
}

export function selectCheckbox (checkName) {
  return (dispatch) => {
    dispatch({
      type: SELECT_CHECK_BOX_MANAGE_CREATE_UPDATE, checkName
    });
  };
}

export function setField (fieldName, value) {
  return (dispatch) => {
    dispatch({
      type: SET_FIELD_NAME_MANAGE_CREATE_UPDATE, fieldName, value
    });
  };
}

// Summary: Set new value for custom field in state
export function setCustomField (key, value) {
  return (dispatch) => {
    dispatch({
      type: SET_CUSTOM_FIELD_MANAGE_CREATE_UPDATE, key, value
    });
  };
}

export function setAttachments (attachments) {
  return async (dispatch) => {
    attachments.forEach(async (attachment) => {
      const uploadImage = await toBase64(attachment);
      dispatch({
        type: SET_ATTACHMENT_MANAGE_CREATE_UPDATE,
        name: attachment.name,
        content_type: attachment.type,
        data: uploadImage.split(';base64,')[1]
      });
    });
  };
}

export function deleteAttachment (key) {
  return (dispatch) => {
    dispatch({
      type: DELETE_ATTACHMENT_MANAGE_CREATE_UPDATE, key
    });
  };
}

export function createVulnerability () {
  return async (dispatch, getState) => {
    dispatch({ type: MANAGE_CREATE_UPDATE_START });

    const state = getState().manageEditCreate;
    const selectedWs = selectCurrentWorkspace(getState());
    const newRefs = state.references.map((ref) => ({ name: ref, type: 'other' }));
    const isWebVuln = state.isWebVuln;
    const servicesTargets = state.targets.filter((target) => target.type === 'Service');
    const servicesParents = servicesTargets.map((service) => service.parent);
    const allTargets = state.targets;
    const hostsTargets = allTargets.filter((target) => target.type === 'Host');
    const removeServiceParent = hostsTargets.filter((target) => !servicesParents.includes(target.id));
    const filteredTargets = servicesTargets.length > 0 ? servicesTargets.concat(removeServiceParent) : state.targets;
    const vulnsTargets = isWebVuln ? servicesTargets : filteredTargets;

    try {
      const promises = vulnsTargets.map((target) => {
        const vuln = {
          _id: state.id,
          confirmed: state.confirmed,
          custom_fields: state.customFields,
          data: state.data,
          desc: state.description,
          description: state.description,
          easeofresolution: state.easeOfResolution,
          external_id: state.externalId,
          impact: {
            accountability: get(state, 'accountability', false),
            availability: get(state, 'availability', false),
            confidentiality: get(state, 'confidentiality', false),
            integrity: get(state, 'integrity', false)
          },
          method: state.method,
          name: state.name,
          pname: state.paramName,
          params: state.params,
          parent: target.id,
          parent_type: target.type,
          path: state.path,
          policyviolations: state.policies,
          query: state.query,
          refs: newRefs,
          request: state.request,
          resolution: state.resolution,
          response: state.response,
          severity: state.severity,
          status_code: state.status_code ? state.status_code : 0,
          type: state.isWebVuln ? 'VulnerabilityWeb' : 'Vulnerability',
          website: state.website,
          _attachments: state._attachments, // eslint-disable-line no-underscore-dangle
          cve: state.CVE
        };
        return api.manage.createVuln(selectedWs, vuln);
      });
      const res = await Promise.all(promises);

      // dispatch(newGetVulns());
      dispatch(getVulnsCountInWs());
      if (window.location.href.includes('/feed')) dispatch(refreshDashboard());
      dispatch({ type: CONTEXT_MANAGE_CREATE_UPDATE_SUCCESS, vulns: res });

      dispatch({ type: RESET_STATE_MANAGE_CREATE_UPDATE });
    } catch (error) {
      return dispatch({ type: MANAGE_CREATE_UPDATE_FAIL, errorMessage: error.message });
    }
  };
}

export function resetState () {
  return (dispatch) => {
    dispatch({ type: RESET_STATE_MANAGE_CREATE_UPDATE });
  };
}

export function getTemplates () {
  return async (dispatch) => {
    dispatch({ type: GET_TEMPLATES_MANAGE_EDIT_CREATE });
    try {
      const response = await api.manage.getTemplates();
      return dispatch({ type: GET_TEMPLATES_MANAGE_EDIT_SUCCESS, data: response });
    } catch (e) {
      return dispatch({ type: GET_TEMPLATES_MANAGE_EDIT_FAIL });
    }
  };
}

export function setValueFieldPreview (vuln, field, value) {
  return (dispatch) => {
    dispatch({
      type: SET_VALUE_FIELD_PREVIEW_MANAGE_EDIT_CREATE, field, value
    });
  };
}

export function getHosts (stats = false, ws = null) {
  return async (dispatch, getState) => {
    dispatch({ type: GET_HOSTS_REQUEST });
    if (!ws) ws = selectCurrentWorkspace(getState());
    try {
      const response = await api.host.getHosts(ws, stats);
      const data = response.rows.map((item) => item.value);
      return dispatch({ type: CONTEXT_GET_HOSTS_SUCCESS, data });
    } catch (error) {
      return dispatch({ type: GET_HOSTS_FAIL, error: error || 'An error has occurred.' });
    }
  };
}

export function getServices (ws = null) {
  return async (dispatch, getState) => {
    dispatch({ type: GET_SERVICES_REQUEST });
    if (!ws) ws = selectCurrentWorkspace(getState());
    try {
      const response = await api.service.getAllServices(ws);
      return dispatch({ type: GET_SERVICES_SUCCESS, services: response.services });
    } catch (error) {
      return dispatch({ type: GET_SERVICES_FAIL, error: error || 'An error has occurred.' });
    }
  };
}

export function createHost (ip) {
  return async (dispatch, getState) => {
    const selectedWs = selectCurrentWorkspace(getState());
    dispatch({ type: HOST_CREATE_REQUEST });
    try {
      const host = { ip, owned: false, description: '' };
      const response = await api.host.createHost(selectedWs, host);

      dispatch({ type: HOST_CREATE_SUCCESS, data: response });
      return dispatch({ type: ADD_TARGET, data: response });
    } catch (error) {
      return dispatch({ type: HOST_CREATE_FAIL, error });
    }
  };
}

export function createService (data) {
  return async (dispatch, getState) => {
    dispatch({ type: CREATE_SERVICES_REQUEST });
    const serviceData = {
      description: '',
      metadata: {},
      name: data.name,
      owned: false,
      owner: '',
      parent: data.parent,
      ports: [data.port],
      protocol: data.protocol,
      status: 'open',
      type: 'Service',
      version: ''
    };

    try {
      const ws = selectCurrentWorkspace(getState());
      const response = await api.service.createService(ws, serviceData);
      dispatch({ type: CREATE_SERVICES_SUCCESS, service: response });
    } catch (e) {
      dispatch({ type: CREATE_SERVICES_FAIL, error: e.message });
    }
  };
}

export function addAsset (data) {
  return (dispatch) => {
    dispatch({ type: ADD_TARGET, data });
  };
}

export function removeAsset (data) {
  return (dispatch) => {
    dispatch({ type: REMOVE_ASSET, data });
  };
}

export function setFilesCount (files) {
  return (dispatch) => {
    dispatch({ type: SET_FILES_TO_UPLOAD_MANAGE, files });
  };
}

export function setFileIndex (index) {
  return (dispatch) => {
    dispatch({ type: SET_FILE_INDEX_MANAGE, index });
  };
}

export function setUploadState (state) {
  return (dispatch) => {
    dispatch({ type: SET_UPLOAD_STATE_MANAGE, state });
  };
}

export function resetFileUploadState () {
  return (dispatch) => {
    dispatch({ type: RESET_FILE_STATE_MANAGE });
  };
}

export function setIgnoreInfo (value) {
  return (dispatch) => {
    dispatch({ type: SET_IGNORE_INFO, value });
  };
}

export function setResolveHost (value) {
  return (dispatch) => {
    dispatch({ type: SET_RESOLVE_HOST, value });
  };
}

export function uploadFile (params) {
  return async (dispatch, getState) => {
    const fileName = get(find(params, ['name', 'file']), 'value.name', '');
    const state = getState();
    const fileIndex = selectFileIndex(state);
    const setUploadState = (index) => {
      if (index < 0) {
        dispatch({ type: SET_UPLOAD_STATE_MANAGE, state: 'FINISHED' });
        // dispatch(getWorkspaces());
      } else {
        dispatch({ type: SET_UPLOAD_STATE_MANAGE, state: 'PROCESSED' });
      }
    };

    try {
      dispatch({ type: SET_UPLOAD_STATE_MANAGE, state: 'PROCESSING' });
      const ws = selectCurrentWorkspace(getState());
      const response = await api.faraday.getSession();
      const cancelTokenSource = CancelToken.source();

      const fdParams = [...params, { name: 'csrf_token', value: response.csrf_token }];

      const fd = new FormData();
      fdParams.forEach((p) => fd.append(p.name, p.value));
      dispatch({
        type: ADD_COMMAND,
        command: {
          mockCommandId: `${fileName}_${fileIndex}`,
          name: fileName,
          fileType: 'file',
          error: false,
          finished: false
        }
      });
      const commandIdResponse = await axios.post(`/_api/${CURRENT_API_VERSION}/ws/${ws}/upload_report`, fd, {
        headers: { 'Content-Type': 'multipart/form-data' },
        cancelToken: cancelTokenSource.token,
        onUploadProgress: async (progressEvent) => {
          dispatch({ type: UPLOAD_REPORT_MANAGE_UPDATE_PERCENT, progressEvent });
          if (Math.round((100 * progressEvent.loaded) / progressEvent.total) === 100) {
            const index = getState().manageEditCreate.fileIndex - 1;
            dispatch({ type: SET_FILE_INDEX_MANAGE, index });
          }
        }
      });

      if (commandIdResponse.data.command_id) {
        dispatch({
          type: REMOVE_COMMAND,
          mockCommandId: `${fileName}_${fileIndex}`
        });

        dispatch({
          type: ADD_COMMAND,
          command: {
            command_id: commandIdResponse.data.command_id,
            name: fileName,
            fileType: 'file',
            error: false,
            finished: false
          }
        });

        const currentIndex = getState().manageEditCreate.fileIndex;
        setUploadState(currentIndex);
      }
    } catch (e) {
      if (!e.message || e.message !== 'cancel') {
        const currentIndex = getState().manageEditCreate.fileIndex;
        dispatch({
          type: SET_ERROR_IN_COMMAND,
          mockCommandId: `${fileName}_${fileIndex}`,
          name: fileName,
          errorMessage: e.response && e.response.data.message ? e.response.data.message : 'Couldn\'t upload your file. Please try again.'
        });
        setUploadState(currentIndex);
      } else {
        dispatch({ type: CANCEL_REQUEST_MANAGE });
      }
    }
  };
}

export function resetErrorValue () {
  return (dispatch) => {
    dispatch({ type: RESET_ERROR_VALUE });
  };
}

export function getServicesByHostIds () {
  return async (dispatch, getState) => {
    dispatch({ type: GET_SERVICES_REQUEST });
    const targets = selectTargetsField(getState());
    const workspaceSelected = selectCurrentWorkspace(getState());

    const hosts = targets.filter((target) => target.type === 'Host');
    if (hosts.length > 0) {
      const servicePromises = hosts.map((target) => api.hostDetail.fetchServices(workspaceSelected, target.id));
      let services = [];
      try {
        const res = await Promise.allSettled(servicePromises);
        res.forEach(({ value }) => {
          if (value.length > 0) services = [...services, ...value];
        });
        dispatch({ type: GET_SERVICES_SUCCESS, services });
      } catch (error) {
        return dispatch({ type: GET_SERVICES_FAIL, errorMessage: error.message });
      }
    } else {
      dispatch({ type: GET_SERVICES_SUCCESS, services: [] });
    }
  };
}

export function cleanWebVulnFields () {
  return (dispatch) => {
    dispatch({ type: CLEAN_WEB_VULNS_FIELDS });
  };
}

export const setCreateVulnError = (error, errorMessage, errorTitle) => (dispatch) => dispatch({ type: SET_ERROR_CREATE_VULN, error, errorMessage, errorTitle });

export const addWorkspace = (data) => (dispatch) => {
  return dispatch({ type: GENERAL_MANAGE_ADD_WORKSPACE, data });
};

export const removeWorkspace = (data) => (dispatch) => {
  return dispatch({ type: GENERAL_MANAGE_REMOVE_WORKSPACE, data });
};

export const getHostsByWorkspaces = () => async (dispatch, getState) => {
  dispatch({ type: GET_HOSTS_REQUEST });
  const workspaces = selectWorkspacesField(getState());
  let filters = [];

  if (workspaces.length > 1) {
    const wsFilters = workspaces.map((ws) => {
      return { name: 'workspace', op: 'has', val: { name: 'name', op: '==', val: ws.name } };
    });
    filters = [{ or: wsFilters }];
  } else if (workspaces.length === 1) {
    filters = [{ name: 'workspace', op: 'has', val: { name: 'name', op: '==', val: workspaces[0].name } }];
  }

  const queryParam = { filters };

  try {
    const response = await api.hostsGeneral.fetchHostsWithoutStats(queryParam);
    const data = response.rows.map((item) => item.value);
    dispatch({ type: CONTEXT_GET_HOSTS_SUCCESS, data });
  } catch (error) {
    dispatch({ type: GET_HOSTS_FAIL, error: error.message || 'Error fetching hosts data' });
  }
};

export function removeGroupAsset (hosts) {
  return (dispatch) => {
    dispatch({ type: REMOVE_GROUP_ASSETS, hosts });
  };
}

export function removeGroupService (services) {
  return (dispatch) => {
    dispatch({ type: REMOVE_GROUP_SERVICES, services });
  };
}

export function getServicesByHostOutsideWs () {
  return async (dispatch, getState) => {
    dispatch({ type: GET_SERVICES_REQUEST });
    const targets = selectTargetsField(getState());
    const workspaces = selectWorkspacesField(getState());
    const hosts = targets.filter((target) => target.value?.type === 'Host');
    if (hosts.length > 0) {
      const servicePromises = hosts.map((target) => api.hostsGeneral.fetchServicesOutsideWs(target.value.id));
      try {
        const res = await Promise.all(servicePromises);
        let allServices = [];
        res.forEach((items) => {
          allServices = [...allServices, ...items];
        });

        let parsedServices = [];
        if (workspaces.length > 0 && allServices.length > 0) {
          workspaces.forEach((ws, i) => {
            parsedServices = [...parsedServices, { groupName: ws.name, items: [] }];
            allServices.forEach((service) => {
              if (service.workspace_name === ws.name) {
                parsedServices[i] = { groupName: ws.name, items: [...parsedServices[i].items, service] };
              }
            });
          });
        }
        dispatch({ type: GET_SERVICES_SUCCESS, services: parsedServices });
      } catch (error) {
        return dispatch({ type: GET_SERVICES_FAIL, errorMessage: error.message });
      }
    } else {
      dispatch({ type: GET_SERVICES_SUCCESS, services: [] });
    }
  };
}
