import React from 'react';
import CreateActions from 'Screens/Contextualization/Services/components/CustomActionBar/components/CreateActions';
import { Wrapper, LeftGroup, RightGroup, Separator, Group } from 'Common/Components/Contextualization/CustomActionBar/styled';
import DeleteServiceButton from '../CustomActionBar/components/DeleteServiceButton';
import EditServiceButton from '../CustomActionBar/components/EditServiceButton';
import Pagination from 'Common/Components/Pagination';
import DeleteConfirmationModal from '../CustomActionBar/components/DeleteConfirmationModal';
import RefreshButton from '../CustomActionBar/components/RefreshButton';
import CopyLink from '../CustomActionBar/components/CopyLink';
import CopyApiLink from '../CustomActionBar/components/CopyApiLink';
import AddRemoveColumnsButton from '../CustomActionBar/components/AddRemoveColumnsButton';
import TagButton from 'Screens/Contextualization/Manage/components/CustomActionBar/components/TagButton';

const CustomActionBar = ({
  setFF, servicesSelected, servicesCount, page, rowsPerPage, setPage, entity,
  setFieldVisibility, setNewOrderColumns, resetDefault, isFetching,
  showDeleteConfirmation, fields, hideServiceModalDelete, showServiceDetail,
  selectAll
}) => {
  const onPrev = () => setPage(page - 1);
  const onNext = () => setPage(page + 1);
  const hasServices = servicesCount > 0;

  return (
    <Wrapper>
      { showDeleteConfirmation
        ? <DeleteConfirmationModal
            showDeleteConfirmation={ showDeleteConfirmation }
            entity={ entity }
            isFetching={ isFetching }
            servicesSelected={ servicesSelected }
            hideServiceModalDelete={ hideServiceModalDelete }
            selectAll={ selectAll }
            servicesCount={ servicesCount }
        />
        : null
      }
      <LeftGroup>
        <CreateActions entity={ entity } />
        <Separator />
        <Group>
          <EditServiceButton hasServices={ hasServices } servicesSelected={ servicesSelected } entity={ entity } />
          <TagButton setFF={ setFF } hasItems={ hasServices } />
          <CopyLink hasServices={ hasServices } servicesSelected={ servicesSelected } />
          <CopyApiLink hasServices={ hasServices } servicesSelected={ servicesSelected } entity={ entity } />
          <DeleteServiceButton hasServices={ hasServices } servicesSelected={ servicesSelected } entity={ entity } showServiceDetail={ showServiceDetail } />
        </Group>
      </LeftGroup>

      <RightGroup>
        { hasServices &&
          <>
            <Pagination offset={ page } limit={ rowsPerPage } total={ servicesCount } onPrev={ onPrev } onNext={ onNext } />
            <Separator />
          </>
        }
        <Group>
          <RefreshButton entity={ entity } />
          <AddRemoveColumnsButton
            setFieldVisibility={ setFieldVisibility }
            setNewOrderColumns={ setNewOrderColumns }
            resetDefault={ resetDefault }
            fields={ fields }
          />
        </Group>
      </RightGroup>
    </Wrapper>
  );
};

export default CustomActionBar;
