import React from 'react';
import ModalConfirmation from 'Common/Components/ModalConfirmation';

const DeleteConfirmationModal = ({
  showDeleteConfirmation, selectAll, isFetching, vulnsSelected,
  deleteVulns, hideModalDelete, vulnsCount
}) => {
  const count = selectAll ? vulnsCount : vulnsSelected.length;

  return (
    <ModalConfirmation
      show={ showDeleteConfirmation }
      handleSubmit={ deleteVulns }
      handleClose={ hideModalDelete }
      entity="vulnerability"
      count={ count }
      loading={ isFetching }
    />
  );
};

export default DeleteConfirmationModal;
