import React from 'react';
import { ReactComponent as PlusIcon } from 'Images/icon-action-bar-plus.svg';
import { useDispatch, useSelector } from 'react-redux';
import { newJob } from 'store/Jobs/actions';
import { Wrapper, Title, Button } from './styled';
import { selectLimitReached } from 'store/Jobs/selectors';
import { openModal } from 'store/modals/actions';
import { MODAL_UPGRADE_LICENSE } from 'store/modals/modals';

const Header = () => {
  const dispatch = useDispatch();
  const limitReached = useSelector(selectLimitReached);

  const onClick = () => {
    if (limitReached) dispatch(openModal(MODAL_UPGRADE_LICENSE));
    else dispatch(newJob());
  };

  return (
    <Wrapper>
      <Title>Jobs</Title>
      <Button title="Create" onClick={ onClick }>
        <PlusIcon />
        Create New Job
      </Button>
    </Wrapper>
  );
};
export default Header;
