import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import { selectCurrentHost } from 'store/Host/selectors';
import {
  getAssetServices, clearError, resetServiceDetail,
  redirectToService, loadServiceDetail, updateService,
  hideServiceDetail, resetState, setCurrentHostInServices
} from 'store/Contextualization/AssetDetail/actions';
import {
  selectError, selectErrorMessage, selectShowDetail,
  selectDetail, selectServiceId, selectServiceOwned,
  selectServiceDescription
} from 'store/Contextualization/AssetDetail/selectors';
import ErrorDialog from 'Common/Components/ErrorDialog';
import ActionBar from './ActionBar';
import ServicesTable from './ServicesTable';
import { closeModal } from 'store/modals/actions';
import ReduxModal from 'Common/Components/ReduxModal';
import { MODAL_CREATE_SERVICE, MODAL_UPGRADE_LICENSE_ACTION_BAR } from 'store/modals/modals';
import CreateService from 'Screens/Contextualization/Services/components/CreateService';
import Wrapper from './styled';
import ServiceDetail from 'Screens/Contextualization/Services/components/ServiceDetail';
import UpgradeLicense from 'Common/Components/UpgradeLicense';
import { getData } from 'store/Host/actions';

const HostServices = ({ params }) => {
  const dispatch = useDispatch();
  const currentAsset = useSelector(selectCurrentHost);
  const assetId = get(currentAsset, '_id', 0);
  const error = useSelector((state) => selectError('services', state));
  const errorMessage = useSelector((state) => selectErrorMessage('services', state));
  const showDetail = useSelector((state) => selectShowDetail('services', state));
  const detailData = useSelector((state) => selectDetail('services', state));
  const serviceId = useSelector(selectServiceId);
  const owned = useSelector(selectServiceOwned);
  const description = useSelector(selectServiceDescription);
  const [FFImage, setFFImage] = useState(null);
  const [FFDescription, setFFDescription] = useState('');

  const setFF = (image, description) => {
    setFFImage(image);
    setFFDescription(description);
  };

  const handleParamId = useCallback(() => {
    const id = get(params, 'serviceId', 0);
    if (parseInt(id, 10) > 0) dispatch(loadServiceDetail(id));
    else dispatch(hideServiceDetail());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  useEffect(() => {
    if (assetId) dispatch(getAssetServices(assetId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetId]);

  useEffect(() => {
    handleParamId();
  }, [handleParamId]);

  useEffect(() => {
    const id = get(params, 'id', 0);
    if ((!assetId) && id) {
      dispatch(getData());
      dispatch(setCurrentHostInServices(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => () => {
    dispatch(resetState('services'));
  }, [dispatch]);

  return (
    <Wrapper>
      <ActionBar setFF={ setFF } />
      <ServicesTable />
      { showDetail &&
        <ServiceDetail
          data={ detailData }
          resetServiceDetail={ resetServiceDetail }
          serviceId={ serviceId }
          owned={ owned }
          updateService={ (id, obj) => dispatch(updateService(id, obj)) }
          redirectToService={ redirectToService }
          loadServiceDetail={ loadServiceDetail }
          description={ description }
        />
      }
      <ReduxModal id={ MODAL_CREATE_SERVICE }>
        <CreateService onClose={ () => dispatch(closeModal(MODAL_CREATE_SERVICE)) } entity="servicesAsset" />
      </ReduxModal>
      { error ? <ErrorDialog message={ errorMessage } resetError={ () => dispatch(clearError('services')) } /> : null }
      <ReduxModal id={ MODAL_UPGRADE_LICENSE_ACTION_BAR }>
        <UpgradeLicense id={ MODAL_UPGRADE_LICENSE_ACTION_BAR } image={ FFImage } descriptionIntlId={ FFDescription } />
      </ReduxModal>
    </Wrapper>
  );
};

export default HostServices;
