import React, { createRef } from 'react';
import { useDispatch } from 'react-redux';
import CustomReactTable from 'Common/Components/CustomReactTable/styled';
import handleLeftClick from 'Common/Components/CustomReactTable/leftClickHelper';
import colors from 'Styles/colors';
import Wrapper from './styled';

const Table = ({
  selectRowAction, // custom action que permite seleccionar una fila, es custom para que se pueda usar desde varios lugares donde exista una tabla.
  rowsPerPage,
  page,
  isFetching,
  data,
  rowsSelected,
  lastRowSelected,
  lastIndexForRange,
  lastSelected,
  columns
}) => {
  const dispatch = useDispatch();
  const table = createRef(null);
  const mockData = [...Array(rowsPerPage).keys()];

  const selectRow = (selectionInfo) => {
    dispatch(selectRowAction(selectionInfo));
  };

  const handleClick = (e, rowInfo) => {
    const selected = rowsSelected.filter((x) => x.id === rowInfo.original.id).length > 0;
    const leftClickResponse = handleLeftClick(e, rowInfo, '', selected, data, rowsSelected, lastRowSelected, lastIndexForRange, lastSelected);
    if (leftClickResponse) selectRow(leftClickResponse);
  };

  const getTrProps = (state, rowInfo) => {
    const selected = rowsSelected.filter((x) => x.id === rowInfo.original.id).length > 0;

    return {
      onClick: (e) => {
        handleClick(e, rowInfo);
        return true;
      },
      onContextMenu: () => {
        if (!selected) selectRow({ newRowsSelected: [rowInfo.original] });
        return false;
      },
      style: {
        background: selected ? colors.blue8 : 'transparent'
      }
    };
  };

  return (
    <Wrapper ref={ table }>
      <CustomReactTable
        data={ isFetching ? mockData : data }
        columns={ columns }
        minRows={ 0 }
        page={ page }
        defaultPageSize={ rowsPerPage }
        manual
        getTrProps={ getTrProps }
        showPagination={ false }
      />
    </Wrapper>
  );
};

export default Table;
