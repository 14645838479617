import get from 'lodash/get';
import defaultFields from 'store/Host/fields';

export const selectFields = (state) => {
  let fields = get(state, 'preferences.host_table', defaultFields);
  const excludedFields = ['workspace_name'];

  fields = Object.keys(fields).reduce((filteredFields, field) => {
    if (!excludedFields.includes(field)) {
      filteredFields[field] = fields[field];
    }
    return filteredFields;
  }, {});

  return { ...fields };
};

export const selectHostList = (state) => get(state, 'host.hostList', []);
