import fetchApi from 'services/api/connector';

export const fetchServices = (queryParam) => fetchApi(`services/filter?q=${escape(JSON.stringify(queryParam))}`);

export const fetchServiceById = (id) => fetchApi(`services/${id}`);

export const deleteService = (ids) => fetchApi('services', { method: 'DELETE', data: { ids } });

export const createService = (ws, data) => fetchApi(`ws/${ws}/services`, { method: 'POST', data });

export const deleteAllServices = (queryParam) => fetchApi(`services?q=${encodeURI(JSON.stringify(queryParam))}`, { method: 'DELETE', version: 'v3' });
