import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { redirectToHost } from 'store/Contextualization/AssetDetail/actions';
import { selectAssetDetailId, selectHostDetailOwned, selectHostWorkspace } from 'store/Contextualization/AssetDetail/selectors';
import Title from './Title';
import CopyLink from './CopyLink';
import CopyApiLink from './CopyApiLink';
import { Wrapper, CloseIconWrapper, CloseIcon, Owned } from './styled';
import { updateHost } from 'store/Host/actions';
import { selectReadOnly } from 'store/Faraday/selectors';
import { redirectToHostsGeneral, updateHostGeneral } from 'store/HostsGeneral/actions';

const Header = ({ assetsGeneral }) => {
  const dispatch = useDispatch();
  const owned = useSelector(selectHostDetailOwned);
  const hostId = useSelector(selectAssetDetailId);
  const intl = useIntl();
  const ownedText = owned ? intl.formatMessage({ id: 'host.hostDetail.owned' }) : intl.formatMessage({ id: 'host.hostDetail.notOwned' });
  const readOnly = useSelector(selectReadOnly);
  const canUpdate = !readOnly;
  const workspace = useSelector(selectHostWorkspace);

  const updateOwned = () => {
    if (canUpdate) assetsGeneral ? dispatch(updateHostGeneral(hostId, { owned: !owned }, workspace)) : dispatch(updateHost(hostId, { owned: !owned }));
  };

  const closeDetail = () => assetsGeneral ? dispatch(redirectToHostsGeneral()) : dispatch(redirectToHost());

  return (
    <Wrapper>
      <Title assetsGeneral={ assetsGeneral } />
      <Owned owned={ owned } onClick={ updateOwned } disabled={ readOnly }>{ ownedText }</Owned>
      <CopyLink />
      <CopyApiLink assetsGeneral={ assetsGeneral } />
      <CloseIconWrapper onClick={ closeDetail }>
        <CloseIcon />
      </CloseIconWrapper>
    </Wrapper>
  );
};

export default Header;
