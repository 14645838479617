/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  addAsset, getServicesByHostIds, removeAsset,
  setField, cleanWebVulnFields, setCreateVulnError,
  getServicesByHostOutsideWs,
  removeGroupService
} from 'Screens/Contextualization/ManageEditCreate/actions/Actions';
import {
  selectDataField,
  selectRequestField,
  selectResponseField,
  selectWebsiteField,
  selectMethodField,
  selectQueryField,
  selectParamNameField,
  selectParamsField,
  selectStatusCodeField,
  selectIsWebVuln,
  selectPathField,
  selectAllServices,
  selectTargetsServices,
  selectTargetsHosts,
  selectTargetsHostsOutsideWs,
  selectTargetsServicesOutsideWs
} from 'store/ManageEditCreate/selectors';
import { methodOptions } from 'Screens/Constants';
import MarkdownInput from '../MarkdownInput';
import StringField from 'Common/Components/StringField';
import StandardDropdown from 'Common/Components/StandarDropdown';
import { Wrapper, Row, Field, MethodTitle, Column, Gap, Toggle, ToggleTitle } from './styled';
import InputSearch from '../General/InputSearch';
import { selectCurrentHost } from 'store/Host/selectors';
import { useIntl } from 'react-intl';
import { selectPathname } from 'store/Router/selectors';
import { Box } from '../General/styled';
import InputFilterGrouped from 'Common/Components/Contextualization/InputFilterGrouped';
import isEmpty from 'lodash/isEmpty';
import colors from 'Styles/colors';

const TechnicalDetails = ({ assets }) => {
  const data = useSelector(selectDataField);
  const isWebVuln = useSelector(selectIsWebVuln);
  const request = useSelector(selectRequestField);
  const response = useSelector(selectResponseField);
  const website = useSelector(selectWebsiteField);
  const method = useSelector(selectMethodField);
  const query = useSelector(selectQueryField);
  const paramName = useSelector(selectParamNameField);
  const params = useSelector(selectParamsField);
  const statusCode = useSelector(selectStatusCodeField);
  const path = useSelector(selectPathField);
  const dispatch = useDispatch();
  const servicesOutsideWs = useSelector(selectTargetsServicesOutsideWs);
  const services = useSelector(selectAllServices);
  const addedServices = useSelector(selectTargetsServices);
  const addedHosts = useSelector(selectTargetsHosts);
  const currentAsset = useSelector(selectCurrentHost);
  const createFromAsset = Object.keys(currentAsset).length > 0;
  const pathname = useSelector(selectPathname);
  const isOutsideWs = pathname.startsWith('/vulnerabilities');
  const addedHostsOutsideWs = useSelector((state) => selectTargetsHostsOutsideWs(state, isOutsideWs));
  const canCreateWebVuln = (createFromAsset && services.length > 0) || (assets.length > 0 && services.length > 0);
  const disabled = !canCreateWebVuln;
  const intl = useIntl();
  const servicesErrorTitle = intl.formatMessage({ id: 'modal.createVuln.missingAssets.title' });
  const servicesErrorMessage = intl.formatMessage({ id: 'modal.createVuln.missingAssets.message' });
  const webVulnErrorTitle = intl.formatMessage({ id: 'modal.createVuln.missingServices.title' });
  const webVulnErrorMessage = intl.formatMessage({ id: 'modal.createVuln.missingServices.message' });
  const webVulnErrorMessageInAsset = intl.formatMessage({ id: 'modal.createVulnInsideAsset.missingServices.webVuln.message' });
  const servicesErrorMessageInAsset = intl.formatMessage({ id: 'modal.createVulnInsideAsset.missingServices.message' });

  const saveField = (field, value) => dispatch(setField(field, value));

  useEffect(() => {
    if (isOutsideWs) {
      dispatch(getServicesByHostOutsideWs());
      if (disabled) {
        saveField('isWebVuln', false);
        dispatch(cleanWebVulnFields());
      }
      if (servicesOutsideWs.length > 0) {
        const assetsIds = assets.map((asset) => asset.value.id);
        servicesOutsideWs.forEach(service => {
          if (!assetsIds.includes(service.parent)) dispatch(removeAsset(service));
        });
      }
    } else if (!createFromAsset) {
      dispatch(getServicesByHostIds());
      if (disabled) {
        saveField('isWebVuln', false);
        dispatch(cleanWebVulnFields());
      }
      if (addedServices.length > 0) {
        const assetsIds = assets.map((asset) => asset.id);
        addedServices.forEach(service => {
          if (!assetsIds.includes(service.parent)) dispatch(removeAsset(service));
        });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assets]);

  useEffect(() => {
    if (isWebVuln && !isOutsideWs && ((addedServices.length === 0) || (addedHosts.length === 0))) {
      saveField('isWebVuln', false);
    } else if (isWebVuln && isOutsideWs && ((servicesOutsideWs.length === 0) || (addedHostsOutsideWs.length === 0))) {
      saveField('isWebVuln', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedServices, addedHosts, addedHostsOutsideWs]);

  const setWebVuln = (value) => {
    if (canCreateWebVuln) saveField('isWebVuln', value);
  };

  const addService = (value) => {
    const temp = addedServices;
    const found = temp.find((item) => item.id === value.id);
    if (!found) {
      dispatch(addAsset(value));
    }
  };

  const onRemoveService = (value) => dispatch(removeAsset(value));

  const toggleWebVuln = () => {
    const everyHostHasService = addedHosts.every((host) => addedServices.some((service) => host.id === service.parent));
    const everyHostHasServiceOutsideWs = addedHostsOutsideWs.every((host) => servicesOutsideWs.some((service) => host.value.id === service.value.parent));
    if (createFromAsset && addedServices.length === 0) {
      dispatch(setCreateVulnError(true, webVulnErrorMessageInAsset, webVulnErrorTitle));
    } else if (!createFromAsset && !isOutsideWs && (!everyHostHasService || addedServices.length === 0)) {
      dispatch(setCreateVulnError(true, webVulnErrorMessage, webVulnErrorTitle));
    } else if (isOutsideWs && (!everyHostHasServiceOutsideWs || servicesOutsideWs.length === 0)) {
      dispatch(setCreateVulnError(true, webVulnErrorMessage, webVulnErrorTitle));
    } else {
      setWebVuln(!isWebVuln);
    }
  };

  const setTargetOutsideWs = (element) => {
    if (element) {
      const temp = addedHostsOutsideWs;
      const found = temp.find((item) => (item.type === 'Service') && (item.id === element[element.length - 1].value.id));
      if (!found) {
        dispatch(addAsset(element[element.length - 1]));
      }
    }
  };

  const removeTargetOutsideWs = (value) => {
    dispatch(removeGroupService(value));
  };

  return (
    <Wrapper>
      <Row>
        <Column>
          { isOutsideWs
            ? <Box>
              <InputFilterGrouped
                options={ isEmpty(assets) ? [] : services }
                required
                title="Affected services"
                id="vulns-general-add-services"
                placeholder="Add a Service"
                cantSelect={ disabled }
                groupNameField="workspace_name"
                addItems={ setTargetOutsideWs }
                addedItems={ servicesOutsideWs }
                setItems={ (elements) => removeTargetOutsideWs(elements) }
                width="386px"
                bgColor={ colors.iceBlue }
                menuHeight="350px"
                setError={ () => dispatch(setCreateVulnError(true, servicesErrorMessage, servicesErrorTitle)) }
                showDetails
              />
              </Box>
            : <InputSearch
                onSelect={ addService }
                data={ services }
                placeholder="Add a Service"
                id="vuln-add-assets"
                title="Affected services"
                addedItems={ addedServices }
                removeItem={ onRemoveService }
                disabled={ disabled }
                errorTitle={ servicesErrorTitle }
                errorMessage={ (createFromAsset && services.length === 0) ? servicesErrorMessageInAsset : servicesErrorMessage }
            />
          }
        </Column>
        <Column>
          <MarkdownInput
            title="Data"
            value={ data }
            onBlur={ (value) => saveField('data', value) }
            minHeight={ 187 }
          />
        </Column>
      </Row>
      <Row>
        <Toggle checked={ isWebVuln } onChange={ toggleWebVuln } />
        <ToggleTitle>Web Vulnerability</ToggleTitle>
      </Row>
      <Row disabled={ !isWebVuln }>
        <Column>
          <Gap />
          <StringField
            width="405px"
            height="38px"
            title="Website"
            defaultValue={ website }
            onChange={ (value) => saveField('website', value) }
          />
          <Gap />
          <Field>
            <MethodTitle>Method</MethodTitle>
            <StandardDropdown
              field="Method"
              defaultValue={ method }
              options={ methodOptions }
              updateValue={ (field, value) => saveField('method', value) }
              placeholder="Select method"
              width="154px"
              normal
            />
          </Field>
          <Gap />
          <StringField
            width="405px"
            height="38px"
            title="Parameter Name"
            defaultValue={ paramName }
            onChange={ (value) => saveField('paramName', value) }
          />
          <Gap />
          <StringField
            width="405px"
            height="38px"
            title="Path"
            defaultValue={ path }
            onChange={ (value) => saveField('path', value) }
          />
          <Gap />
          <StringField
            width="405px"
            height="38px"
            title="Status code"
            defaultValue={ statusCode }
            onChange={ (value) => saveField('status_code', value) }
            type="number"
          />
          <Gap />
          <StringField
            width="405px"
            height="38px"
            title="Parameter"
            defaultValue={ params }
            onChange={ (value) => saveField('params', value) }
          />
        </Column>
        <Column>
          <Gap />
          <MarkdownInput
            title="Query string"
            value={ query }
            onBlur={ (value) => saveField('query', value) }
            plainText
            minHeight={ 187 }
          />
          <MarkdownInput
            title="Request"
            value={ request }
            onBlur={ (value) => saveField('request', value) }
            plainText
            minHeight={ 222 }
          />
          <MarkdownInput
            title="Response"
            value={ response }
            onBlur={ (value) => saveField('response', value) }
            plainText
            minHeight={ 222 }
          />
        </Column>
      </Row>
    </Wrapper>
  );
};

export default withRouter(TechnicalDetails);
