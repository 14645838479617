export default function matchField (field, entity) {
  switch (true) {
    case field === 'host__os':
      return 'Asset Os';

    case field === 'easeofresolution':
      return 'Ease of Resolution';

    case field === 'status_code':
      return 'Status Code';

    case field === 'pname':
      return 'Param Name';

    case field === 'policyviolations':
      return 'Policy Violations';

    case field === 'external_id':
      return 'External Id';

    case field === 'create_date' && (entity !== 'workspaces'):
      return 'Creation Date';

    case field === 'creation_time':
      return 'Creation Time';

    case field === 'last_modified':
      return 'Last Modified';

    case field === 'open_services':
      return 'Open Services';

    case field === 'severity_counts':
      return 'Important Vulnerabilities';

    case field === 'service__name':
      return 'Service';

    case field === 'update_date' && (entity !== 'workspaces'):
      return 'Updated';

    case field === 'risk':
      return 'Risk Score';

    case field === 'create_date' && (entity === 'workspaces'):
      return 'Creation Date';

    case field === 'update_date' && (entity === 'workspaces'):
      return 'Last Modified';

    case field === 'range_dates':
      return 'Start-end date';

    case field === 'name' && (entity === 'workspaces'):
      return 'Workspace';

    case field === 'workspace_name':
      return 'Workspace';

    default: {
      const fieldUpper = field.charAt(0).toUpperCase() + field.slice(1);
      return fieldUpper;
    }
  }
}
