import React from 'react';
import get from 'lodash/get';
import { selectCurrentWorkspace } from 'store/Faraday/selectors';

import { ReactComponent as Dashboard } from 'Images/activity-dashboard.svg';
import { ReactComponent as Assets } from 'Images/assets.svg';
import { ReactComponent as Automation } from 'Images/automation.svg';
import { ReactComponent as Vulnerabilities } from 'Images/vulnerabilities.svg';
import { ReactComponent as Planner } from 'Images/tasks_icon.svg';
import { ReactComponent as Reports } from 'Images/reports.svg';
import { ReactComponent as Analytics } from 'Images/analytics.svg';
import { ReactComponent as Users } from 'Images/icon_users.svg';

import FFAnalytics from 'Images/ff_analytics.png';
import FFPlanner from 'Images/ff_planner.png';
import FFReports from 'Images/ff_reporting.png';
import FFUsers from 'Images/ff_users.png';

export const selectIsOpen = (state) => get(state, 'sideBar.isOpen', false);
export const selectPagesData = (state) => {
  const ws = selectCurrentWorkspace(state);

  return [
    {
      name: 'Activity Dashboard',
      icon: <Dashboard />,
      subMenu: [
        {
          name: 'Activity Dashboard',
          url: `/feed/${ws}`,
          enabledByRole: true,
          enabledByFeature: true
        }
      ]
    },
    {
      name: 'Vulnerabilities',
      icon: <Vulnerabilities />,
      subMenu: [
        {
          name: 'Manage',
          url: `/manage/${ws}`,
          enabledByRole: true,
          enabledByFeature: true
        },
        {
          name: 'Vuln Templates (KB)',
          url: '/knowledge_base',
          enabledByRole: true,
          enabledByFeature: true
        }
      ]
    },
    {
      name: 'Assets',
      icon: <Assets />,
      subMenu: [
        {
          name: 'Assets',
          url: `/host/${ws}`,
          enabledByRole: true,
          enabledByFeature: true
        }
      ]
    },
    {
      name: 'Analytics',
      icon: <Analytics />,
      subMenu: [
        {
          name: 'Analytics',
          url: null,
          enabledByRole: true,
          enabledByFeature: false,
          FF: {
            image: FFAnalytics,
            description: 'upgradeLicense.sidebar.analytics.description'
          }
        }
      ]
    },
    {
      name: 'Planner',
      icon: <Planner />,
      subMenu: [
        {
          name: 'Planner',
          url: null,
          enabledByRole: true,
          enabledByFeature: false,
          FF: {
            image: FFPlanner,
            description: 'upgradeLicense.sidebar.planner.description'
          }
        }
      ]
    },
    {
      name: 'Reports',
      icon: <Reports />,
      subMenu: [
        {
          name: 'Reports',
          url: null,
          enabledByRole: true,
          enabledByFeature: false,
          FF: {
            image: FFReports,
            description: 'upgradeLicense.sidebar.reporting.description'
          }
        }
      ]
    },
    {
      name: 'Automation',
      icon: <Automation />,
      subMenu: [
        {
          name: 'Agents',
          url: '/automation/agents',
          enabledByRole: true,
          enabledByFeature: true
        },
        {
          name: 'Scheduler',
          url: '/automation/schedule',
          enabledByRole: true,
          enabledByFeature: true
        },
        {
          name: 'Pipelines',
          url: '/automation/pipelines',
          enabledByRole: true,
          enabledByFeature: true
        },
        {
          name: 'Jobs',
          url: '/automation/jobs',
          enabledByRole: true,
          enabledByFeature: true
        }
      ]
    },
    {
      name: 'Users',
      icon: <Users />,
      subMenu: [
        {
          name: 'Users',
          url: null,
          enabledByRole: true,
          enabledByFeature: false,
          FF: {
            image: FFUsers,
            description: 'upgradeLicense.sidebar.users.description'
          }
        }
      ]
    }
  ];
};
