import React from 'react';
import { useDispatch } from 'react-redux';
import IconButton from 'Common/Components/IconButton';
import { useIntl } from 'react-intl';
import DuplicateIcon from './styled';
import { openModal } from 'store/modals/actions';
import { MODAL_UPGRADE_LICENSE_ACTION_BAR } from 'store/modals/modals';
import FFDuplicates from 'Images/ff_duplicates.png';

const DuplicateButton = ({ setFF, vulnsSelected, showVulnDetail }) => {
  const dispatch = useDispatch();
  const multipleVulnsSelected = vulnsSelected.length > 1;
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'vuln.detail.duplicates.markAsDuplicates' });

  const onClick = () => {
    setFF(FFDuplicates, 'upgradeLicense.sidebar.duplicates.description');
    dispatch(openModal(MODAL_UPGRADE_LICENSE_ACTION_BAR));
  };

  if (!multipleVulnsSelected || showVulnDetail) return null;
  return <IconButton icon={ <DuplicateIcon /> } title={ title } onClick={ onClick } />;
};

export default DuplicateButton;
