// --- Aux functions to create keys
export const createNameKey = (label, value) => ({
  name: {
    label,
    value
  }
});
const searchByFilterKey = (name, autocomplete, operators) => ({
  name,
  autocomplete,
  operators
});
const groupByKey = (label, field) => ({
  label,
  value: [
    {
      field
    }
  ]
});
const createFilter = (name, op, val) => ({
  name,
  op,
  val
});
const createLabelName = (label, name) => ({
  label,
  name
});

const FILTER_OPERATORS = {
  eq: createNameKey('=', '=='),
  neq: createNameKey('!=', '!='),
  like: createNameKey('contains', 'like'),
  ilike: createNameKey('contains', 'ilike'),
  greaterOrEqual: createNameKey('>=', '>='),
  greater: createNameKey('>', '>'),
  lessOrEqual: createNameKey('<=', '<='),
  less: createNameKey('<', '<'),
  has: createNameKey('has', 'has'),
  any: createNameKey('any', 'any'),
  in: createNameKey('in', 'in'),
  notIn: createNameKey('not in', 'not_in'),
  isNull: createNameKey('is null', 'is_null'),
  isNotNull: createNameKey('is not null', 'is_not_null'),
  and: createNameKey('&', 'and'),
  or: createNameKey('||', 'or'),
  isOneOf: createNameKey('is one of', '=='),
  isNotOneOf: createNameKey('is not one of', 'eq'),
  notAny: createNameKey('notAny', 'not_any')
};

export const {
  eq, neq, ilike, greaterOrEqual, greater, lessOrEqual, less, has, any, and, or, isOneOf, isNotOneOf, notAny
} = FILTER_OPERATORS;

const commandIdFilter = (id) => ({
  name: 'creator_command_id',
  op: eq.name.value,
  val: id
});

const vulnerabilitiesBySeverity = (severityType) => ({
  name: 'severity',
  op: eq.name.value,
  val: severityType
});

const vulnerabilitiesByStatus = (statusType) => ({
  name: 'status',
  op: eq.name.value,
  val: statusType
});

const vulnerabilityById = (id) => ({
  name: 'id',
  op: eq.name.value,
  val: id
});

const vulnerabilitiesByType = (type) => ({
  name: 'type',
  op: eq.name.value,
  val: type
});

const assetsByIp = (ip) => ({
  name: 'ip',
  op: eq.name.value,
  val: ip
});

const assetsByServiceName = (serviceName) => ({
  name: 'services',
  op: 'any',
  val: {
    name: 'name',
    op: eq.name.value,
    val: serviceName
  }
});

// ------------------------------------

const SEARCH_BY_AUTOCOMPLETE_KEYS = {
  critical: createNameKey('Critical', 'critical'),
  high: createNameKey('High', 'high'),
  medium: createNameKey('Medium', 'medium'),
  low: createNameKey('Low', 'low'),
  informational: createNameKey('Informational', 'informational'),
  unclassified: createNameKey('Unclassified', 'unclassified'),
  true: createNameKey('True', 'true'),
  false: createNameKey('False', 'false'),
  trivial: createNameKey('Trivial', 'trivial'),
  simple: createNameKey('Simple', 'simple'),
  moderate: createNameKey('Moderate', 'moderate'),
  difficult: createNameKey('Difficult', 'difficult'),
  infeasible: createNameKey('Infeasible', 'infeasible'),
  vulnerability: createNameKey('Vulnerability', 'vulnerability'),
  vulnerability_web: createNameKey('Vulnerability Web', 'vulnerability_web'),
  open: createNameKey('Open', 'open'),
  closed: createNameKey('Closed', 'closed'),
  filtered: createNameKey('Filtered', 'filtered'),
  reOpened: createNameKey('Re-Opened', 're-opened'),
  riskAccepted: createNameKey('Risk Accepted', 'risk-accepted'),
  locked: createNameKey('Locked', 'true'),
  unlocked: createNameKey('Unlocked', 'false'),
  active: createNameKey('Active', 'true'),
  archived: createNameKey('Archived', 'false')
};

const SEVERITY_AUTOCOMPLETE = [
  SEARCH_BY_AUTOCOMPLETE_KEYS.critical,
  SEARCH_BY_AUTOCOMPLETE_KEYS.high,
  SEARCH_BY_AUTOCOMPLETE_KEYS.medium,
  SEARCH_BY_AUTOCOMPLETE_KEYS.low,
  SEARCH_BY_AUTOCOMPLETE_KEYS.informational,
  SEARCH_BY_AUTOCOMPLETE_KEYS.unclassified
];

const BOOL_AUTOCOMPLETE = [
  SEARCH_BY_AUTOCOMPLETE_KEYS.true,
  SEARCH_BY_AUTOCOMPLETE_KEYS.false
];

const EASE_OF_RESOLUTION_AUTOCOMPLETE = [
  SEARCH_BY_AUTOCOMPLETE_KEYS.trivial,
  SEARCH_BY_AUTOCOMPLETE_KEYS.simple,
  SEARCH_BY_AUTOCOMPLETE_KEYS.moderate,
  SEARCH_BY_AUTOCOMPLETE_KEYS.difficult,
  SEARCH_BY_AUTOCOMPLETE_KEYS.infeasible
];

const VULN_TYPE_AUTOCOMPLETE = [
  SEARCH_BY_AUTOCOMPLETE_KEYS.vulnerability,
  SEARCH_BY_AUTOCOMPLETE_KEYS.vulnerability_web
];

const STATUS_AUTOCOMPLETE = [
  SEARCH_BY_AUTOCOMPLETE_KEYS.open,
  SEARCH_BY_AUTOCOMPLETE_KEYS.closed,
  SEARCH_BY_AUTOCOMPLETE_KEYS.reOpened,
  SEARCH_BY_AUTOCOMPLETE_KEYS.riskAccepted
];

const SERVICES_STATUS_AUTOCOMPLETE = [
  SEARCH_BY_AUTOCOMPLETE_KEYS.open,
  SEARCH_BY_AUTOCOMPLETE_KEYS.closed,
  SEARCH_BY_AUTOCOMPLETE_KEYS.filtered
];

const WORKSPACES_PERMISSION_AUTOCOMPLETE = [
  SEARCH_BY_AUTOCOMPLETE_KEYS.locked,
  SEARCH_BY_AUTOCOMPLETE_KEYS.unlocked
];

export const CUSTOM_FIELDS_OPERATORS = {
  str: [eq, neq, ilike],
  int: [eq, neq, greaterOrEqual, greater, lessOrEqual, less],
  list: [any, notAny, ilike],
  choice: [eq, neq],
  date: [eq, lessOrEqual, greaterOrEqual, less, greater]
};

export const SEARCH_BY_FILTER_KEYS = {
  asset: searchByFilterKey({ label: 'Asset', value: 'target' }, [], [eq, neq, ilike]),
  assetOs: searchByFilterKey({ label: 'Asset OS', value: 'host__os' }, [], [eq, neq, ilike]),
  confirmed: searchByFilterKey({ label: 'Confirmed', value: 'confirmed' }, BOOL_AUTOCOMPLETE, [eq]),
  createDate: searchByFilterKey({ label: 'Creation Date', value: 'create_date' }, [], [eq, lessOrEqual, greaterOrEqual, less, greater]),
  creatorCommandId: searchByFilterKey({ label: 'Creator command ID', value: 'creator_command_id' }, [], [eq, neq]),
  customer: searchByFilterKey({ label: 'Customer', value: 'customer' }, [], [eq, neq, ilike]),
  cve_instances__name: searchByFilterKey({ label: 'Name', value: 'name' }, [], [isOneOf, isNotOneOf, ilike]),
  cve_instances: searchByFilterKey({ label: 'CVE', value: 'cve_instances__name' }, [], [any, notAny]),
  cvss2: searchByFilterKey({ label: 'CVSS2', value: 'cvss2_base_score' }, [], [eq, neq, greaterOrEqual, greater, lessOrEqual, less]),
  cvss3: searchByFilterKey({ label: 'CVSS3', value: 'cvss3_base_score' }, [], [eq, neq, greaterOrEqual, greater, lessOrEqual, less]),
  cwe__name: searchByFilterKey({ label: 'Name', value: 'name' }, [], [isOneOf, isNotOneOf, ilike]),
  cwe: searchByFilterKey({ label: 'CWE', value: 'cwe__name' }, [], [any, notAny]),
  data: searchByFilterKey({ label: 'Data', value: 'data' }, [], [eq, neq, ilike]),
  description: searchByFilterKey({ label: 'Description', value: 'description' }, [], [eq, neq, ilike]),
  easeOfResolution: searchByFilterKey({ label: 'Ease of Resolution', value: 'ease_of_resolution' }, EASE_OF_RESOLUTION_AUTOCOMPLETE, [eq, neq]),
  endDate: searchByFilterKey({ label: 'End Date', value: 'end_date' }, [], [eq, lessOrEqual, greaterOrEqual, less, greater]),
  externalId: searchByFilterKey({ label: 'External ID', value: 'external_id' }, [], [eq, neq, ilike]),
  hostnames__name: searchByFilterKey({ label: 'Name', value: 'name' }, [], [ilike]),
  hostNames: searchByFilterKey({ label: 'Hostnames', value: 'hostnames__name' }, [], [any]),
  id: searchByFilterKey({ label: 'ID', value: 'id' }, [], [eq, neq, greaterOrEqual, greater, lessOrEqual, less]),
  impactAccountability: searchByFilterKey({ label: 'Impact-Accountability', value: 'impact_accountability' }, BOOL_AUTOCOMPLETE, [eq]),
  impactAvailability: searchByFilterKey({ label: 'Impact-Availability', value: 'impact_availability' }, BOOL_AUTOCOMPLETE, [eq]),
  impactConfidentiality: searchByFilterKey({ label: 'Impact-Confidentiality', value: 'impact_confidentiality' }, BOOL_AUTOCOMPLETE, [eq]),
  impactIntegrity: searchByFilterKey({ label: 'Impact-Integrity', value: 'impact_integrity' }, BOOL_AUTOCOMPLETE, [eq]),
  importance: searchByFilterKey({ label: 'Importance', value: 'importance' }, [], [eq]),
  ip: searchByFilterKey({ label: 'Asset', value: 'ip' }, [], [eq, neq, ilike]),
  method: searchByFilterKey({ label: 'Method', value: 'method' }, [], [eq, neq, ilike]),
  name: searchByFilterKey({ label: 'Name', value: 'name' }, [], [eq, neq, ilike]),
  os: searchByFilterKey({ label: 'OS', value: 'os' }, [], [eq, neq, ilike]),
  owned: searchByFilterKey({ label: 'Owned', value: 'owned' }, BOOL_AUTOCOMPLETE, [eq]),
  path: searchByFilterKey({ label: 'Path', value: 'path' }, [], [eq, neq, ilike]),
  permission: searchByFilterKey({ label: 'Permission', value: 'readonly' }, WORKSPACES_PERMISSION_AUTOCOMPLETE, [eq, neq]),
  public: searchByFilterKey({ label: 'Public', value: 'public' }, BOOL_AUTOCOMPLETE, [eq]),
  query: searchByFilterKey({ label: 'Query', value: 'query_string' }, [], [eq, neq, ilike]),
  request: searchByFilterKey({ label: 'Request', value: 'request' }, [], [eq, neq, ilike]),
  resolution: searchByFilterKey({ label: 'Resolution', value: 'resolution' }, [], [eq, neq, ilike]),
  response: searchByFilterKey({ label: 'Response', value: 'response' }, [], [eq, neq, ilike]),
  service__name: searchByFilterKey({ label: 'Name', value: 'name' }, [], [eq, neq, ilike]),
  service__port: searchByFilterKey({ label: 'Port', value: 'port' }, [], [eq, neq, greaterOrEqual, greater, lessOrEqual, less]),
  serviceName: searchByFilterKey({ label: 'Service name', value: 'service__name' }, [], [has]),
  servicePort: searchByFilterKey({ label: 'Service port', value: 'service__port' }, [], [has]),
  services__name: searchByFilterKey({ label: 'Name', value: 'name' }, [], [eq, neq, ilike]),
  services__port: searchByFilterKey({ label: 'Port', value: 'port' }, [], [eq, neq, greaterOrEqual, greater, lessOrEqual, less]),
  services__status: searchByFilterKey({ label: 'Status', value: 'status' }, SERVICES_STATUS_AUTOCOMPLETE, [eq, neq]),
  services__version: searchByFilterKey({ label: 'Version', value: 'version' }, [], [eq, neq, ilike]),
  servicesName: searchByFilterKey({ label: 'Services name', value: 'services__name' }, [], [any]),
  servicesPort: searchByFilterKey({ label: 'Services port', value: 'services__port' }, [], [any]),
  servicesStatus: searchByFilterKey({ label: 'Services status', value: 'services__status' }, [], [any]),
  servicesVersion: searchByFilterKey({ label: 'Services version', value: 'services__version' }, [], [any]),
  severity: searchByFilterKey({ label: 'Severity', value: 'severity' }, SEVERITY_AUTOCOMPLETE, [eq, neq]),
  startDate: searchByFilterKey({ label: 'Start Date', value: 'start_date' }, [], [eq, lessOrEqual, greaterOrEqual, less, greater]),
  status: searchByFilterKey({ label: 'Status', value: 'status' }, STATUS_AUTOCOMPLETE, [eq, neq]),
  statusCode: searchByFilterKey({ label: 'Status Code', value: 'status_code' }, [], [eq, neq, greaterOrEqual, greater, lessOrEqual, less]),
  tool: searchByFilterKey({ label: 'Tool', value: 'tool' }, [], [eq, neq, ilike]),
  type: searchByFilterKey({ label: 'Type', value: 'type' }, VULN_TYPE_AUTOCOMPLETE, [eq, neq]),
  updateDate: searchByFilterKey({ label: 'Update Date', value: 'update_date' }, [], [eq, greaterOrEqual, lessOrEqual, greater, less]),
  website: searchByFilterKey({ label: 'Website', value: 'website' }, [], [eq, neq, ilike]),
  workspace__name: searchByFilterKey({ label: 'Name', value: 'name' }, [], [eq, neq, ilike]),
  workspaceName: searchByFilterKey({ label: 'Workspace', value: 'workspace__name' }, [], [has])
};

export const GROUP_BY = {
  none: {
    key: groupByKey('None', ''),
    filter: null
  },
  name: {
    key: groupByKey('Name', 'name'),
    filter: createFilter('name', ilike.name.value, '')
  },
  severity: {
    key: groupByKey('Severity', 'severity'),
    filter: createFilter('severity', eq.name.value, '')
  },
  services__name: {
    key: groupByKey('Service name', 'services__name'),
    filter: createFilter('services', any.name.value, '')
  },
  status: {
    key: groupByKey('Status', 'status'),
    filter: createFilter('status', eq.name.value, '')
  },
  hostnames__name: {
    key: groupByKey('Hostnames', 'hostnames__name'),
    filter: createFilter('hostnames', any.name.value, '')
  },
  target_host_ip: {
    key: groupByKey('Asset', 'target_host_ip'),
    filter: createFilter('target_host_ip', ilike.name.value, '')
  },
  os: {
    key: groupByKey('OS', 'os'),
    filter: createFilter('os', eq.name.value, '')
  },
  cveAndTarget: {
    key: {
      label: 'CVE & Asset',
      value: [
        {
          field: 'cve_instances__name'
        },
        {
          field: 'target'
        }
      ]
    },
    filter: createFilter('cve_instances__name', eq.name.value, '')
  }
};

export const CONTEXT_MENU_FILTER_KEYS = {
  asset: {
    key: createLabelName('Asset', 'target'),
    filter: createFilter('target', eq.name.value, '')
  },
  date: {
    key: createLabelName('Date', 'create_date'),
    filter: createFilter('create_date', eq.name.value, '')
  },
  name: {
    key: createLabelName('Name', 'name'),
    filter: createFilter('name', ilike.name.value, '')
  },
  service: {
    key: createLabelName('Service name', 'service'),
    filter: createFilter('service', has.name.value, '')
  },
  status: {
    key: createLabelName('Status', 'status'),
    filter: createFilter('status', eq.name.value, '')
  },
  tags: {
    key: createLabelName('Tags', 'tags'),
    filter: createFilter('tags', has.value, '')
  }
};

// --- VULN KEYS ---

export const VULNS_SEARCH_BY_FILTER_KEYS = [
  SEARCH_BY_FILTER_KEYS.asset,
  SEARCH_BY_FILTER_KEYS.assetOs,
  SEARCH_BY_FILTER_KEYS.confirmed,
  SEARCH_BY_FILTER_KEYS.createDate,
  SEARCH_BY_FILTER_KEYS.creatorCommandId,
  SEARCH_BY_FILTER_KEYS.cve_instances,
  SEARCH_BY_FILTER_KEYS.cvss2,
  SEARCH_BY_FILTER_KEYS.cvss3,
  SEARCH_BY_FILTER_KEYS.cwe,
  SEARCH_BY_FILTER_KEYS.data,
  SEARCH_BY_FILTER_KEYS.description,
  SEARCH_BY_FILTER_KEYS.easeOfResolution,
  SEARCH_BY_FILTER_KEYS.externalId,
  SEARCH_BY_FILTER_KEYS.id,
  SEARCH_BY_FILTER_KEYS.impactAccountability,
  SEARCH_BY_FILTER_KEYS.impactAvailability,
  SEARCH_BY_FILTER_KEYS.impactConfidentiality,
  SEARCH_BY_FILTER_KEYS.impactIntegrity,
  SEARCH_BY_FILTER_KEYS.method,
  SEARCH_BY_FILTER_KEYS.name,
  SEARCH_BY_FILTER_KEYS.path,
  SEARCH_BY_FILTER_KEYS.query,
  SEARCH_BY_FILTER_KEYS.request,
  SEARCH_BY_FILTER_KEYS.resolution,
  SEARCH_BY_FILTER_KEYS.response,

  SEARCH_BY_FILTER_KEYS.serviceName,
  SEARCH_BY_FILTER_KEYS.servicePort,
  SEARCH_BY_FILTER_KEYS.severity,
  SEARCH_BY_FILTER_KEYS.status,
  SEARCH_BY_FILTER_KEYS.statusCode,
  SEARCH_BY_FILTER_KEYS.tool,
  SEARCH_BY_FILTER_KEYS.type,
  SEARCH_BY_FILTER_KEYS.updateDate,
  SEARCH_BY_FILTER_KEYS.website
];

export const VULNS_GROUP_BY_KEYS = [
  GROUP_BY.none.key,
  GROUP_BY.name.key,
  GROUP_BY.severity.key,
  GROUP_BY.status.key,
  GROUP_BY.target_host_ip.key,
  GROUP_BY.cveAndTarget.key
];

export const VULNS_CONTEXT_MENU_FILTER_KEYS = [
  CONTEXT_MENU_FILTER_KEYS.name.key,
  CONTEXT_MENU_FILTER_KEYS.service.key,
  CONTEXT_MENU_FILTER_KEYS.asset.key,
  CONTEXT_MENU_FILTER_KEYS.date.key,
  CONTEXT_MENU_FILTER_KEYS.status.key
];

// --- ASSETS KEYS ---

export const ASSETS_SEARCH_BY_FILTER_KEYS = [
  SEARCH_BY_FILTER_KEYS.hostNames,
  SEARCH_BY_FILTER_KEYS.ip,
  SEARCH_BY_FILTER_KEYS.os,
  SEARCH_BY_FILTER_KEYS.owned,
  SEARCH_BY_FILTER_KEYS.servicesName,
  SEARCH_BY_FILTER_KEYS.servicesPort,
  SEARCH_BY_FILTER_KEYS.servicesStatus,
  SEARCH_BY_FILTER_KEYS.servicesVersion
];

export const ASSETS_GROUP_BY_KEYS = [
  GROUP_BY.none.key,
  GROUP_BY.os.key,
  GROUP_BY.services__name.key,
  GROUP_BY.hostnames__name.key
];

// --- KB KEYS ---

export const KB_SEARCH_BY_FILTER_KEYS = [
  SEARCH_BY_FILTER_KEYS.data,
  SEARCH_BY_FILTER_KEYS.description,
  SEARCH_BY_FILTER_KEYS.easeOfResolution,
  SEARCH_BY_FILTER_KEYS.externalId,
  SEARCH_BY_FILTER_KEYS.id,
  SEARCH_BY_FILTER_KEYS.name,
  SEARCH_BY_FILTER_KEYS.resolution,
  SEARCH_BY_FILTER_KEYS.severity
];

// --- VULNS IN ASSET KEYS ---

export const VULNS_ASSETS_SEARCH_BY_FILTER_KEYS = [
  SEARCH_BY_FILTER_KEYS.confirmed,
  SEARCH_BY_FILTER_KEYS.createDate,
  SEARCH_BY_FILTER_KEYS.creatorCommandId,
  SEARCH_BY_FILTER_KEYS.cve_instances,
  SEARCH_BY_FILTER_KEYS.cvss2,
  SEARCH_BY_FILTER_KEYS.cvss3,
  SEARCH_BY_FILTER_KEYS.cwe,
  SEARCH_BY_FILTER_KEYS.data,
  SEARCH_BY_FILTER_KEYS.description,
  SEARCH_BY_FILTER_KEYS.easeOfResolution,
  SEARCH_BY_FILTER_KEYS.externalId,
  SEARCH_BY_FILTER_KEYS.id,
  SEARCH_BY_FILTER_KEYS.impactAccountability,
  SEARCH_BY_FILTER_KEYS.impactAvailability,
  SEARCH_BY_FILTER_KEYS.impactConfidentiality,
  SEARCH_BY_FILTER_KEYS.impactIntegrity,
  SEARCH_BY_FILTER_KEYS.method,
  SEARCH_BY_FILTER_KEYS.name,
  SEARCH_BY_FILTER_KEYS.path,
  SEARCH_BY_FILTER_KEYS.query,
  SEARCH_BY_FILTER_KEYS.request,
  SEARCH_BY_FILTER_KEYS.resolution,
  SEARCH_BY_FILTER_KEYS.response,
  SEARCH_BY_FILTER_KEYS.serviceName,
  SEARCH_BY_FILTER_KEYS.servicePort,
  SEARCH_BY_FILTER_KEYS.severity,
  SEARCH_BY_FILTER_KEYS.status,
  SEARCH_BY_FILTER_KEYS.statusCode,
  SEARCH_BY_FILTER_KEYS.tool,
  SEARCH_BY_FILTER_KEYS.type,
  SEARCH_BY_FILTER_KEYS.updateDate,
  SEARCH_BY_FILTER_KEYS.website
];

// --- GENERAL ASSETS KEYS ---

export const GENERAL_ASSETS_SEARCH_BY_FILTER_KEYS = [
  SEARCH_BY_FILTER_KEYS.hostNames,
  SEARCH_BY_FILTER_KEYS.ip,
  SEARCH_BY_FILTER_KEYS.os,
  SEARCH_BY_FILTER_KEYS.owned,
  SEARCH_BY_FILTER_KEYS.servicesName,
  SEARCH_BY_FILTER_KEYS.servicesPort,
  SEARCH_BY_FILTER_KEYS.servicesStatus,
  SEARCH_BY_FILTER_KEYS.servicesVersion,
  SEARCH_BY_FILTER_KEYS.workspaceName
];

// --- GENERAL VULNS KEYS ---

export const GENERAL_VULNS_SEARCH_BY_FILTER_KEYS = [
  SEARCH_BY_FILTER_KEYS.asset,
  SEARCH_BY_FILTER_KEYS.assetOs,
  SEARCH_BY_FILTER_KEYS.confirmed,
  SEARCH_BY_FILTER_KEYS.createDate,
  SEARCH_BY_FILTER_KEYS.creatorCommandId,
  SEARCH_BY_FILTER_KEYS.cve_instances,
  SEARCH_BY_FILTER_KEYS.cvss2,
  SEARCH_BY_FILTER_KEYS.cvss3,
  SEARCH_BY_FILTER_KEYS.cwe,
  SEARCH_BY_FILTER_KEYS.data,
  SEARCH_BY_FILTER_KEYS.description,
  SEARCH_BY_FILTER_KEYS.easeOfResolution,
  SEARCH_BY_FILTER_KEYS.externalId,
  SEARCH_BY_FILTER_KEYS.id,
  SEARCH_BY_FILTER_KEYS.impactAccountability,
  SEARCH_BY_FILTER_KEYS.impactAvailability,
  SEARCH_BY_FILTER_KEYS.impactConfidentiality,
  SEARCH_BY_FILTER_KEYS.impactIntegrity,
  SEARCH_BY_FILTER_KEYS.method,
  SEARCH_BY_FILTER_KEYS.name,
  SEARCH_BY_FILTER_KEYS.path,
  SEARCH_BY_FILTER_KEYS.query,
  SEARCH_BY_FILTER_KEYS.request,
  SEARCH_BY_FILTER_KEYS.resolution,
  SEARCH_BY_FILTER_KEYS.response,
  SEARCH_BY_FILTER_KEYS.serviceName,
  SEARCH_BY_FILTER_KEYS.servicePort,
  SEARCH_BY_FILTER_KEYS.severity,
  SEARCH_BY_FILTER_KEYS.status,
  SEARCH_BY_FILTER_KEYS.statusCode,
  SEARCH_BY_FILTER_KEYS.tool,
  SEARCH_BY_FILTER_KEYS.type,
  SEARCH_BY_FILTER_KEYS.updateDate,
  SEARCH_BY_FILTER_KEYS.website,
  SEARCH_BY_FILTER_KEYS.workspaceName
];

// --- WORKSPACES KEYS ---

export const WORKSPACES_SEARCH_BY_FILTER_KEYS = [
  SEARCH_BY_FILTER_KEYS.customer,
  SEARCH_BY_FILTER_KEYS.description,
  SEARCH_BY_FILTER_KEYS.endDate,
  SEARCH_BY_FILTER_KEYS.importance,
  SEARCH_BY_FILTER_KEYS.name,
  SEARCH_BY_FILTER_KEYS.permission,
  SEARCH_BY_FILTER_KEYS.public,
  SEARCH_BY_FILTER_KEYS.startDate
];

export const CONFIRMED_FLAG_FILTERS = {
  confirmed: {
    name: 'confirmed',
    op: eq.name.value,
    val: 'true'
  },
  notConfirmed: {
    name: 'confirmed',
    op: eq.name.value,
    val: 'false'
  }
};

export const CLOSED_FILTERS = {
  closed: {
    name: 'status',
    op: neq.name.value,
    val: 'closed'
  }
};

const kbByName = (name) => ({
  name: 'name',
  op: ilike.name.value,
  val: name
});

export const KB_FILTERS = {
  kbByName
};

export const DASHBOARD_FILTERS = {
  commandIdFilter,
  vulnerabilitiesBySeverity,
  vulnerabilitiesByStatus,
  vulnerabilityById,
  assetsByIp,
  assetsByServiceName,
  vulnerabilitiesByType
};

export const KEY_LABELS_MAP = Object.entries(SEARCH_BY_FILTER_KEYS).map((key) => key[1].name);
export const OPERATOR_LABELS_MAP = Object.entries(FILTER_OPERATORS).map((operator) => operator[1].name);
export const AUTOCOMPLETE_LABELS_MAP = Object.entries(SEARCH_BY_AUTOCOMPLETE_KEYS).map((autocomplete) => autocomplete[1].name);
