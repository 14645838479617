import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { resetError } from 'store/Jobs/actions';
import { selectError, selectErrorMessage } from 'store/Jobs/selectors';
import ErrorDialog from 'Common/Components/ErrorDialog';
import List from './components/List';
import Detail from './components/Detail';
import { Wrapper } from './styled';
import ReduxModal from 'Common/Components/ReduxModal';
import { MODAL_UPGRADE_LICENSE } from 'store/modals/modals';
import UpgradeLicense from 'Common/Components/UpgradeLicense';
import upgradeLicenseImage from 'Images/workspaces-license.png';

const Jobs = ({ isNew, match }) => {
  const dispatch = useDispatch();
  const error = useSelector(selectError);
  const errorMessage = useSelector(selectErrorMessage);

  return (
    <Wrapper>
      <List />
      <Detail isNew={ isNew } match={ match } />
      { error ? <ErrorDialog message={ errorMessage } resetError={ () => dispatch(resetError()) } /> : null }
      <ReduxModal id={ MODAL_UPGRADE_LICENSE }>
        <UpgradeLicense id={ MODAL_UPGRADE_LICENSE } image={ upgradeLicenseImage } descriptionIntlId="upgradeLicense.jobs.description" />
      </ReduxModal>
    </Wrapper>
  );
};

Jobs.propTypes = {
  isNew: PropTypes.bool.isRequired,
  match: PropTypes.instanceOf(Object).isRequired
};

export default Jobs;
