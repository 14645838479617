import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import useClickOutside from 'Hooks/useClickOutside';
import IconButton from 'Common/Components/IconButton';
import MenuItem from 'Common/Components/ContextMenu/components/MenuItem';
import {
  setBulkUpdateField, setBulkUpdateValue, updateVuln
} from 'store/Manage/actions';
import { MODAL_MANAGE_BULK_UPDATE_CONFIRMATION } from 'store/modals/modals';
import { openModal } from 'store/modals/actions';
import { Wrapper, SeverityIcon, Dropdown, SeverityLetter } from './styled';
import ModalWarning from 'Common/Components/ModalWarning';
import { selectReadOnly } from 'store/Faraday/selectors';
import {
  setBulkUpdateField as setBulkUpdateFieldVulnsAsset,
  setBulkUpdateValue as setBulkUpdateValueVulnsAsset,
  updateVulnFromAsset
} from 'store/Contextualization/AssetDetail/actions';
import {
  setBulkUpdateField as setBulkUpdateFieldGeneral,
  setBulkUpdateValue as setBulkUpdateValueGeneral,
  updateVulnGeneral
} from 'store/ManageGeneral/actions';

const SeverityItem = ({ title, handleDropdown, entity, vulnsSelected }) => {
  const dispatch = useDispatch();
  const oneVulnSelected = vulnsSelected.length === 1;
  const isBulk = vulnsSelected.length > 1;
  const selectedVuln = vulnsSelected[0];
  const capitalizedSeverity = title.charAt(0).toUpperCase() + title.slice(1);
  const letter = capitalizedSeverity.charAt(0);

  const applySeverityFromAsset = () => {
    if (isBulk) {
      dispatch(setBulkUpdateFieldVulnsAsset('severity'));
      dispatch(setBulkUpdateValueVulnsAsset(title));
      dispatch(openModal(MODAL_MANAGE_BULK_UPDATE_CONFIRMATION));
    } else dispatch(updateVulnFromAsset(selectedVuln, 'severity', title));
    handleDropdown();
  };

  const applySeverity = () => {
    if (isBulk) {
      dispatch(setBulkUpdateField('severity'));
      dispatch(setBulkUpdateValue(title));
      dispatch(openModal(MODAL_MANAGE_BULK_UPDATE_CONFIRMATION));
    } else dispatch(updateVuln(selectedVuln, 'severity', title));
    handleDropdown();
  };

  const applySeverityGeneral = () => {
    if (isBulk) {
      dispatch(setBulkUpdateFieldGeneral('severity'));
      dispatch(setBulkUpdateValueGeneral(title));
      dispatch(openModal(MODAL_MANAGE_BULK_UPDATE_CONFIRMATION));
    } else dispatch(updateVulnGeneral(selectedVuln, 'severity', title));
    handleDropdown();
  };

  const update = {
    vulns: () => applySeverity(),
    vulnsAssets: () => applySeverityFromAsset(),
    vulnsGeneral: () => applySeverityGeneral()
  };

  return (
    <MenuItem
      isSelected={ oneVulnSelected && selectedVuln.severity === title }
      title={ capitalizedSeverity }
      icon={ (
        <SeverityLetter title={ capitalizedSeverity } type={ letter }>
          { letter }
        </SeverityLetter>
      ) }
      onClick={ update[entity] }
    />
  );
};

const Severity = ({ hasVulns, vulnsSelected, entity, groupBy }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const intl = useIntl();
  const readOnly = useSelector(selectReadOnly);
  const message = intl.formatMessage({ id: 'modalWarning.selectOneOrMore' }, { entity: 'vulnerabilities' });
  const canUpdate = !readOnly && (groupBy !== 'severity') && hasVulns;

  const dropdownRef = useRef();
  useClickOutside(dropdownRef, () => setShowDropdown(false));

  const handleDropdown = () => setShowDropdown(!showDropdown);

  const handleClick = () => {
    if (canUpdate) {
      if (vulnsSelected.length > 0) handleDropdown();
      else setShowWarning(true);
    }
  };

  const onClose = () => setShowWarning(false);

  return (
    <Wrapper ref={ dropdownRef }>
      <IconButton icon={ <SeverityIcon /> } title={ intl.formatMessage({ id: 'manage.cm.severity' }) } onClick={ handleClick } disabled={ !canUpdate } />
      {showDropdown && (
      <Dropdown>
        <SeverityItem title="critical" handleDropdown={ handleDropdown } entity={ entity } vulnsSelected={ vulnsSelected } />
        <SeverityItem title="high" handleDropdown={ handleDropdown } entity={ entity } vulnsSelected={ vulnsSelected } />
        <SeverityItem title="medium" handleDropdown={ handleDropdown } entity={ entity } vulnsSelected={ vulnsSelected } />
        <SeverityItem title="low" handleDropdown={ handleDropdown } entity={ entity } vulnsSelected={ vulnsSelected } />
        <SeverityItem title="informational" handleDropdown={ handleDropdown } entity={ entity } vulnsSelected={ vulnsSelected } />
        <SeverityItem title="unclassified" handleDropdown={ handleDropdown } entity={ entity } vulnsSelected={ vulnsSelected } />
      </Dropdown>
      )}
    { showWarning && <ModalWarning onCloseCallback={ onClose } title={ intl.formatMessage({ id: 'manage.cm.severity' }) } description={ message } /> }
    </Wrapper>

  );
};

export default Severity;
