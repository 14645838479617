import React, { useState, useRef } from 'react';
import get from 'lodash/get';
import Header from './components/Header';
import Tabs from './components/Tabs';
import {
  Wrapper, DetailWrapper, ResizerWrapper,
  Resizer, Transparency
} from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/styled';
import ReduxModal from 'Common/Components/ReduxModal';
import { MODAL_UPGRADE_LICENSE_VULN_DETAIL, MODAL_MOVE_VULN_HOST } from 'store/modals/modals';
import UpgradeLicense from 'Common/Components/UpgradeLicense';
import FFTags from 'Images/ff_tags.png';
import { useResizeX } from 'Hooks/useResize';
import useWindowDimensions from 'Hooks/useWindowSize';
import ImagePreviewModal from 'Screens/Contextualization/Manage/components/VulnerabilityDetail/components/ImagePreviewModal';
import TabSelector from './components/Tabs/components/TabSelector';
import selectModal from 'store/modals/selectors';
import { useSelector } from 'react-redux';
import { MoveVulnModal } from './components/Tabs/components/GeneralTab/components/HostData';

const VulnerabilityDetail = () => {
  const [showTransparency, setShowTransparency] = useState(false);
  const ref = useRef(null);
  const wrapperRef = useRef(null);
  const resizerRef = useRef(null);
  const { width } = useWindowDimensions();
  const minWidth = width < 3000 ? width * 0.55 : width * 0.35;
  const maxWidth = width < 3000 ? width * 0.75 : width * 0.55;
  const wrapperWidth = useResizeX(wrapperRef, resizerRef, minWidth, minWidth, maxWidth);
  const showMoveVulnModal = useSelector((state) => selectModal(state, MODAL_MOVE_VULN_HOST));

  const onScroll = () => {
    const scrollTop = get(ref, 'current.scrollTop', 0);
    if (scrollTop === 0) setShowTransparency(false);
    else setShowTransparency(true);
  };

  return (
    <>
      <ReduxModal id={ MODAL_UPGRADE_LICENSE_VULN_DETAIL }>
        <UpgradeLicense id={ MODAL_UPGRADE_LICENSE_VULN_DETAIL } image={ FFTags } descriptionIntlId={ 'upgradeLicense.sidebar.tags.description' } />
      </ReduxModal>
      <ImagePreviewModal />
      <Wrapper ref={ wrapperRef } wrapperWidth={ wrapperWidth }>
        <ResizerWrapper ref={ resizerRef } children={ <Resizer /> } />
        <DetailWrapper>
          <Header />
          <TabSelector />
          { showTransparency && <Transparency /> }
          <Tabs tabRef={ ref } onScroll={ onScroll } showTransparency={ showTransparency } />
        </DetailWrapper>

      </Wrapper>
      {showMoveVulnModal && <MoveVulnModal />}
    </>
  );
};

export default VulnerabilityDetail;
