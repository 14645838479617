import styled from 'styled-components';
import colors from 'Styles/colors';

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

HiddenCheckbox.displayName = 'HiddenCheckbox';

export const Wrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

Wrapper.displayName = 'Wrapper';

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

CheckboxContainer.displayName = 'CheckboxContainer';

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

Icon.displayName = 'Icon';

export const Checkbox = styled.div`
  display: flex;
  width: 16px;
  height: 16px;
//   background: ${(props) => (props.checked ? `${colors.green5}` : `${colors.white}`)};
//   border: ${(props) => (props.checked ? `1px solid ${colors.green6}` : `1px solid ${colors.grey31}`)};
//   border-radius: 3px;
//   transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 2px ${colors.green5};
  }

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')}
  }
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  > :nth-child(1){
      margin-right: 13px;
  }
`;

Label.displayName = 'Label';

export const Span = styled.span`
    font-size: 12.5px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #63758d;
    margin-top: 3px;
    pointer-events: none;
`;

Span.displayName = 'Span';
